import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Card,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			isNextPage      : false,
			ErrMsg          : '',
			email           : '',
			email_original  : '',
			name_1          : '',
			name_2          : '',
			name_furi_1     : '',
			name_furi_2     : '',
			zip_1           : '',
			zip_2           : '',
			pref            : '',
			addr_1          : '',
			addr_2          : '',
			addr_3          : '',
			tel_1           : '',
			tel_2           : '',
			tel_3           : '',
			birth_y         : '',
			birth_m         : '',
			birth_d         : '',
			sex             : '',
			mg_kbn          : false,
		}
		this._backTop         = this._backTop.bind(this);
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	_backTop() {
		//子ウィンドウからの呼び出し用
		this.setState({
			isNextPage  : false,
		});
	}
	async _getData() {

		try{
			//会員データを取得
			const refProfile = await API.Profile.Get();

			//データがない場合
			if(refProfile.item === null){
				this.setState({
					isLoading  : false,
					ErrMsg     : '※会員データが存在しません。<br/>',
				 });
				return(0);
			}

			//各項目取得
			const email         = refProfile.item.email             ? refProfile.item.email         :  '';
			const name_1        = refProfile.item.name_1            ? refProfile.item.name_1        :  '';
			const name_2        = refProfile.item.name_2            ? refProfile.item.name_2        :  '';
			const name_furi_1   = refProfile.item.name_furi_1       ? refProfile.item.name_furi_1   :  '';
			const name_furi_2   = refProfile.item.name_furi_2       ? refProfile.item.name_furi_2   :  '';
			const zip_1         = refProfile.item.zip_1             ? refProfile.item.zip_1         :  '';
			const zip_2         = refProfile.item.zip_2             ? refProfile.item.zip_2         :  '';
			const pref          = refProfile.item.pref              ? refProfile.item.pref          :  '';
			const addr_1        = refProfile.item.addr_1            ? refProfile.item.addr_1        :  '';
			const addr_2        = refProfile.item.addr_2            ? refProfile.item.addr_2        :  '';
			const addr_3        = refProfile.item.addr_3            ? refProfile.item.addr_3        :  '';
			const tel_1         = refProfile.item.tel_1             ? refProfile.item.tel_1         :  '';
			const tel_2         = refProfile.item.tel_2             ? refProfile.item.tel_2         :  '';
			const tel_3         = refProfile.item.tel_3             ? refProfile.item.tel_3         :  '';
			const birth_y       = refProfile.item.birth_y           ? refProfile.item.birth_y       :  '';
			const birth_m       = refProfile.item.birth_m           ? refProfile.item.birth_m       :  '';
			const birth_d       = refProfile.item.birth_d           ? refProfile.item.birth_d       :  '';
			const sex           = refProfile.item.sex               ? refProfile.item.sex           :  '';
			const mg_kbn        = refProfile.item.mg_kbn_1          ? true                          :  false;

			const email_original = email;

			//表示更新
			this.setState({
				isLoading     : false,
				email_original : email_original,
				email         : email         ,
				name_1        : name_1        ,
				name_2        : name_2        ,
				name_furi_1   : name_furi_1   ,
				name_furi_2   : name_furi_2   ,
				zip_1         : zip_1         ,
				zip_2         : zip_2         ,
				pref          : pref          ,
				addr_1        : addr_1        ,
				addr_2        : addr_2        ,
				addr_3        : addr_3 === 'null' ? '' : addr_3,
				tel_1         : tel_1         ,
				tel_2         : tel_2         ,
				tel_3         : tel_3         ,
				birth_y       : birth_y       ,
				birth_m       : birth_m       ,
				birth_d       : birth_d       ,
				sex           : sex           ,
				mg_kbn        : mg_kbn        ,
			});

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
		}
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const email         = formObj.email.value;
			const name_1        = formObj.name_1.value;
			const name_2        = formObj.name_2.value;
			const name_furi_1   = formObj.name_furi_1.value;
			const name_furi_2   = formObj.name_furi_2.value;
			const zip_1         = formObj.zip_1.value;
			const zip_2         = formObj.zip_2.value;
			const pref          = formObj.pref.value;
			const addr_1        = formObj.addr_1.value;
			const addr_2        = formObj.addr_2.value;
			const addr_3        = formObj.addr_3.value;
			const tel_1         = formObj.tel_1.value;
			const tel_2         = formObj.tel_2.value;
			const tel_3         = formObj.tel_3.value;
			const birth         = formObj.birth.value;
			const sex           = formObj.sex.value;
			const mg_kbn        = formObj.mg_kbn.value === 'true' ? true : false;

			const birth_ary = birth.split("-");
			const birth_y   = birth_ary[0];
			const birth_m   = birth_ary[1];
			const birth_d   = birth_ary[2];

			//次のページ
			this.setState({
				ErrMsg      : '',
				isLockForm  : false,
				isValidated : false,
				isNextPage  : true,
				email       : email         ,
				name_1      : name_1        ,
				name_2      : name_2        ,
				name_furi_1 : name_furi_1   ,
				name_furi_2 : name_furi_2   ,
				zip_1       : zip_1         ,
				zip_2       : zip_2         ,
				pref        : pref          ,
				addr_1      : addr_1        ,
				addr_2      : addr_2        ,
				addr_3      : addr_3        ,
				tel_1       : tel_1         ,
				tel_2       : tel_2         ,
				tel_3       : tel_3         ,
				birth_y     : birth_y       ,
				birth_m     : birth_m       ,
				birth_d     : birth_d       ,
				sex         : sex           ,
				mg_kbn      : mg_kbn        ,
			 });
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
			isNextPage  : false,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isNextPage){
			return (
				<NextPage _backTop={this._backTop} {...this.state} {...this.props} />
			);
		}
		else if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
	</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
							<Container>
								<h2>ご登録内容の変更</h2>
								<h3><span>変更する項目にご入力ください</span></h3>
								<Row>
									<Col className="offset-md-1 col-md-10">
									<Card>
										<Card.Body>
											<Row>
												<Col>
													<Form.Group controlId="email">
														<Form.Label>
															メールアドレス
														</Form.Label>
														<Form.Control type="email" name="email" size="lg" required pattern=".{1,255}" defaultValue={this.state.email} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															メールアドレスを入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>
											<Row>
												<Col md={6}>
													<Form.Group controlId="name_1">
														<Form.Label>
															姓
														</Form.Label>
														<Form.Control type="text" name="name_1" size="lg" required pattern=".{1,255}" defaultValue={this.state.name_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名字を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Group controlId="name_2">
														<Form.Label>
															名
														</Form.Label>
														<Form.Control type="text" name="name_2" size="lg" required pattern=".{1,255}" defaultValue={this.state.name_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名前を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4" />

											<Row>
												<Col md={6}>
													<Form.Group controlId="name_furi_1">
														<Form.Label>
															セイ
														</Form.Label>
														<Form.Control type="text" name="name_furi_1" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" defaultValue={this.state.name_furi_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名字（カタカナ）を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col md={6}>
													<Form.Group controlId="name_furi_2">
														<Form.Label>
															メイ
														</Form.Label>
														<Form.Control type="text" name="name_furi_2" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" defaultValue={this.state.name_furi_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名前（カタカナ）を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4" />

											<p className="small red">※ハイフンで区切って、郵便番号１（３桁）-郵便番号２（4桁）で入力してください</p>
											<Row>
													<Col sm={3} xs={6}>
													<Form.Group controlId="zip_1">
													<Form.Label>
														郵便番号1(3桁)
													</Form.Label>
														<Form.Control type="text" name="zip_1" size="lg" maxLength='3' required pattern="\d{3}" defaultValue={this.state.zip_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															郵便番号1を入力してください
														</Form.Control.Feedback>
													</Form.Group>
													</Col>
													<Col sm={3} xs={6}>
													<Form.Group controlId="zip_2">
													<Form.Label>
														郵便番号2(4桁)
													</Form.Label>
														<Form.Control type="text" name="zip_2" size="lg" maxLength='4' required pattern="\d{4}" defaultValue={this.state.zip_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															郵便番号2を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>

											<Row>
												<Col md={4} className="mb-3">
													<Form.Group controlId="pref">
														<Form.Label>
															都道府県
														</Form.Label>
														<Form.Control type="text" name="pref" size="lg" required pattern=".{2,4}" defaultValue={this.state.pref} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															都道府県を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col md={8} className="mb-3">
													<Form.Group controlId="addr_1">
														<Form.Label>
															市町村
														</Form.Label>
														<Form.Control type="text" name="addr_1" size="lg" required pattern=".{1,255}" defaultValue={this.state.addr_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															市町村を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<Row>
											<Col sm={6} className="mb-3">
													<Form.Group controlId="addr_2">
														<Form.Label>番地</Form.Label>
														<Form.Control type="text" name="addr_2" size="lg" required pattern=".{1,255}" defaultValue={this.state.addr_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															住所を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col sm={6} className="mb-3">
													<Form.Group controlId="addr_3">
														<Form.Label>
															アパート・マンション名
														</Form.Label>
														<Form.Control type="text" name="addr_3" size="lg" pattern=".{0,255}" defaultValue={this.state.addr_3} disabled={this.state.isLockForm} />
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4" />
												<p className="small red">※ハイフンで区切って、電話番号１ - 電話番号２ - 電話番号3 で入力してください</p>
											<Form.Row>
												<Col>
													<Form.Group controlId="tel_1">
													<Form.Label>
														電話番号1
													</Form.Label>
														<Form.Control type="text" name="tel_1" size="lg" maxLength='4' required pattern="\d{2,4}" defaultValue={this.state.tel_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															電話番号1を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											<Col>
													<Form.Group controlId="tel_2">
													<Form.Label>
														電話番号２
													</Form.Label>
														<Form.Control type="text" name="tel_2" size="lg" maxLength='4' required pattern="\d{2,4}" defaultValue={this.state.tel_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															電話番号2を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col>
													<Form.Group controlId="tel_3">
													<Form.Label>
														電話番号3
													</Form.Label>
														<Form.Control type="text" name="tel_3" size="lg" maxLength='4' required pattern="\d{2,4}" defaultValue={this.state.tel_3} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															電話番号3を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Form.Row>

											<hr className="mb-4"/>
											<Row>
												<Col>
													<Form.Label>
														生年月日
													</Form.Label>
												</Col>
												<Col>
													<Form.Group controlId="birth">
														<Form.Control type="date" name="birth" size="lg" defaultValue={this.state.birth_y + '-' + this.state.birth_m + '-' + this.state.birth_d} required disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															誕生日を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>

											<Form.Group controlId="sex">
											<Row>
												<Col md={3}>
													<Form.Label>
														性別
													</Form.Label>
												</Col>
												<Col md={2}>
													<Form.Check type="radio" name="sex" id="sex_1" defaultValue="男性" label="男性" defaultChecked={this.state.sex === '男性' ? true : false} required disabled={this.state.isLockForm} />
												</Col>
												<Col md={2}>
													<Form.Check type="radio" name="sex" id="sex_2" defaultValue="女性" label="女性" defaultChecked={this.state.sex === '女性' ? true : false} required disabled={this.state.isLockForm} />
												</Col>
												<Col md={5}>
													<Form.Control.Feedback type="invalid">
														性別を選択してください
													</Form.Control.Feedback>
												</Col>
											</Row>
											</Form.Group>

											<hr className="mb-4"/>

											<Row>
												<Col md={3}>
													<Form.Label>メルマガ購読</Form.Label>
												</Col>
												<Col md={2}>
													<Form.Check type="radio" name="mg_kbn" id="mg_kbn_1" defaultValue={true} label="はい" defaultChecked={this.state.mg_kbn ? true : false} required disabled={this.state.isLockForm} />
												</Col>
												<Col md={2}>
													<Form.Check type="radio" name="mg_kbn" id="mg_kbn_2" defaultValue={false} label="いいえ" defaultChecked={this.state.mg_kbn ? false : true } required disabled={this.state.isLockForm} />
												</Col>
											</Row>

										<Row>
										<Col sm={6}>
											<Button size="lg" type="submit" className="btn-blue" block >
												次へ
											</Button>
								</Col>
									<Col sm={6}>
											<Button size="lg" type="reset" className="btn-bk" block onClick={()=>{ this.props.history.push('/mypage/'); }} >
												戻る
											</Button>
									</Col>
									</Row>
										</Card.Body>
									</Card>
									</Col>
								</Row>
							</Container>
							</Form>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class NextPage extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			isSuccess       : false,
			isEmailChange   : false,
			isEmailVerify   : false,
			isRedirect      : false,
			ErrMsg          : '',
		}
		this._getData             = this._getData.bind(this);
		this._emailChange         = this._emailChange.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData() {

		try{

			//表示更新
			this.setState({
				isLoading     : false,
			});

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
		}
	}
	async _emailChange(email){
		try {
			const attributes = { email: email }
			const user = await API.Auth.currentAuthenticatedUser();
			await API.Auth.updateUserAttributes(user, attributes);
			return(null);
		}
		catch(error) {
			//console.log(error);
			return(error.message);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading       : true,
		});
		this._getData();
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const email_original = this.props.email_original;
			const email       = this.props.email;
			const name_1      = this.props.name_1;
			const name_2      = this.props.name_2;
			const name_furi_1 = this.props.name_furi_1;
			const name_furi_2 = this.props.name_furi_2;
			const zip_1       = this.props.zip_1;
			const zip_2       = this.props.zip_2;
			const pref        = this.props.pref;
			const addr_1      = this.props.addr_1;
			const addr_2      = this.props.addr_2;
			const addr_3      = this.props.addr_3;
			const tel_1       = this.props.tel_1;
			const tel_2       = this.props.tel_2;
			const tel_3       = this.props.tel_3;
			const birth_y     = this.props.birth_y;
			const birth_m     = this.props.birth_m;
			const birth_d     = this.props.birth_d;
			const sex         = this.props.sex;
			const mg_kbn      = this.props.mg_kbn;

			let isEmailChange = false;
			let email_unverify = null;
			if(email !== email_original){
				const err = await this._emailChange(email);
				if(err){
					this.setState({
						ErrMsg     : err + '<br/>',
						isLockForm : false,
					});
					return(0);
				}
				isEmailChange = true;
				email_unverify = email;
			}

			//カート変更
			const ref = await API.Profile.Update(
						{
							email_unverify : email_unverify,
							name_1      : name_1        ,
							name_2      : name_2        ,
							name_furi_1 : name_furi_1   ,
							name_furi_2 : name_furi_2   ,
							zip_1       : zip_1         ,
							zip_2       : zip_2         ,
							pref        : pref          ,
							addr_1      : addr_1        ,
							addr_2      : addr_2        ,
							addr_3      : addr_3 ? addr_3 : 'null',
							tel_1       : tel_1         ,
							tel_2       : tel_2         ,
							tel_3       : tel_3         ,
							birth_y     : birth_y       ,
							birth_m     : birth_m       ,
							birth_d     : birth_d       ,
							sex         : sex           ,
							mg_kbn_1    : mg_kbn        ,
						}
						);

			if(ref.error !== null){
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
			else{
				//成功
				this.setState({
					isSuccess     : true,
					isLockForm    : false,
					isEmailChange : isEmailChange,
				 });
			}

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
		if(this.state.isEmailChange){
			this.setState({
				isEmailVerify : true,
			});
		}
		else{
			this.setState({
				isRedirect    : true,
			});
		}
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isEmailVerify){
			return (
				<Redirect to="/mypage/emailverify/"/>
			);
		}
		else if(this.state.isRedirect){
			return (
				<Redirect to="/mypage/"/>
			);
		}
		else if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							更新しました。<br/>
							{this.state.isEmailChange ? 'メールアドレスを変更された場合は、新しいメールアドレスに検証コードが到着しています次のページで検証コードを入力して、Eメールアドレスの変更を有効にしてください。' : ''}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
							<p>
								<Image src="/img/step_order02.svg" className="img-fluid te mx-auto d-block" />
							</p>

							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
							<Container>
								<h2>登録内容変更</h2>
								<h3><span>変更内容をご確認ください</span></h3>
								<Row>
									<Col className="offset-md-1 col-md-10">
										<hr className="mt-4"/>
										<Row>
											<Col md={3}>
												お名前
											</Col>
											<Col md={9}>
												{this.props.name_1} {this.props.name_2}（{this.props.name_furi_1} {this.props.name_furi_2}）様
											</Col>
										</Row>
										<hr className="mb-4"/>
										<Row>
											<Col md={3}>
												メールアドレス
											</Col>
											<Col md={9}>
												{this.props.email}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												パスワード
											</Col>
											<Col md={9}>
												＊＊＊＊＊＊＊＊＊＊
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												住所
											</Col>
											<Col md={9}>
												〒{this.props.zip_1}-{this.props.zip_2}<br/>
												{this.props.pref}{this.props.addr_1}{this.props.addr_2}{this.props.addr_3}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												電話番号
											</Col>
											<Col md={9}>
												{this.props.tel_1}-{this.props.tel_2}-{this.props.tel_3}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												生年月日
											</Col>
											<Col md={9}>
												{this.props.birth_y}年{this.props.birth_m}月{this.props.birth_d}日
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												性別
											</Col>
											<Col md={9}>
												{this.props.sex}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												メルマガ購読
											</Col>
											<Col md={9}>
												{this.props.mg_kbn ? 'はい' : 'いいえ'}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col className="offset-md-3 col-md-6">
												<Button type="submit" className="btn-blue" size="lg" block>
													変更する
												</Button>
											</Col>
											<Col className="offset-md-3 col-md-6">
												<Button size="lg" type="reset" className="btn-bk" block onClick={()=>{ this.props._backTop(); }} >
													戻る
												</Button>
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>
							</Form>
						</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}


export default withAuthenticator(withRouter(Default));
