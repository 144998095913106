import { API, graphqlOperation } from 'aws-amplify';

const Order = {};

Order.Get = async function (orderno)  {
		
	try{
		//パラメータ確認
		if(!orderno){ return({ error : "システムIDがありません。" }); }

		const Query = `query getOrder($orderno: String!) {
			getOrder(orderno: $orderno) {
				items{
					orderno
					total_price
					payment_type
					payment_price
					carriage_type
					carriage
					cashdeli
					wrapping_type
					wrapping
					coupon_type
					coupon
					email
					name_1
					name_2
					name_furi_1
					name_furi_2
					zip_1
					zip_2
					pref
					addr_1
					addr_2
					addr_3
					tel_1
					tel_2
					tel_3
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
					o_date_type
					o_date_h
					order_memo
					delivery_no
					delivery_date
					status
					post_kbn
					date_add
					date_part_jst
					order_items{
						code
						name
						price
						sub_price
						piece
						piece_type
					}
				}
			}
		}`;
		const Data = { orderno : orderno };
		const ref = await API.graphql(graphqlOperation(Query, Data));
		let items = ref.data.getOrder.items;
		//console.log(items);
		
		if(!items.length){
			const Query = `query getOldOrder($orderno: String!) {
				getOldOrder(orderno: $orderno) {
					items{
						orderno
						total_price
						payment_type
						payment_price
						carriage_type
						carriage
						cashdeli
						wrapping_type
						wrapping
						coupon_type
						coupon
						email
						name_1
						name_2
						name_furi_1
						name_furi_2
						zip_1
						zip_2
						pref
						addr_1
						addr_2
						addr_3
						tel_1
						tel_2
						tel_3
						o_name_1
						o_name_2
						o_name_furi_1
						o_name_furi_2
						o_zip_1
						o_zip_2
						o_pref
						o_addr_1
						o_addr_2
						o_addr_3
						o_tel_1
						o_tel_2
						o_tel_3
						o_com_name
						o_date_type
						o_date_h
						order_memo
						delivery_no
						delivery_date
						status
						post_kbn
						date_add
						date_part_jst
						order_items{
							code
							name
							price
							sub_price
							piece
							piece_type
						}
					}
				}
			}`;
			const Data = { orderno : orderno };
			const ref = await API.graphql(graphqlOperation(Query, Data));
			items = ref.data.getOldOrder.items;
			//console.log(items);
		}
		
		
		if(items.length){
			return ({
				item: items[0],
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: '受注データが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}

Order.GetAll = async function ()  {
		
	try{
		const Query = `query getOrderAll {
			getOrderAll {
				items{
					orderno
					total_price
					payment_type
					payment_price
					carriage_type
					carriage
					cashdeli
					wrapping_type
					wrapping
					coupon_type
					coupon
					email
					name_1
					name_2
					name_furi_1
					name_furi_2
					zip_1
					zip_2
					pref
					addr_1
					addr_2
					addr_3
					tel_1
					tel_2
					tel_3
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
					o_date_type
					o_date_h
					order_memo
					delivery_no
					delivery_date
					status
					post_kbn
					date_add
					date_part_jst
					order_items{
						code
						name
						price
						sub_price
						piece
						piece_type
					}
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(Query));
		let items = ref.data.getOrderAll.items;
		//console.log(items);

		const OldQuery = `query getOldOrderAll {
			getOldOrderAll {
				items{
					orderno
					total_price
					payment_type
					payment_price
					carriage_type
					carriage
					cashdeli
					wrapping_type
					wrapping
					coupon_type
					coupon
					email
					name_1
					name_2
					name_furi_1
					name_furi_2
					zip_1
					zip_2
					pref
					addr_1
					addr_2
					addr_3
					tel_1
					tel_2
					tel_3
					o_name_1
					o_name_2
					o_name_furi_1
					o_name_furi_2
					o_zip_1
					o_zip_2
					o_pref
					o_addr_1
					o_addr_2
					o_addr_3
					o_tel_1
					o_tel_2
					o_tel_3
					o_com_name
					o_date_type
					o_date_h
					order_memo
					delivery_no
					delivery_date
					status
					post_kbn
					date_add
					date_part_jst
					order_items{
						code
						name
						price
						sub_price
						piece
						piece_type
					}
				}
			}
		}`;
		const Oldref = await API.graphql(graphqlOperation(OldQuery));
		items = items.concat(Oldref.data.getOldOrderAll.items);
		//console.log(items);

		items.sort(function(a,b){
			if(a.date_add > b.date_add) return -1;
			if(a.date_add < b.date_add) return 1;
			return 0;
		});

		if(items.length){
			return ({
				items: items,
				error: null,
			});
		}
		else{
			return ({
				items: null,
				error: '受注データが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

Order.Commit = async function (data)  {

	try{
		//更新
		const Query = `mutation executeOrderCommit($data: OrderInput!) {
			executeOrderCommit(input: $data) {
				orderno
				error
			}
		}`;
		const Data = {
			data : data,
		};
		const ref     = await API.graphql(graphqlOperation(Query, Data));
		const orderno = ref.data.executeOrderCommit.orderno
		const error   = ref.data.executeOrderCommit.error
		
		return ({
			orderno: orderno,
			error  : error,
		});
	}
	catch(error) {
		return({ error: error });
	}
}

Order.CommitV2 = async function (data)  {

	try{
		//更新
		const Query = `mutation executeOrderCommitV2($data: OrderInput!) {
			executeOrderCommitV2(input: $data) {
				orderno
				error
			}
		}`;
		const Data = {
			data : data,
		};
		const ref     = await API.graphql(graphqlOperation(Query, Data));
		const orderno = ref.data.executeOrderCommitV2.orderno
		const error   = ref.data.executeOrderCommitV2.error
		
		return ({
			orderno: orderno,
			error  : error,
		});
	}
	catch(error) {
		return({ error: error });
	}
}

export { Order };
