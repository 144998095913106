import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

import GetItemInfo from '../GetItemInfo';
import './character.css';

class OthersNyansuke extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	
	render() {
		return (
							<div>
							<Header/>
			<main className={this.state.cognitoid ? 'others chara-lp members' : 'others chara-lp'}>
			{/*<Container fluid={true} className="bg_or lp_catelist">
			<Container>
			<div className="pt-4 pb-2">
			<picture>
		<source media="(max-width: 767px)" srcSet="/img/nyansuke/ttl_list_sp.png" />
			 <Image src="/img/nyansuke/ttl_list_pc.png" alt="カテゴリーリスト" />
		 </picture>
			<ul><li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-シール/">シール</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-付箋/">付箋</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-レター/">レター</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ノート/">ノート</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-シャープ/">シャープ</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ボールペン/">ボールペン</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ダイアリー/">ダイアリー</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-アルバム/">アルバム</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-文具小物/">文具小物</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ペンケース・ポーチ/">ペンケース</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ぬいぐるみ/">ぬいぐるみ</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-雑貨/">生活雑貨</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-化粧品/">化粧品</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-その他紙製品/">その他紙製品</a></li>
			</ul>
			</div>
			</Container></Container>*/}
			<Container fluid={true} className="bg_or lp_catelist">
			<div className="pt-5 pb-2">
			<dl><dt><picture>
		<source media="(max-width: 840px)" srcSet="/img/nyansuke/ttl_list_sp.png" />
			 <Image src="/img/nyansuke/ttl_list_pc.png" alt="カテゴリーリスト" />
		 </picture>
		 </dt><dd>
			<ul><li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-シール/">シール</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-付箋/">付箋</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-レター/">レター</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ボールペン/">ボールペン</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ダイアリー/">ダイアリー</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-アルバム/">アルバム</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-文具小物/">文具小物</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ペンケース・ポーチ/">ペンケース</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ランチ/">ランチ</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-布製品/">布製品</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ぬいぐるみ/">ぬいぐるみ</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-雑貨/">雑貨</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-限定商品/">限定商品</a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-その他紙製品/">その他紙製品</a></li>
			</ul>
			</dd></dl>
			</div></Container>
<Container>

	 <Row>
		 	  <div className="top-img">
	   <h2>
		 		<Image src="/img/nyansuke/lp_nyansuke_head.jpg" alt="ごろごろにゃんすけ特集ページ" fluid />
	 	</h2>
	 </div>
		 </Row>
		 <Row><dl className="illustrator"><dt><Image src="/img/nyansuke/murasato.png" alt="イラストレーターの紹介 村里つむぎ" fluid /></dt>
		 <dd>兵庫県神戸市で生まれ育つ。おひるねが日課で、あんこに目がない。<br />クスッと笑えるどこか気の抜けたイラストが得意。<br />
		 猫のいる生活に憧れている。
		 <span className="sns-link"><a href="https://www.instagram.com/tsumugimurasato/"> <i className="fab fa-instagram"></i></a><a href=" https://twitter.com/tsumugimurasato"> <i className="fab fa-twitter"></i></a><a href="https://twitter.com/goronyan_mw"> <i className="fab fa-instagram"></i></a></span></dd></dl></Row>
		 <Row><a href="/search/ごろごろにゃんすけ%2010th%20ANNIVERSARY/" className="opa06 my-5">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202403/bnr_nyansuke10th_sp.jpg" />
		 <Image src="/img/202403/bnr_nyansuke10th.jpg" alt="ごろごろにゃんすけ" className="bdrimg" fluid />
	 </picture></a></Row>
</Container>
<Container>
<div className="pu-item">
	 <h3 className="nyansuke_ttl">
	 <Image src="/img/nyansuke/ttl_puitem_or.png" alt="注目のアイテムをご紹介！" />
	 </h3>

	 <h4 className="nyansuke_sttl">
	 <Image src="/img/nyansuke/subttl_01.png" alt="オンラインストア限定アイテム" />
	 </h4>
		<Row>
			<Col xs={12} sm={4} ><a href="/others/mobilebattery/" className="opa06"><Image src="/img/nyansuke/recommend_01.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/others/glasscase/" className="opa06"><Image src="/img/nyansuke/recommend_02.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/sg_401/" className="opa06"><Image src="/img/nyansuke/recommend_09.jpg" alt="注目のアイテム" fluid /></a></Col>
	 </Row>
	 <ul className="text-right puitem-list">
	 <li><a href="/others/mobilebattery/">◆受注生産◆ モバイルバッテリー(5000mAh)［メーカー発送］ ￥4,378<span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/others/glasscase/">◆受注生産◆ スマホケース［メーカー発送］ ￥2,860<span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/sg_401/">ダイカットスマホグリップ［メーカー発送］ ￥<GetItemInfo code="sg_401" setkey="price" /><span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li></ul>

	 <h4 className="nyansuke_sttl">
	 <Image src="/img/nyansuke/subttl_02.png" alt="いつでもにゃんすけと一緒" />
	 </h4>
		<Row>
			<Col xs={12} sm={4} ><a href="/item/61779/" className="opa06"><Image src="/img/nyansuke/recommend_12.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/61716/" className="opa06"><Image src="/img/nyansuke/recommend_11.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/95246/" className="opa06"><Image src="/img/nyansuke/recommend_10.jpg" alt="注目のアイテム" fluid /></a></Col>
	 </Row>
	 <ul className="text-right puitem-list">
	 <li><a href="/item/61779/"><GetItemInfo code="61779" setkey="name" /> ￥<GetItemInfo code="61779" setkey="price" /><span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/61716/"><GetItemInfo code="61716" setkey="name" /> ￥<GetItemInfo code="61716" setkey="price" /><span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/95246/"><GetItemInfo code="95246" setkey="name" /> ￥<GetItemInfo code="95246" setkey="price" /><span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li></ul>
</div>
	 </Container>

	 <Container className="my-5">
		<Row>
	 <h3 className="nyansuke_ttl">
	 <Image src="/img/nyansuke/ttl_pucate_or.png" alt="注目のカテゴリーをご紹介！" />
	 </h3></Row>
	 </Container>
<Container fluid={true} className="bg_bl py-5 my-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/nyansuke/cate_nuigurumi_sp.png" />
 <Image className="my-5" src="/img/nyansuke/cate_nuigurumi.png" alt="ぬいぐるみ" fluid />
</picture>
<div className="pucate-item">
<Row>
 <Col xs={12} sm={3} ><a href="/item/94295/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94295_main.jpg" alt="" fluid /></a>
 <p><a href="/item/94295/"><GetItemInfo code="94295" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} ><a href="/item/61428/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61428_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61428/"><GetItemInfo code="61428" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} ><a href="/item/94800/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94800_main.jpg" alt="" fluid /></a>
 <p><a href="/item/94800/"><GetItemInfo code="94800" setkey="name" /></a></p></Col>
 <Col xs={12} sm={3} >
  {/*<Row className="mb-5">
<Col xs={6} sm={6} ><a href="/item/94653/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94653_main.jpg" alt="" fluid /></a></Col>
 <Col xs={6} sm={6} ><a href="/item/94655/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94655_main.jpg" alt="" fluid /></a></Col></Row>
 <Row><Col xs={6} sm={6} ><a href="/item/94538/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94538_main.jpg" alt="" fluid /></a></Col>
 <Col xs={6} sm={6} ><a href="/item/94537/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94537_main.jpg" alt="" fluid /></a></Col></Row>*/}
<div className="nui-slist">
<a href="/item/61716/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61716_main.jpg" alt="" fluid /></a>
<a href="/item/94655/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94655_main.jpg" alt="" fluid /></a>
<p><a href="/search/ごろごろにゃんすけ%E3%80%80ぬいぐるみマスコット/">ぬいぐるみマスコット</a></p></div>
<div className="nui-slist">
<a href="/item/94538/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94538_main.jpg" alt="" fluid /></a>
<a href="/item/94537/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94537_main.jpg" alt="" fluid /></a>
<p><a href="/search/ごろごろにゃんすけ%E3%80%80モチモチマスコット/">モチモチマスコット</a></p></div>
</Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ぬいぐるみ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK  <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/nyansuke/cate_gentei_sp.png" />
 <Image className="my-5" src="/img/nyansuke/cate_gentei.png" alt="限定商品" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={12} sm={3} ><a href="/item/61428/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61428_main.jpg" alt="" fluid /></a>
<p><a href="/item/61428/"><GetItemInfo code="61428" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/NA002/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/NA002_main.jpg" alt="" fluid /></a>
 <p><a href="/item/NA002/"><GetItemInfo code="NA002" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/61866/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61866_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61866/"><GetItemInfo code="61866" setkey="name" /></a></p></Col>{/* */}
 <Col xs={6} sm={3} ><a href="/item/61746/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61746_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61746/"><GetItemInfo code="61746" setkey="name" /></a></p></Col>

</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ごろごろにゃんすけ-限定商品/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK  <i className="fas fa-arrow-right"></i></button></a></div>
</Container>

<Container fluid={true} className="bg_bl py-5 my-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/nyansuke/cate_seal_sp.png" />
 <Image className="my-5" src="/img/nyansuke/cate_seal.png" alt="シール" fluid />
</picture>
<div className="pucate-item">
<Row>
 <Col xs={6} sm={3} ><a href="/item/61892/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61892_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61892/"><GetItemInfo code="61892" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/81749/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/81749_main.jpg" alt="" fluid /></a>
 <p><a href="/item/81749/"><GetItemInfo code="81749" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/61839/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61839_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61839/"><GetItemInfo code="61839" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/81169/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/81169_main.jpg" alt="" fluid /></a>
 <p><a href="/item/81169/"><GetItemInfo code="81169" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ごろごろにゃんすけ-シール/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK  <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>

<Container className="my-5">
<picture>
 <source media="(max-width: 767px)" srcSet="/img/nyansuke/cate_collabo_sp.png" />
		<Image className="my-5" src="/img/nyansuke/cate_collabo.png" alt="ごろにゃん＆しばんばんコラボ" fluid />
	</picture>
	<div className="pucate-item">
	<Row>
		<Col xs={12} sm={4} ><a href="/item/57357/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57357_main.jpg" alt="" fluid /></a>
		<p><a href="/item/57357/"><GetItemInfo code="57357" setkey="name" /></a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/94268/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94268_main.jpg" alt="" fluid /></a>
		<p><a href="/item/94268/"><GetItemInfo code="94268" setkey="name" /></a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/57391/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57391_main.jpg" alt="" fluid /></a>
		<p><a href="/item/57391/"><GetItemInfo code="57391" setkey="name" /></a></p></Col>

 </Row>
 </div>
 <div className="text-right"><a href="/category/キャラクターから探す-ごろにゃん＆しばんばん%E3%80%80コラボ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK  <i className="fas fa-arrow-right"></i></button></a></div>
</Container>


{/* 
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/nyansuke/cate_cosme_sp.png" />
 <Image className="mt-5" src="/img/nyansuke/cate_cosme.png" alt="化粧品" fluid />
</picture>
<h4 className="">
 <Image className="mb-4 ttl_furupuru" src="/img/nyansuke/ttl_furupuru.png" alt="FURUPURU COSME" />
 </h4>
<div className="pucate-item">
<Row>
 <Col xs={6} sm={4} ><a href="/search/ごろごろにゃんすけ%E3%80%80フルプルクリーム/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/93598_main.jpg" alt="" fluid /></a>
 <p className="s-cate"><a href="/search/ごろごろにゃんすけ%E3%80%80フルプルクリーム/">クリーム  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>

 <Col xs={6} sm={4} ><a href="/search/ごろごろにゃんすけ%E3%80%80ハンドクリーム/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/93993_main.jpg" alt="" fluid /></a>
 <p className="s-cate"><a href="/search/ごろごろにゃんすけ%E3%80%80ハンドクリーム/">ハンドクリーム  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>
 <Col xs={6} sm={4} ><a href="/search/ごろごろにゃんすけ%E3%80%80フェイスマスク/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94585_main.jpg" alt="" fluid /></a>
 <p className="s-cate"><a href="/search/ごろごろにゃんすけ%E3%80%80フェイスマスク/">フェイスマスク  <i className="fas fa-arrow-alt-circle-right"></i></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ごろごろにゃんすけ-化粧品/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK  <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
*/}
<Container fluid={true} className="bg_bl py-5 mt-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/nyansuke/cate_zakka_sp.png" />
 <Image className="my-5" src="/img/nyansuke/cate_zakka.png" alt="雑貨" fluid />
</picture>
<div className="pucate-item">
<Row>
  <Col xs={6} sm={3} ><a href="/item/95246/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95246_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95246/"><GetItemInfo code="95246" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/LA2207D/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/LA2207D_main.jpg" alt="" fluid /></a>
 <p><a href="/item/LA2207D/"><GetItemInfo code="LA2207D" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/95472/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95472_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95472/"><GetItemInfo code="95472" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/FI_001_05set/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/FI_001_05set_main.jpg" alt="" fluid /></a>
 <p><a href="/item/FI_001_05set/"><GetItemInfo code="FI_001_05set" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ごろごろにゃんすけ-雑貨/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK  <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>
<Container className="my-3"><Row>
<Col xs={12} sm={{ span: 6, offset: 3 }} ><a href="/category/キャラクターから探す-ごろごろにゃんすけ/" className="btn_or w-100 btn btn-smp btn-lg py-3 my-5">全商品一覧はこちら <i className="fa fa-arrow-right"></i></a></Col>
</Row></Container>
<Container className="">
<a href="../30th/"><picture>
<source media="(max-width: 767px)" srcSet="/img/top/bnr_30th_sp.jpg" />
<Image className="mb-3" src="/img/top/bnr_30th.jpg" alt="30周年特設ページ" fluid />
</picture></a>
<Row>
<Col xs={12} sm={6} ><a href="../shibanban/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-banban_sp.jpg" alt="しばんばん" fluid /></a></Col>
<Col xs={12} sm={6} ><a href="../piyokomame/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-piyoko_sp.jpg" alt="ぴよこ豆" fluid /></a></Col>
 {/*<Col xs={12} sm={6} ><Image className="mb-3" src="/img/202002/bnr_lp-piyoko_sp02.jpg" alt="ぴよこ豆" fluid /></Col>*/}
 </Row>
 <div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る  <i className="fas fa-arrow-right"></i></a></div>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersNyansuke);
