import { API, graphqlOperation } from 'aws-amplify';

const Category = {};

Category.GetId = async function (category_a, category_b, category_c, category_d)  {

	try{
	
		category_a = category_a ? category_a : null;
		category_b = category_b ? category_b : null;
		category_c = category_c ? category_c : null;
		category_d = category_d ? category_d : null;
	
		const Query = `query searchCategory($category_a: String, $category_b: String, $category_c: String, $category_d: String) {
			searchCategory(category_a: $category_a, category_b: $category_b ,category_c: $category_c ,category_d: $category_d) {
				categoryid
			}
		}`;
		const Data = {
			category_a : category_a,
			category_b : category_b,
			category_c : category_c,
			category_d : category_d,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const Items = ref.data.searchCategory;
		
		return ({
			items: Items,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

export { Category };


