import { API, graphqlOperation } from 'aws-amplify';

const SquareCard = {};

SquareCard.Get = async function (card_id)  {
		
	try{
		const Query = `query getSquareCard($card_id: String!) {
			getSquareCard(card_id: $card_id) {
				card_brand
				last_4
				exp_month
				exp_year
				cardholder_name
				fingerprint
				use_flag
			}
		}`;
		const Data = {
			card_id : card_id,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const item = ref.data.getSquareCard;
		
		const card_brand      = item.card_brand      ;
		const last_4          = item.last_4          ;
		const exp_month       = item.exp_month       ;
		const exp_year        = item.exp_year        ;
		const cardholder_name = item.cardholder_name ;
		const fingerprint     = item.fingerprint     ;
		const use_flag        = item.use_flag        ;
		
		if(card_brand){
			return ({
				item: {
					card_id         : card_id         ,
					card_brand      : card_brand      ,
					last_4          : last_4          ,
					exp_month       : exp_month       ,
					exp_year        : exp_year        ,
					cardholder_name : cardholder_name ,
					fingerprint     : fingerprint     ,
					use_flag        : use_flag        ,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: 'クレジットカード情報が見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

SquareCard.GetAll = async function ()  {
		
	try{
		const Query = `query getSquareCardAll {
			getSquareCardAll {
				items{
					card_id
					card_brand
					last_4
					exp_month
					exp_year
					cardholder_name
					fingerprint
					use_flag
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(Query));
		const items = ref.data.getSquareCardAll.items;

		const newData = [];

		for(const item of items){
				newData.push({
					card_id         : item.card_id         ,
					card_brand      : item.card_brand      ,
					last_4          : item.last_4          ,
					exp_month       : item.exp_month       ,
					exp_year        : item.exp_year        ,
					cardholder_name : item.cardholder_name ,
					fingerprint     : item.fingerprint     ,
					use_flag        : item.use_flag        ,
				});
		}
		
		if(newData.length !== 0){
			return ({
				items: newData,
				error: null,
			});
		}
		else{
			return ({
				items: null,
				error: 'クレジットカード情報が見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

SquareCard.CustomerAdd = async function (email, name_1, name_2)  {

	try{
		
		//新規
		const Query = `mutation executeSquareCustomer($email: String, $name_1: String, $name_2: String) {
			executeSquareCustomer(email: $email, name_1: $name_1, name_2: $name_2) {
				result
				error
			}
		}`;
		const Data = {
			email  : email,
			name_1 : name_1,
			name_2 : name_2,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const square_id = ref.data.executeSquareCustomer.result;
		
		return ({
			square_id: square_id,
			error    : null,
		});
	}
	catch(error) {
		console.log(error);
		return ({
			square_id: null,
			error    : error,
		});
	}
}

SquareCard.CardAdd = async function (card_nonce, square_id)  {

	try{
		if(!square_id){
			const getQuery = `query getSquareCardAll {
				getSquareCardAll {
					scannedCount
				}
			}`;
			const ref = await API.graphql(graphqlOperation(getQuery));
			const count = ref.data.getSquareCardAll.scannedCount;
			
			//カートは1度に100件まで
			if(count >= 5){ return({ error : "クレジットカードは5件までしか登録出来ません。" }); }
		}
		
		//新規
		const Query = `mutation executeSquareCard($flag: String, $card_nonce: String, $square_id: String) {
			executeSquareCard(flag: $flag, card_nonce: $card_nonce, square_id: $square_id) {
				result
				error
			}
		}`;
		const Data = {
			flag       : 'create',
			card_nonce : card_nonce,
			square_id  : square_id,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const card_id = ref.data.executeSquareCard.result;
		const error = ref.data.executeSquareCard.error;

		return ({
			card_id : card_id,
			error: error,
		});
	}
	catch(error) {
		console.log(error);
		return({
			card_id: null,
			error: error,
		});
	}
}


SquareCard.Remove = async function (card_id)  {

	try{
		//削除
		const Query = `mutation executeSquareCard($flag: String, $card_id: String) {
			executeSquareCard(flag: $flag, card_id: $card_id) {
				result
				error
			}
		}`;
		const Data = {
			flag : 'delete',
			card_id : card_id,
		};
		await API.graphql(graphqlOperation(Query, Data));

		return ({ error: null });
	}
	catch(error) {
		console.log(error);
		return({ error: error });
	}
}

export { SquareCard };


