import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Button,
	Modal,
	Pagination,
	Form,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../@Include/NotFound';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
import SiteFlag from '../@Include/SiteFlag';

class PageN extends React.Component {
	render() {
		const page     = Number(this.props.page);
		const max      = Number(this.props.max);

		//前後表示する件数
		const count = 1;

		//スタート位置
		let start  = (page - count) >= 1 ? (page - count) : 1;

		//終了位置
		let end    = (start + (count * 1)) >= max ? max : (start + (count * 1));

		//現在値より終了位置が前後表示する件数より少なかった場合は、その件数分をスタート位置へ追加する
		if( (end - page) < count ){
			const diff = count - (end - page);
			start  = (start - diff) >= 1 ? (start - diff) : 1;
		}

		//ページアイテム作成
		let items = [];
		for (let number = start; number <= end; number++) {
			items.push(
				<Pagination.Item key={number} active={number === page} onClick={()=>{ this.props.pageLoad(number); }} >
				{number}
				</Pagination.Item>,
			);
		}

		//最後のページの区切りボタンを作る
		let ellipsis = '';
		let enditem = '';
		if(end !== max){
			//終了位置ボタンと最終ボタンに飛びがなければ区切りは表示しない
			if((end + 1) !== max){
				ellipsis = <Pagination.Ellipsis />;
			}
			enditem = <Pagination.Item onClick={()=>{ this.props.pageLoad(max); }} active={max === page}>{max}</Pagination.Item>;
		}

		return(
			<Pagination>
			<Pagination.First onClick={()=>{ this.props.pageLoad(1); }} />
			<Pagination.Prev  onClick={()=>{ this.props.pageLoad(Math.max(1, (page - 1))); }} />
			{items}
			{ellipsis}
			{enditem}
			<Pagination.Next onClick={()=>{ this.props.pageLoad(Math.min(max, (page + 1))); }} />
			<Pagination.Last onClick={()=>{ this.props.pageLoad(max); }}/>
			</Pagination>
		);
	}
}

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
			itemCount      : 0,
			itemData       : [],
			category       : '',
			page           : 1,
			size           : 30,
			max            : 0,
			sort           : '新しい順',
		}
		this.handleSort       = this.handleSort.bind(this);
		this._pageLoad        = this._pageLoad.bind(this);
		this._getData         = this._getData.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
	}
	handleSort(e) {
		window.location.href = '/category/' + this.state.category + '/' + 1 + '/' + e.target.value + '/';
	}
	_pageLoad(page) {
		this.setState({
			isLoading   : true,
		});
		//this.props.history.push('/category/' + keyword + '/' + page + '/');
		//this._getData(keyword, page);
		window.location.href = '/category/' + this.state.category + '/' + page + '/' + this.state.sort + '/';
	}
	async _getData(category, page, sort) {

		try{

			//取得
			const size = this.state.size;

			//IDの取得
			category = category ? category : this.props.match.params.category;
			page     = page     ? page     : this.props.match.params.page;
			sort     = sort     ? sort     : this.props.match.params.sort;

			//カテゴリ
			let category_b = null;
			let category_c = null;
			let category_d = null;

			//カテゴリ文字列を切り分ける
			if(category && category !== 'default'){
				const categorys = category.split('-');

				//それぞれに代入
				category_b = categorys[0] ? categorys[0] : '';
				category_c = categorys[1] ? categorys[1] : '';
				category_d = categorys[2] ? categorys[2] : '';
			}
			else{
				category = 'default';
			}

			//数値確認
			page = (page >= 1 && page < 999) ? page : 1;

			//対象のカテゴリコードを取得
			const refCategory = await API.Category.GetId(SiteFlag.ja, category_b, category_c, category_d);
			//console.log(refCategory);

			//エラーかどうか
			if(refCategory.error !== null){
				//Not Found
				this.setState({
					isLoading      : false,
					isNotFound     : true,
				});
				return(0);
			}

			//商品データ取得
			const refItem = await API.Item.GetCategoryListV2(SiteFlag.en, refCategory.items, page, size, sort);
			console.log(refItem);

			//エラーかどうか
			if(refItem.error !== null){
				//Not Found
				this.setState({
					isLoading      : false,
					isNotFound     : true,
				});
				return(0);
			}

			//内容
			const items = refItem.items ? refItem.items : [];
			const count = refItem.count ? refItem.count : 0;

			//エラーかどうか
			//if(refItem.items.length === 0){
			//	//Not Found
			//	this.setState({
			//		isLoading      : false,
			//		isNotFound     : true,
			//	});
			//	return(0);
			//}

			//ページ数
			const max = Math.ceil(count / size);

			//データがあれば
			this.setState({
				isLoading      : false,
				category       : category,
				category_b     : category_b,
				category_c     : category_c,
				category_d     : category_d,
				itemCount      : count,
				itemData       : items,
				page           : page,
				max            : max,
				sort           : sort,
			});
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg,
			 });
			return(0);
		}
	}

			async cartAdd (code) {

				//更新処理
				try{

					//カート追加
					const ref = await API.Cart.Add(code, 1);

					if(ref.error === null){
						//カートに移動
						this.props.history.push('/cart/');
					}
					else{
						//エラー処理
						const error = ref.error;
						let err_msg = '';
						if(error.errors){
							error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
						}
						else{
							err_msg = error + '<br/>';
						}
						this.setState({
							ErrMsg     : err_msg + '<br/>',
							isLockForm  : false,
						});
						return(0);
					}
				}
				catch(error) {
					let err_msg = '';
					if(error.errors){
						error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
					}
					else{
						err_msg = error + '<br/>';
					}
					this.setState({
						ErrMsg     : err_msg,
						isLockForm : false,
					 });
					return(0);
				}
			}

	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 3000, marginBottom: 3000 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header />
					<main>
						<div className="head-logo sec"><a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a></div>

						<Container>

							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div>キャラクターで探す</div>
								<div><a href={'/category/' + this.state.category_b ? this.state.category_b + (this.state.category_c ? '-' + this.state.category_c : '') : ''}>{this.state.category_c}</a></div>
								<div>{this.state.category_d}</div>
							</div>
						</Container>

						<Container>
							<h1>ITEM LIST<p>商品一覧</p></h1>
								{/*<h2>{this.state.category_b ? this.state.category_b + (this.state.category_c ? '：' + this.state.category_c + (this.state.category_d ? '：' + this.state.category_d : '') : '') : ''}（{this.state.itemCount}商品）</h2>*/}
							<h2>{this.state.category_b ? this.state.category_c + (this.state.category_d ? ' ' + this.state.category_d : '') : ''}（{this.state.itemCount}商品）</h2>
						</Container>

						<Container>
							<Row>
								<Col md={{ span: 4, offset: 8 }}>
									<Form>
										<Form.Control as="select" size="lg" defaultValue={this.state.sort} onChange={this.handleSort}>
										<option>新しい順</option>
										<option>安い順</option>
										<option>高い順</option>
										<option>商品名順（昇順）</option>
										<option>商品名順（降順）</option>
										</Form.Control>
									</Form>
								</Col>
							</Row>
						</Container>

						<Container>
							<PageN page={this.state.page} max={this.state.max} pageLoad={this._pageLoad} {...this.props}/>
						</Container>

						<Container>

							<div className="item-wrap">
								{
									this.state.itemData.map( item =>{
										 const code       = item.code;
										 const name       = item.name;
										 const price       = item.price;
										 const zaiko       = item.zaiko;
										 const flag_3       = item.flag_3;
										 const zaiko_flag  = item.zaiko_flag;
										return(
											<div className="list-item-box" key={code}>
												<div onClick={()=>{ this.props.history.push('/item/' + code + '/'); }} >
													<Image className="itemlist-img" src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} />
													<h5 className="list-title">{name}</h5>
													<p className="list-price">￥{price}<span>（税込）</span></p>
								{(() => {
									if (flag_3 === true){
																					return(
								<p className="float-left mr-3"><Badge pill variant="info">SALE</Badge></p>
																					);
																				}
									})()}


								{(() => {
									if (zaiko === 0){
										return(
											<p className="float-left"><Badge pill variant="warning">在庫０</Badge></p>
											);
										}
								else if (zaiko_flag === false){
									return (
										<p className="float-left"><small>{zaiko} 個</small></p>
												);
							}}
								)()}


										</div>
										<p className="float-right">
											<Button type="button" className="btn-list-cart" onClick={()=>{ this.cartAdd(code) }}>
										<i className="fa fa-cart-arrow-down"></i>
										</Button>
										</p>
										</div>
										);
							})}
							</div>
						</Container>
	    					<Container>
							<PageN page={this.state.page} max={this.state.max} pageLoad={this._pageLoad} {...this.props}/>
						</Container>


	{/*	<h4>こちらもオススメです</h4>*/}

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withRouter(Default);
