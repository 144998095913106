import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NotFound from './Pages/@Include/NotFound';
import Main from './Pages/Main';
import CartMain from './Pages/Cart/Main';
import ItemMain from './Pages/Item/Main';
import CategoryMain from './Pages/Category/Main';
import SearchMain from './Pages/Search/Main';
import OrderMain from './Pages/Order/Main';
import MyPageMain from './Pages/MyPage/Main';
import MyPageUnsub from './Pages/MyPage/Unsub';
import MyPageProfile from './Pages/MyPage/Profile';
import MyPageEmailVerify from './Pages/MyPage/EmailVerify';
import MyPagePassword from './Pages/MyPage/Password';
import MyPageAddress from './Pages/MyPage/Address';
import MyPageAddressDetails from './Pages/MyPage/AddressDetails';
import MyPageAddressNew from './Pages/MyPage/AddressNew';
import MyPageOrder from './Pages/MyPage/Order';
import MyPageOrderDetails from './Pages/MyPage/OrderDetails';
import MyPageWishList from './Pages/MyPage/WishList';
import MemberMain from './Pages/Member/Main';
import MemberVerify from './Pages/Member/Verify';
import MemberResent from './Pages/Member/Resent';
import LoginMain from './Pages/Login/Main';
import LogoutMain from './Pages/Logout/Main';
import StationaryIndex from './Pages/StationaryIndex';
import GuideMain from './Pages/Guide/Main';
import GuidePayment from './Pages/Guide/Payment';
import GuideSend from './Pages/Guide/Send';
import GuideFaq from './Pages/Guide/Faq';
import OthersMain from './Pages/Others/Main';
import OthersSmp from './Pages/Others/Smp';
import OthersFukubukuro from './Pages/Others/Fukubukuro';
import OthersNyansuke from './Pages/Others/Nyansuke';
import OthersShibanban from './Pages/Others/Shibanban';
import OthersAnniv30th from './Pages/Others/Anniv30th';
import OthersPiyokomame from './Pages/Others/Piyokomame';
import OthersAndroid from './Pages/Others/Android';
import OthersMobilebattery from './Pages/Others/Mobilebattery';
import OthersKotorimachi from './Pages/Others/Kotorimachi';
import OthersMobileitem from './Pages/Others/Mobileitem';
import OthersSandcase from './Pages/Others/Sandcase';
import OthersAirpods from './Pages/Others/Airpods';
import OthersGlasscase from './Pages/Others/Glasscase';
import OthersStrapcase from './Pages/Others/Strapcase';
import OthersHumidifier from './Pages/Others/Humidifier';
import OthersSpeaker from './Pages/Others/Speaker';
import OthersBine from './Pages/Others/Bine';
import CompanyMain from './Pages/Company/Main';
import CompanyRaw from './Pages/Company/Raw';
import CompanyPolicy from './Pages/Company/Policy';
import CompanyTerms from './Pages/Company/Terms';
import ContactMain from './Pages/Contact/Main';

class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<div className="wrap">
					<Switch>
						<Route exact path='/' component={Main} />
						<Route exact path='/stationary_index/' component={StationaryIndex} />
						<Route path='/order/' component={OrderMain} />
						<Route path='/search/:keyword/:page/:sort/' component={SearchMain} />
						<Route path='/search/:keyword/:page/' component={SearchMain} />
						<Route path='/search/:keyword/' component={SearchMain} />
						<Route path='/search/' component={SearchMain} />
						<Route path='/category/:category/:page/:sort/' component={CategoryMain} />
						<Route path='/category/:category/:page/' component={CategoryMain} />
						<Route path='/category/:category/' component={CategoryMain} />
						<Route path='/category/' component={CategoryMain} />
						<Route path='/cart/' component={CartMain} />
						<Route path='/item/:code/' component={ItemMain} />
						<Route path='/mypage/wishlist/' component={MyPageWishList} />
						<Route path='/mypage/order/:orderno/' component={MyPageOrderDetails} />
						<Route path='/mypage/order/' component={MyPageOrder} />
						<Route path='/mypage/address/new/' component={MyPageAddressNew} />
						<Route path='/mypage/address/:addressid/' component={MyPageAddressDetails} />
						<Route path='/mypage/address/' component={MyPageAddress} />
						<Route path='/mypage/password/' component={MyPagePassword} />
						<Route path='/mypage/emailverify/' component={MyPageEmailVerify} />
						<Route path='/mypage/profile/' component={MyPageProfile} />
						<Route path='/mypage/unsub/' component={MyPageUnsub} />
						<Route path='/mypage/' component={MyPageMain} />
						<Route path='/member/resent/' component={MemberResent} />
						<Route path='/member/verify/' component={MemberVerify} />
						<Route path='/member/' component={MemberMain} />
						<Route path='/login/' component={LoginMain} />
						<Route path='/logout/' render={props => <LogoutMain {...this.props} />} />
						<Route path='/guide/payment' component={GuidePayment} />
					  	<Route path='/guide/send' component={GuideSend} />
						<Route path='/guide/faq' component={GuideFaq} />
						<Route path='/guide' component={GuideMain} />
						<Route path='/others/smp' component={OthersSmp} />
						<Route path='/others/fukubukuro' component={OthersFukubukuro} />
						<Route path='/others/nyansuke' component={OthersNyansuke} />
						<Route path='/others/shibanban' component={OthersShibanban} />
						<Route path='/others/30th' component={OthersAnniv30th} />
						<Route path='/others/piyokomame' component={OthersPiyokomame} />
						<Route path='/others/android' component={OthersAndroid} />
						<Route path='/others/mobilebattery' component={OthersMobilebattery} />
						<Route path='/others/kotorimachi' component={OthersKotorimachi} />
						<Route path='/others/mobileitem' component={OthersMobileitem} />
						<Route path='/others/sandcase' component={OthersSandcase} />
						<Route path='/others/glasscase' component={OthersGlasscase} />
						<Route path='/others/strapcase' component={OthersStrapcase} />
						<Route path='/others/humidifier' component={OthersHumidifier} />
						<Route path='/others/speaker' component={OthersSpeaker} />
						<Route path='/others/airpods' component={OthersAirpods} />
						<Route path='/others/bine' component={OthersBine} />
						<Route path='/others' component={OthersMain} />
						<Route path='/company/law' component={CompanyRaw} />
						<Route path='/company/policy' component={CompanyPolicy} />
						<Route path='/company/terms' component={CompanyTerms} />
						<Route path='/company' component={CompanyMain} />
						<Route path='/contact' component={ContactMain} />
						<Route component={NotFound} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
