import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Image,
	Card,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isLockForm     : false,
			isSuccess      : false,
			isLogin        : false,
			ErrMsg         : '',
			email          : '',
			passwd         : '',
		}
		this._reload          = this._reload.bind(this);
		this._resendSignUp    = this._resendSignUp.bind(this);
		this._getData         = this._getData.bind(this);
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	_reload() {
		this.setState({ isSubmit: false});
	}
	async _resendSignUp(email){
		try {
			await API.Auth.resendSignUp(email);
			return(null);
		}
		catch(error) {
			console.log(error);
			return(error.message);
		}
	}
	async _getData() {

		//ログインの確認
		try{
			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			//ログイン済みの場合フラグ
			if(cognitoid){
				this.setState({
					isLoading  : false,
					isLogin    : true,
				});
				return(0);
			}
		}
		catch(error) {
			//読み込み完了
			this.setState({
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const email = formObj.email.value;

			//サインアップ確認
			const err = await this._resendSignUp(email);
			console.log(err);
			if(err){
				this.setState({
					ErrMsg     : err + '<br/>',
					isLockForm : false,
				});
				return(0);
			}
			else{
				//成功
				this.setState({
					isSuccess     : true,
					isLockForm    : false,
				 });
			}

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
		//
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLogin){
			return (
				<Redirect to="/mypage/"/>
			);
		}
		else if(this.state.isSuccess){
			return (
				<EndPage {...this.state} {...this.props} reload={this._reload} />
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/member/">新規会員登録</a></div>
							</div>
						</Container>



						<Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
						<Container>
							<h3><span>検証コードの再送</span></h3>
							<Row>
								<Col className="offset-sm-1 col-sm-10 offset-md-2 col-md-8 mt-5">
									<Card>
										<Card.Body>
											<Form.Group controlId="email">
												<Form.Label>メールアドレス</Form.Label>
											        <Form.Control name="email" type="email" placeholder="メールアドレス" pattern=".{1,255}" size="lg" required disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													メールアドレスを入力してください
												</Form.Control.Feedback>
											</Form.Group>


											<Button type="submit" className="btn-blue" size="lg" block disabled={this.state.isLockForm}>再送</Button>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
						</Form>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class EndPage extends React.Component {
	render() {
		return (
				<div>
					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/member/">新規会員登録</a></div>
							</div>
						</Container>


						<Container>
							<h1>REGISTRATION<p>新規会員登録</p></h1>
							<h2>完了</h2>
							<h3><span>検証コードの再送が完了しました</span></h3>
						</Container>

						<Row>
							<Col className="offset-md-1 col-md-10">
								<Row>
									<Col md={12} className="mb-4">
										<p className="text-center">登録したメールアドレスに検証コードを再送しています。下記より、検証コードを入力してください。</p>
										<Button className="btn-blue" size="lg" block onClick={() => this.props.history.push('/member/verify/')}>検証コードの入力はこちら</Button>
									</Col>
								</Row>
							</Col>
						</Row>

					</main>
					<Footer/>
				</div>
		);
	}
}

export default withRouter(Default);
