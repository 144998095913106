import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersSandcase extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/sandcase/header-sc_sp.jpg" />
	 		<Image src="/img/sandcase/header-sc.jpg" alt="オンラインストア限定スマートフォンクリアケース" fluid />
	 	</picture>
	 </div>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobileitem/period_sp.jpg" />
	 		<Image src="/img/mobileitem/period.jpg" alt="期間" fluid />
	 	</picture>
	 </div>
	</Container>
	<Container>
	<picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution2_sp.png" />
		<Image className="mb-5" src="/img/android/ac-caution2.png" alt="ご注意" fluid />
	</picture>	
	 </Container>

	 <Container>
	 <div className="my-5 pb-3">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/sandcase/sc_color_sp.jpg" />
		 <Image className="mt-5" src="/img/sandcase/sc_color.jpg" alt="カラバリ" fluid />
	 </picture>
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution3_sp.png" />
		<Image src="/img/android/ac-caution3.png" alt="ご注意" fluid className="my-5" />
	</picture>

	</div>
	 </Container>

	  <Container>
		<div id="sandcase" className="mt-5 model">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/sandcase/ttl_list_sp.png" />
		 <Image className="mb-5" src="/img/sandcase/ttl_list.png" alt="" fluid />
	 </picture>
		<Row>
	 	<Col xs={12} sm={6} md={4}><dl className=""><dt>ごろごろにゃんすけ</dt>
		 <dd><a href="/category/サンドケース-ごろごろにゃんすけ/" className="opa06"><Image src="/img/sandcase/scd_nyansuke.png" alt="" fluid className="my-3"/></a>
		 <p className="title-border">カラーで選ぶ</p>
		 <ul className="color-list">
			 <li><a href="/category/サンドケース-ごろごろにゃんすけ-ピンク×パープル/" className="opa06"><Image src="/img/sandcase/pk-pu.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ごろごろにゃんすけ-ピンク×ライトブルー/" className="opa06"><Image src="/img/sandcase/pk-bl.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ごろごろにゃんすけ-ピンク×ブラック/" className="opa06"><Image src="/img/sandcase/pk-bk.png" alt="" fluid/></a></li>
			 {/*<li><a href="/category/サンドケース-ごろごろにゃんすけ-オレンジ×イエロー/" className="opa06"><Image src="/img/sandcase/or-ye.png" alt="" fluid/></a></li>*/}
			 <li><a href="/category/サンドケース-ごろごろにゃんすけ-イエロー×レッド/" className="opa06"><Image src="/img/sandcase/ye-rd.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ごろごろにゃんすけ-グリーン×ライムグリーン/" className="opa06"><Image src="/img/sandcase/gr-lgr.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ごろごろにゃんすけ-ホワイト×ブラック/" className="opa06"><Image src="/img/sandcase/wh-bk.png" alt="" fluid/></a></li>
		 </ul>
		 <p className="title-border">機種で選ぶ</p>
		 <ul className="model-list">
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(8･7)" className="">iPhone 7/8/SE2</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(XS･X)" className="">iPhone X/XS</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(11)" className="">iPhone 11</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(11pro)" className="">iPhone 11Pro</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(12･12pro)" className="">iPhone 12/12pro</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(12mini)" className="">iPhone 12mini</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(12ProMax)" className="">iPhone 12ProMax</a></li>
		<li><a href="/category/サンドケース-ごろごろにゃんすけ-iPhone(13･14)" className="">iPhone 13･14</a></li>
		</ul>
		 </dd></dl>
		 </Col>
		 <Col xs={12} sm={6} md={4}><dl className=""><dt>しばんばん</dt>
		 <dd><a href="/category/サンドケース-しばんばん/" className="opa06"><Image src="/img/sandcase/scd_banban.png" alt="" fluid className="my-3"/></a>
		 <p className="title-border">カラーで選ぶ</p>
		 <ul className="color-list">
			 <li><a href="/category/サンドケース-しばんばん-ピンク×パープル/" className="opa06"><Image src="/img/sandcase/pk-pu.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-しばんばん-ピンク×ライトブルー/" className="opa06"><Image src="/img/sandcase/pk-bl.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-しばんばん-ピンク×ブラック/" className="opa06"><Image src="/img/sandcase/pk-bk.png" alt="" fluid/></a></li>
			 {/*<li><a href="/category/サンドケース-しばんばん-オレンジ×イエロー/" className="opa06"><Image src="/img/sandcase/or-ye.png" alt="" fluid/></a></li>*/}
			 <li><a href="/category/サンドケース-しばんばん-イエロー×レッド/" className="opa06"><Image src="/img/sandcase/ye-rd.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-しばんばん-グリーン×ライムグリーン/" className="opa06"><Image src="/img/sandcase/gr-lgr.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-しばんばん-ホワイト×ブラック/" className="opa06"><Image src="/img/sandcase/wh-bk.png" alt="" fluid/></a></li>
		 </ul>
		 <p className="title-border">機種で選ぶ</p>
		 <ul className="model-list">
		<li><a href="/category/サンドケース-しばんばん-iPhone(8･7)" className="">iPhone 7/8/SE2</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(XS･X)" className="">iPhone X/XS</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(11)" className="">iPhone 11</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(11pro)" className="">iPhone 11Pro</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(12･12pro)" className="">iPhone 12/12pro</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(12mini)" className="">iPhone 12mini</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(12ProMax)" className="">iPhone 12ProMax</a></li>
		<li><a href="/category/サンドケース-しばんばん-iPhone(13･14)" className="">iPhone 13･14</a></li>
		</ul>
		 </dd></dl>
		 </Col>
		 <Col xs={12} sm={6} md={4}><dl className=""><dt>ぴよこ豆</dt>
		 <dd><a href="/category/サンドケース-ぴよこ豆/" className="opa06"><Image src="/img/sandcase/scd_piyoko.png" alt="" fluid className="my-3"/></a>
		 <p className="title-border">カラーで選ぶ</p>
		 <ul className="color-list">
			 <li><a href="/category/サンドケース-ぴよこ豆-ピンク×パープル/" className="opa06"><Image src="/img/sandcase/pk-pu.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ぴよこ豆-ピンク×ライトブルー/" className="opa06"><Image src="/img/sandcase/pk-bl.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ぴよこ豆-ピンク×ブラック/" className="opa06"><Image src="/img/sandcase/pk-bk.png" alt="" fluid/></a></li>
			 {/*<li><a href="/category/サンドケース-ぴよこ豆-オレンジ×イエロー/" className="opa06"><Image src="/img/sandcase/or-ye.png" alt="" fluid/></a></li>*/}
			 <li><a href="/category/サンドケース-ぴよこ豆-イエロー×レッド/" className="opa06"><Image src="/img/sandcase/ye-rd.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ぴよこ豆-グリーン×ライムグリーン/" className="opa06"><Image src="/img/sandcase/gr-lgr.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ぴよこ豆-ホワイト×ブラック/" className="opa06"><Image src="/img/sandcase/wh-bk.png" alt="" fluid/></a></li>
		 </ul>
		 <p className="title-border">機種で選ぶ</p>
		 <ul className="model-list">
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(8･7)" className="">iPhone 7/8/SE2</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(XS･X)" className="">iPhone X/XS</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(11)" className="">iPhone 11</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(11pro)" className="">iPhone 11Pro</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(12･12pro)" className="">iPhone 12/12pro</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(12mini)" className="">iPhone 12mini</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(12ProMax)" className="">iPhone 12ProMax</a></li>
		<li><a href="/category/サンドケース-ぴよこ豆-iPhone(13･14)" className="">iPhone 13･14</a></li>
		</ul>
		 </dd></dl>
		 </Col>
		 <Col xs={12} sm={6} md={4}><dl className=""><dt>うさぎのムーちゃん</dt>
		 <dd><a href="/category/サンドケース-うさぎのムーちゃん/" className="opa06"><Image src="/img/sandcase/scd_mu.png" alt="" fluid className="my-3"/></a>
		 <p className="title-border">カラーで選ぶ</p>
		 <ul className="color-list">
			 <li><a href="/category/サンドケース-うさぎのムーちゃん-ピンク×パープル/" className="opa06"><Image src="/img/sandcase/pk-pu.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-うさぎのムーちゃん-ピンク×ライトブルー/" className="opa06"><Image src="/img/sandcase/pk-bl.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-うさぎのムーちゃん-ピンク×ブラック/" className="opa06"><Image src="/img/sandcase/pk-bk.png" alt="" fluid/></a></li>
			 {/*<li><a href="/category/サンドケース-うさぎのムーちゃん-オレンジ×イエロー/" className="opa06"><Image src="/img/sandcase/or-ye.png" alt="" fluid/></a></li>*/}
			 <li><a href="/category/サンドケース-うさぎのムーちゃん-イエロー×レッド/" className="opa06"><Image src="/img/sandcase/ye-rd.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-うさぎのムーちゃん-グリーン×ライムグリーン/" className="opa06"><Image src="/img/sandcase/gr-lgr.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-うさぎのムーちゃん-ホワイト×ブラック/" className="opa06"><Image src="/img/sandcase/wh-bk.png" alt="" fluid/></a></li>
		 </ul>
		 <p className="title-border">機種で選ぶ</p>
		 <ul className="model-list">
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(8･7)" className="">iPhone 7/8/SE2</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(XS･X)" className="">iPhone X/XS</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(11)" className="">iPhone 11</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(11pro)" className="">iPhone 11Pro</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(12･12pro)" className="">iPhone 12/12pro</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(12mini)" className="">iPhone 12mini</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(12ProMax)" className="">iPhone 12ProMax</a></li>
		<li><a href="/category/サンドケース-うさぎのムーちゃん-iPhone(13･14)" className="">iPhone 13･14</a></li>
		</ul>
		 </dd></dl>
		 </Col>
		 <Col xs={12} sm={6} md={4}><dl className=""><dt>ツンダちゃん</dt>
		 <dd><a href="/category/サンドケース-ツンダちゃん/" className="opa06"><Image src="/img/sandcase/scd_tsunda.png" alt="" fluid className="my-3"/></a>
		 <p className="title-border">カラーで選ぶ</p>
		 <ul className="color-list">
			 <li><a href="/category/サンドケース-ツンダちゃん-ピンク×パープル/" className="opa06"><Image src="/img/sandcase/pk-pu.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ツンダちゃん-ピンク×ライトブルー/" className="opa06"><Image src="/img/sandcase/pk-bl.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ツンダちゃん-ピンク×ブラック/" className="opa06"><Image src="/img/sandcase/pk-bk.png" alt="" fluid/></a></li>
			 {/*<li><a href="/category/サンドケース-ツンダちゃん-オレンジ×イエロー/" className="opa06"><Image src="/img/sandcase/or-ye.png" alt="" fluid/></a></li>*/}
			 <li><a href="/category/サンドケース-ツンダちゃん-イエロー×レッド/" className="opa06"><Image src="/img/sandcase/ye-rd.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ツンダちゃん-グリーン×ライムグリーン/" className="opa06"><Image src="/img/sandcase/gr-lgr.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ツンダちゃん-ホワイト×ブラック/" className="opa06"><Image src="/img/sandcase/wh-bk.png" alt="" fluid/></a></li>
		 </ul>
		 <p className="title-border">機種で選ぶ</p>
		 <ul className="model-list">
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(8･7)" className="">iPhone 7/8/SE2</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(XS･X)" className="">iPhone X/XS</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(11)" className="">iPhone 11</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(11pro)" className="">iPhone 11Pro</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(12･12pro)" className="">iPhone 12/12pro</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(12mini)" className="">iPhone 12mini</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(12ProMax)" className="">iPhone 12ProMax</a></li>
		<li><a href="/category/サンドケース-ツンダちゃん-iPhone(13･14)" className="">iPhone 13･14</a></li>
		</ul>
		 </dd></dl>
		 </Col>
		 <Col xs={12} sm={6} md={4}><dl className=""><dt>ばいばいべあ</dt>
		 <dd><a href="/category/サンドケース-ばいばいべあ/" className="opa06"><Image src="/img/sandcase/scd_baibai.png" alt="" fluid className="my-3"/></a>
		 <p className="title-border">カラーで選ぶ</p>
		 <ul className="color-list">
			 <li><a href="/category/サンドケース-ばいばいべあ-ピンク×パープル/" className="opa06"><Image src="/img/sandcase/pk-pu.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ばいばいべあ-ピンク×ライトブルー/" className="opa06"><Image src="/img/sandcase/pk-bl.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ばいばいべあ-ピンク×ブラック/" className="opa06"><Image src="/img/sandcase/pk-bk.png" alt="" fluid/></a></li>
			 {/*<li><a href="/category/サンドケース-ばいばいべあ-オレンジ×イエロー/" className="opa06"><Image src="/img/sandcase/or-ye.png" alt="" fluid/></a></li>*/}
			 <li><a href="/category/サンドケース-ばいばいべあ-イエロー×レッド/" className="opa06"><Image src="/img/sandcase/ye-rd.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ばいばいべあ-グリーン×ライムグリーン/" className="opa06"><Image src="/img/sandcase/gr-lgr.png" alt="" fluid/></a></li>
			 <li><a href="/category/サンドケース-ばいばいべあ-ホワイト×ブラック/" className="opa06"><Image src="/img/sandcase/wh-bk.png" alt="" fluid/></a></li>
		 </ul>
		 <p className="title-border">機種で選ぶ</p>
		 <ul className="model-list">
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(8･7)" className="">iPhone 7/8/SE2</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(XS･X)" className="">iPhone X/XS</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(11)" className="">iPhone 11</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(11pro)" className="">iPhone 11Pro</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(12･12pro)" className="">iPhone 12/12pro</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(12mini)" className="">iPhone 12mini</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(12ProMax)" className="">iPhone 12ProMax</a></li>
		<li><a href="/category/サンドケース-ばいばいべあ-iPhone(13･14)" className="">iPhone 13･14</a></li>
		</ul>
		 </dd></dl>
		 </Col>

		 </Row>
		 </div>
</Container>
{/* */}

<Container className="mb-4 ">
	 <div className="oshirase">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <ul className="mb-5">
	<li>●本製品に使用している天然オイルは、人体に直接影響のないものを使用しておりますが、本来の用途以外での使用をされた場合は、人体への影響が出る恐れがあります。そのため飲み込む、目に入れる、吸引する等での使用をされた場合は、一切責任を負いかねますのでご了承ください。</li>
	<li>●落下や強い衝撃などにより、ケースが破損した場合は、直ちに液漏れがないかをご確認ください。</li>
	<li>●万が一液漏れが確認された場合は、直ちに使用を止めてください。また、天然オイルが口に触れてしまった場合や、飲み込んでしまった場合は、直ちに水でゆすぎ、医師にご相談ください。</li>
	<li>●天然オイルが皮膚に付着したら直ちに洗い流してください。</li>
	<li>●本製品はスマートフォンの機種別専用ケースです。記載された機種以外でのご使用はできません。</li>
	<li>●本製品はスマートフォン本体へのキズや汚れを完全に防ぐものではありません。</li>
	<li>●ホコリ等は付着したまま装着すると、スマートフォン本体にキズが付く場合があります。装着時には必ずホコリや汚れをふき取り、しっかり装着されているかを確認してからご使用ください。</li>
	<li>●本製品は幼児の手の届く場所に保管しないでください。小さなお子様がなめたり口に入れたりしなように十分注意してください。</li>
	<li>●本製品は高温多湿、直射日光の当たる場所での保管は避けてください。</li>
	<li>●水濡れや摩擦などにより、衣類などに染料が付着してしまう場合があります。十分ご注意の上ご使用ください。</li>
	<li>●本製品を使用しての、スマートフォンの破損、故障、内部データの破損に関しては一切の責任を負いかねますのでご了承ください。</li>
	<li>●環境によってはフラッシュ撮影時にケースの色が写真に写り込む場合がございます。その場合はお手数ですが、本製品を外して撮影してください。</li>
	<li>●ワイヤレス充電機能については、充電器側の性能により使用出来ない場合がございます。その場合は本製品を外してご使用ください。</li>
	</ul></Col></Row>
	 </div>
	
</Container>
<Container className="my-5">
<a href="/others/mobileitem/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/mobileitem/bnr_mobileitem_sp.jpg" />
	 <Image src="/img/mobileitem/bnr_mobileitem.jpg" alt="オンラインストア限定モバイルITEM" fluid className="bdrimg"/>
 </picture></a>
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersSandcase);
