import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersAndroid extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/android/header-ac_sp.jpg" />
	 		<Image src="/img/android/header-ac.jpg" alt="オンラインストア限定スマートフォンクリアケース" fluid />
	 	</picture>
	 </div>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobileitem/period_sp.jpg" />
	 		<Image src="/img/mobileitem/period.jpg" alt="期間" fluid />
	 	</picture>
	 </div>

		 {/*<div className="my-5">
		   <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
		 	</picture>
		 </div>
<p className="red"><strong>※他の通常商品（シールなど）を一緒にご購入の場合は、別送となり、別途送料が必要です。受注確定メールにて訂正した正しい金額をお知らせさせていただきます。<br />
		 ※マルチ手帳Lサイズが生産終了につき、在庫限りとなります。</strong></p>
		 <p className="red"><strong>※現在発生している新型コロナウイルス感染症の影響により、iPhoneケース【背面ガラス(ラウンド型)】 の「11」「11pro」の生産および出荷の遅延が避けられない見通しとなっております。これにより販売を一旦休止とさせていただきます。<br />
	入荷の目途が立ち次第、再販させていただきますので、お客様にはご迷惑をおかけし大変申し訳ございませんが、何卒ご理解を賜りますよう、よろしくお願い申し上げます。</strong></p>*/}

	</Container>
	<Container>
	<picture>
	 <source media="(max-width: 767px)" srcSet="/img/android/printimage_sp.jpg" />
	 		<Image src="/img/android/printimage.jpg" alt="オンラインストア限定スマートフォンクリアケース" fluid />
	 	</picture>
	 <div id="smp_chara-list" className="my-5 pb-3">
		 <ul className="mb-5">
	 <li><a href="#iphone" className="">iPhone</a></li>
	 <li><a href="#galaxy" className="">Galaxy</a></li>
	 <li><a href="#aquos" className="">AQUOS</a></li>
	 <li><a href="#xperia" className="">Xperia</a></li>
	 <li><a href="#othermodel" className="">その他</a></li>	 
	 </ul>
	</div>
	 </Container>

	 <Container>
	 <div id="" className="my-5 pb-3">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/android/ttl_design_sp.png" />
		 <Image className="mt-5" src="/img/android/ttl_design.png" alt="デザイン一覧" fluid />
	 </picture>
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution3_sp.png" />
		<Image className="" src="/img/android/ac-caution3.png" alt="ご注意" fluid />
	</picture>
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/design_sp.jpg" />
		<Image className="" src="/img/android/design.jpg" alt="デザイン一覧" fluid />
	</picture>

	
	<picture>
 <source media="(max-width: 767px)" srcSet="/img/android/design02_sp.jpg" />
		<Image className="" src="/img/android/design02.jpg" alt="デザイン一覧" fluid />
	</picture>
	<picture>
 <source media="(max-width: 767px)" srcSet="/img/android/design2103_sp.jpg" />
		<Image className="" src="/img/android/design2103.jpg" alt="デザイン一覧" fluid />
	</picture>

		{/**/}
	
	<picture>
 <source media="(max-width: 767px)" srcSet="/img/android/design2108_sp.jpg" />
		<Image className="" src="/img/android/design2108.jpg" alt="デザイン一覧" fluid />
	</picture>
	<Image className="mt-5" src="/img/android/design2112.jpg" alt="新デザイン" fluid />
	<Image className="mt-5" src="/img/android/design2203.jpg" alt="新デザイン" fluid />


<p className="text-right mb-5 pt-2">各機種 ￥<span className="font_ll">2,860</span> (税込)</p>
	</div>
	 </Container>

	  <Container>
		<div className="mt-5 model">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/android/ttl_model_sp.png" />
		 <Image className="mt-5 mb-3" src="/img/android/ttl_model.png" alt="機種・型番で探す" fluid />
	 </picture>
		   <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>

	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution2_sp.png" />
		<Image className="mb-5" src="/img/android/ac-caution2.png" alt="ご注意" fluid />
	</picture>
	<Row id="iphone" className="model_listbox">
	 	<Col xs={12} sm={4}><div className="model_name bg_gr"><p>iPhone</p></div></Col>
	 	<Col xs={12} sm={8}>
		<Row>
		<Col xs={12} sm={6}>
		<ul className="model_list">
		<li><a href="/category/アイテムから探す-iPhone-(8･7)" className=""><i className="fa fa-mobile-alt"></i> iPhone 6/7/8/SE2</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(XS･X)" className=""><i className="fa fa-mobile-alt"></i> iPhone X/XS</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(11)" className=""><i className="fa fa-mobile-alt"></i> iPhone 11</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(11pro)" className=""><i className="fa fa-mobile-alt"></i> iPhone 11Pro</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(12･12pro)" className=""><i className="fa fa-mobile-alt"></i> iPhone 12/12pro</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(12mini)" className=""><i className="fa fa-mobile-alt"></i> iPhone 12mini</a></li>
		</ul></Col>
		<Col xs={12} sm={6}>
		<ul className="model_list">
		<li><a href="/category/アイテムから探す-iPhone-(12ProMax)" className=""><i className="fa fa-mobile-alt"></i> iPhone 12ProMax</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(13)" className=""><i className="fa fa-mobile-alt"></i> iPhone 13</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(13mini)" className=""><i className="fa fa-mobile-alt"></i> iPhone 13mini</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(13pro)" className=""><i className="fa fa-mobile-alt"></i> iPhone 13pro</a></li>
		<li><a href="/category/アイテムから探す-iPhone-(13ProMax)" className=""><i className="fa fa-mobile-alt"></i> iPhone 13ProMax</a></li>
		</ul></Col>
	
		</Row>
		</Col>
		 </Row>
	 <Row id="galaxy" className="model_listbox">
	 	<Col xs={12} sm={4}><div className="model_name bg_gr"><p>Galaxy<span class="font_s">※現在受付休止中</span></p></div></Col>
	 	<Col xs={12} sm={8}>
		<Row>
		<Col xs={12} sm={6}>
		<ul className="model_list">
		{/**/}
		{/*<li><a href="/category/アイテムから探す-Android(Galaxy)-S7 edge" className=""><i className="fa fa-mobile-alt"></i> S7 sdge</a></li>*/}
		{/* 2022.3.18非表示 <li><a href="/category/アイテムから探す-Android(Galaxy)-S10" className=""><i className="fa fa-mobile-alt"></i> S10</a></li>
		<li><a href="/category/アイテムから探す-Android(Galaxy)-S10plus" className=""><i className="fa fa-mobile-alt"></i> S10+</a></li>*/}
		</ul></Col>
		<Col xs={12} sm={6}></Col>
		</Row>
		</Col>
		 </Row>
		 <Row id="aquos" className="model_listbox">
		 	<Col xs={12} sm={4}><div className="model_name bg_gr"><p>AQUOS<span class="font_s">※現在受付休止中</span></p></div></Col>
		 	<Col xs={12} sm={8}>
			<Row>
			<Col xs={12} sm={6}>
			<ul className="model_list">
			{/* 2022.3.18非表示 <li><a href="/category/アイテムから探す-Android(AQUOS)-ZETA(SH－04H)･Xx3" className=""><i className="fa fa-mobile-alt"></i> ZETA(SH-04H)/Xx3</a></li>
			{/*<li><a href="/category/アイテムから探す-Android(AQUOS)-EVER＿SH－02J" className=""><i className="fa fa-mobile-alt"></i> EVER SH-02J</a></li>*/}
			{/* 2022.3.18非表示 
			<li><a href="/category/アイテムから探す-Android(AQUOS)-R" className=""><i className="fa fa-mobile-alt"></i> R</a></li>
			<li><a href="/category/アイテムから探す-Android(AQUOS)-R3" className=""><i className="fa fa-mobile-alt"></i> R3</a></li>
			<li><a href="/category/アイテムから探す-Android(AQUOS)-R5G" className=""><i className="fa fa-mobile-alt"></i> R5G</a></li>*/}
			</ul></Col>
			<Col xs={12} sm={6}>
			<ul className="model_list">
			{/* 2022.3.18非表示 
			<li><a href="/category/アイテムから探す-Android(AQUOS)-sense2 SH－01L" className=""><i className="fa fa-mobile-alt"></i> sence2 SH-01L</a></li>
			<li><a href="/category/アイテムから探す-Android(AQUOS)-sense3 lite" className=""><i className="fa fa-mobile-alt"></i> sence3 lite</a></li>
			<li><a href="/category/アイテムから探す-Android(AQUOS)-zero2" className=""><i className="fa fa-mobile-alt"></i> zero2</a></li>
		*/}
			{/*<li><a href="/category/アイテムから探す-Android(AQUOS)-sense3 plus サウンド･sense3 plus" className=""><i className="fa fa-mobile-alt"></i> sense3 plus サウンド/sense3 plus</a></li>*/}		
			</ul></Col>
			</Row>
			</Col>
			 </Row>
			 <Row id="xperia" className="model_listbox">
			 	<Col xs={12} sm={4}><div className="model_name bg_gr"><p>Xperia<span class="font_s">※現在受付休止中</span></p></div></Col>
			 	<Col xs={12} sm={8}>
				<Row>
				<Col xs={12} sm={6}>
				<ul className="model_list">
				{/* 2022.3.18非表示 <li><a href="/category/アイテムから探す-Android(Xperia)-Z3C(SO－02G)" className=""><i className="fa fa-mobile-alt"></i> Z3C(SO-02G)</a></li>
				<li><a href="/category/アイテムから探す-Android(Xperia)-Xcompact" className=""><i className="fa fa-mobile-alt"></i> Xcompact</a></li>
				<li><a href="/category/アイテムから探す-Android(Xperia)-Xperia 1" className=""><i className="fa fa-mobile-alt"></i> Xperia 1</a></li>
				<li><a href="/category/アイテムから探す-Android(Xperia)-Xperia 1 II" className=""><i className="fa fa-mobile-alt"></i> Xperia 1 Ⅱ</a></li>
				*/}
				</ul></Col>
				<Col xs={12} sm={6}>
				<ul className="model_list">
				{/* 2022.3.18非表示 <li><a href="/category/アイテムから探す-Android(Xperia)-Xperia 8" className=""><i className="fa fa-mobile-alt"></i> Xperia 8</a></li>
				<li><a href="/category/アイテムから探す-Android(Xperia)-Xperia 10 II" className=""><i className="fa fa-mobile-alt"></i> Xperia 10 Ⅱ</a></li>
				<li><a href="/category/アイテムから探す-Android(Xperia)-Xperia Ace" className=""><i className="fa fa-mobile-alt"></i> Xperia Ace</a></li>
				<li><a href="/category/アイテムから探す-Android(Xperia)-Xperia 5" className=""><i className="fa fa-mobile-alt"></i> Xperia 5</a></li>
			*/}
				</ul></Col>
				
				</Row>
				</Col>
				 </Row>
				 <Row id="othermodel" className="model_listbox">
				 	<Col xs={12} sm={4}><div className="model_name bg_gr"><p>その他<span class="font_s">※現在受付休止中</span></p></div></Col>
				 	<Col xs={12} sm={8}>
					<Row>
					<Col xs={12} sm={6}>
					<ul className="model_list">
					{/* 2022.3.18非表示 
					<li><a href="/category/アイテムから探す-Android(その他)-isai Beat LGV34" className=""><i className="fa fa-mobile-alt"></i> isai Beat LGV34</a></li>
					<li><a href="/category/アイテムから探す-Android(その他)-MONO MO－01J" className=""><i className="fa fa-mobile-alt"></i> MONO MO-01J</a></li>
					<li><a href="/category/アイテムから探す-Android(その他)-arrows F－01J" className=""><i className="fa fa-mobile-alt"></i> arrows F-01J</a></li>
					<li><a href="/category/アイテムから探す-Android(その他)-arrows Be3" className=""><i className="fa fa-mobile-alt"></i> arrows Be3</a></li>
					<li><a href="/category/アイテムから探す-Android(その他)-arrows 5G F－51A" className=""><i className="fa fa-mobile-alt"></i> arrows 5G F-51A</a></li>
					*/}
					</ul></Col>
					<Col xs={12} sm={6}>
					<ul className="model_list">
					
					{/* 2022.3.18非表示 
					<li><a href="/category/アイテムから探す-Android(その他)-arrows Be4 F－41A" className=""><i className="fa fa-mobile-alt"></i> arrows Be4 F-41A</a></li>
					<li><a href="/category/アイテムから探す-Android(その他)-Android One S6･GRATINA KYV48" className=""><i className="fa fa-mobile-alt"></i> Android One S6/GRATINA KYV48</a></li>
					<li><a href="/category/アイテムから探す-Android(その他)-BASIO 4･かんたんスマホ2 A001KC" className=""><i className="fa fa-mobile-alt"></i> BASIO 4/かんたんスマホ2 A001KC</a></li>
				*/}
					{/*<li><a href="/category/アイテムから探す-Android(その他)-らくらく4 F－04J" className=""><i className="fa fa-mobile-alt"></i> らくらく4 F-04J</a></li>
					
					
					<li><a href="/category/アイテムから探す-Android(その他)-DIGNO EF 503KC" className=""><i className="fa fa-mobile-alt"></i> DIGNO E/F 503KC</a></li>*/}
					{/* 2022.3.18非表示 
					<li><a href="/category/アイテムから探す-Android(その他)-Google Pixel 4a" className=""><i className="fa fa-mobile-alt"></i> Google Pixel 4a</a></li>
					*/}
					{/*
					<li><a href="/category/アイテムから探す-Android(その他)-Android One 507SH" className=""><i className="fa fa-mobile-alt"></i> Android One 507SH</a></li>
					
					<li><a href="/category/アイテムから探す-Android(その他)-HUAWEI P20 lite" className=""><i className="fa fa-mobile-alt"></i> HUAWEI P20 lite</a></li>
					*/}
					</ul></Col>
					</Row>
					</Col>
					 </Row>
		 </div>
</Container>
{/* */}

<Container className="mb-4 ">
	 <div className="oshirase">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <ul className="mb-5">
	<li>※スマートフォン用ケースです。本来の用途以外には使用しないでください。</li>
	<li>※スマホケースは、スマートフォン本体へのキズや汚れを完全に防ぐものではありません。</li>
	<li>※本製品のご使用中に伴う、スマートフォンの紛失、故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
	<li>※スマホケースを装着したまま充電スタンドの使用や、SIMカード、SDカードの出し入れはできません。</li>
	</ul></Col></Row>
	 </div>
	
</Container>
<Container className="my-5">
<a href="/others/sandcase/" className="opa06 mb-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/sandcase/bnr_sandcase_sp.jpg" />
	 <Image src="/img/sandcase/bnr_sandcase.jpg" alt="オンラインストア限定サンドケース" fluid className="mb-5"/>
 </picture></a>
<a href="/others/mobilebattery/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/mobilebattery/bnr_mb_sp.jpg" />
	 <Image src="/img/mobilebattery/bnr_mb.jpg" alt="オンラインストア限定モバイルバッテリー" fluid className="bdrimg"/>
 </picture></a>
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersAndroid);
