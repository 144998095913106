import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Card,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isSuccess       : false,
			isRedirect      : false,
			ErrMsg          : '',
			addressid       : '',
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleDelete         = this.handleDelete.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData () {

		try{
			//IDの取得
			const addressid = this.props.match.params.addressid;
			if(!addressid){
					this.setState({
						isLoading   : false,
						ErrMsg  : 'システムIDが読み取れません。<br/>',
					});
					return(0);
			}

			//アドレスデータを取得
			const ref = await API.Address.Get(addressid);
			console.log(ref);

			if(ref.item === null){
					this.setState({
						isLoading   : false,
						ErrMsg  : 'アドレス帳のデータが見つかりません。<br/>',
					});
					return(0);
			}

			const o_name_1      = ref.item.o_name_1     ;
			const o_name_2      = ref.item.o_name_2     ;
			const o_name_furi_1 = ref.item.o_name_furi_1;
			const o_name_furi_2 = ref.item.o_name_furi_2;
			const o_zip_1       = ref.item.o_zip_1      ;
			const o_zip_2       = ref.item.o_zip_2      ;
			const o_pref        = ref.item.o_pref       ;
			const o_addr_1      = ref.item.o_addr_1     ;
			const o_addr_2      = ref.item.o_addr_2     ;
			const o_addr_3      = ref.item.o_addr_3     ;
			const o_tel_1       = ref.item.o_tel_1      ;
			const o_tel_2       = ref.item.o_tel_2      ;
			const o_tel_3       = ref.item.o_tel_3      ;
			const o_com_name    = ref.item.o_com_name   ;
			const use_flag      = ref.item.use_flag     ;

			//データ更新
			this.setState({
				isLoading     : false,
				addressid     : addressid,
				o_name_1      : o_name_1     ,
				o_name_2      : o_name_2     ,
				o_name_furi_1 : o_name_furi_1,
				o_name_furi_2 : o_name_furi_2,
				o_zip_1       : o_zip_1      ,
				o_zip_2       : o_zip_2      ,
				o_pref        : o_pref       ,
				o_addr_1      : o_addr_1     ,
				o_addr_2      : o_addr_2     ,
				o_addr_3      : o_addr_3 === 'null' ? '' : o_addr_3,
				o_tel_1       : o_tel_1      ,
				o_tel_2       : o_tel_2      ,
				o_tel_3       : o_tel_3      ,
				o_com_name    : o_com_name === 'null' ? '' : o_com_name,
				use_flag      : use_flag     ,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
				});
				return(0);
			}

			//stateから直接取得する更新項目
			const addressid     = this.state.addressid       ;

			//Formから直接取得する更新項目
			const o_name_1      = formObj.o_name_1.value     ;
			const o_name_2      = formObj.o_name_2.value     ;
			const o_name_furi_1 = formObj.o_name_furi_1.value;
			const o_name_furi_2 = formObj.o_name_furi_2.value;
			const o_zip_1       = formObj.o_zip_1.value      ;
			const o_zip_2       = formObj.o_zip_2.value      ;
			const o_pref        = formObj.o_pref.value       ;
			const o_addr_1      = formObj.o_addr_1.value     ;
			const o_addr_2      = formObj.o_addr_2.value     ;
			const o_addr_3      = formObj.o_addr_3.value     ;
			const o_tel_1       = formObj.o_tel_1.value      ;
			const o_tel_2       = formObj.o_tel_2.value      ;
			const o_tel_3       = formObj.o_tel_3.value      ;
			const o_com_name    = formObj.o_com_name.value   ;
			//console.log(formObj.use_flag.checked);
			const use_flag      = formObj.use_flag.checked ? true : false;

			//通常使うフラグがある場合、その他のフラグを消す
			if(use_flag){
				//アドレスデータを取得
				const refAddress = await API.Address.GetAll();
				if(refAddress.items !== null){
					const newList = refAddress.items.filter(x => x.use_flag === true);
					for(const item of newList){
						const addressid     = item.addressid     ;
						const o_name_1      = item.o_name_1      ;
						const o_name_2      = item.o_name_2      ;
						const o_name_furi_1 = item.o_name_furi_1 ;
						const o_name_furi_2 = item.o_name_furi_2 ;
						const o_zip_1       = item.o_zip_1       ;
						const o_zip_2       = item.o_zip_2       ;
						const o_pref        = item.o_pref        ;
						const o_addr_1      = item.o_addr_1      ;
						const o_addr_2      = item.o_addr_2      ;
						const o_addr_3      = item.o_addr_3      ;
						const o_tel_1       = item.o_tel_1       ;
						const o_tel_2       = item.o_tel_2       ;
						const o_tel_3       = item.o_tel_3       ;
						const o_com_name    = item.o_com_name    ;
						await API.Address.Update(addressid,o_name_1,o_name_2,o_name_furi_1,o_name_furi_2,o_zip_1,o_zip_2,o_pref,o_addr_1,o_addr_2,o_addr_3,o_tel_1,o_tel_2,o_tel_3,o_com_name,false);
					}
				}
			}

			//変更
			const ref = await API.Address.Update(
							addressid    ,
							o_name_1     ,
							o_name_2     ,
							o_name_furi_1,
							o_name_furi_2,
							o_zip_1      ,
							o_zip_2      ,
							o_pref       ,
							o_addr_1     ,
							o_addr_2     ,
							o_addr_3 ? o_addr_3 : 'null'     ,
							o_tel_1      ,
							o_tel_2      ,
							o_tel_3      ,
							o_com_name ? o_com_name : 'null' ,
							use_flag     ,
						);

			if(ref.error !== null){
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
			else{
				//成功
				this.setState({
					isSuccess     : true,
					isLockForm    : false,
				 });
			}

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	async handleDelete (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
				});
				return(0);
			}

			//stateから直接取得する更新項目
			const addressid     = this.state.addressid       ;
			const use_flag      = this.state.use_flag        ;

			//通常使うフラグがある場合、その他にフラグを付ける
			if(use_flag){
				//アドレスデータを取得
				const refAddress = await API.Address.GetAll();
				if(refAddress.items !== null){
					const newList = refAddress.items.filter(x => x.use_flag === false);
					for(const item of newList){
						const addressid     = item.addressid     ;
						const o_name_1      = item.o_name_1      ;
						const o_name_2      = item.o_name_2      ;
						const o_name_furi_1 = item.o_name_furi_1 ;
						const o_name_furi_2 = item.o_name_furi_2 ;
						const o_zip_1       = item.o_zip_1       ;
						const o_zip_2       = item.o_zip_2       ;
						const o_pref        = item.o_pref        ;
						const o_addr_1      = item.o_addr_1      ;
						const o_addr_2      = item.o_addr_2      ;
						const o_addr_3      = item.o_addr_3      ;
						const o_tel_1       = item.o_tel_1       ;
						const o_tel_2       = item.o_tel_2       ;
						const o_tel_3       = item.o_tel_3       ;
						const o_com_name    = item.o_com_name    ;
						await API.Address.Update(addressid,o_name_1,o_name_2,o_name_furi_1,o_name_furi_2,o_zip_1,o_zip_2,o_pref,o_addr_1,o_addr_2,o_addr_3,o_tel_1,o_tel_2,o_tel_3,o_com_name,true);
						break;
					}
				}
			}

			//削除
			const ref = await API.Address.Remove(addressid);

			if(ref.error !== null){
				console.log(ref.error);
				//エラー処理
				this.setState({
					ErrMsg     : ref.error + '<br/>',
					isLockForm : false,
				 });
			}
			else{
				//成功
				this.setState({
					isSuccess     : true,
					isLockForm    : false,
				 });
			}

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	handleClose(){
		this.setState({
			isSuccess : false,
			isRedirect : true,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isRedirect){
			return (
				<Redirect to="/mypage/address/"/>
			);
		}
		else if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isSuccess} onHide={this.handleClose} centered>
						<Modal.Body>
							更新しました。<br/>
							{this.state.isEmailChange ? 'メールアドレスを変更された場合は、新しいメールアドレスに検証コードが到着しています次のページで検証コードを入力して、Eメールアドレスの変更を有効にしてください。' : ''}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
	</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>


							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
							<Container>
								<h2>お届け先アドレス帳</h2>
								<Card>
									<Card.Body>
										<Row>
											<Col className="col-md-6 mb-3">
												<Form.Group controlId="o_name_1">
													<Form.Label>
														姓
													</Form.Label>
													<Form.Control type="text" name="o_name_1" size="lg" required pattern=".{1,255}" defaultValue={this.state.o_name_1} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														名字を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col className="col-md-6 mb-3">
												<Form.Group controlId="o_name_2">
													<Form.Label>
														名
													</Form.Label>
													<Form.Control type="text" name="o_name_2" size="lg" required pattern=".{1,255}" defaultValue={this.state.o_name_2} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														名前を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<hr className="mb-4"/>
										<Row>
											<Col className="col-md-6 mb-3">
												<Form.Group controlId="o_name_furi_1">
													<Form.Label>
														セイ
													</Form.Label>
													<Form.Control type="text" name="o_name_furi_1" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" defaultValue={this.state.o_name_furi_1} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														名字（カタカナ）を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col className="col-md-6 mb-3">
												<Form.Group controlId="o_name_furi_2">
													<Form.Label>
														メイ
													</Form.Label>
													<Form.Control type="text" name="o_name_furi_2" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" defaultValue={this.state.o_name_furi_2} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														名前（カタカナ）を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
<p className="small red">※ハイフンで区切って、郵便番号１（３桁）-郵便番号２（4桁）で入力してください</p>
										<Row>
											<Col sm={3} xs={6}>
												<Form.Group controlId="o_zip_1">
													<Form.Label>
														郵便番号1(3桁)
													</Form.Label>
													<Form.Control type="text" name="o_zip_1" size="lg" maxLength='3' required pattern="\d{3}" defaultValue={this.state.o_zip_1} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														郵便番号を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col sm={3} xs={6}>
												<Form.Group controlId="o_zip_2">
													<Form.Label>
														郵便番号2(4桁)
													</Form.Label>
													<Form.Control type="text" name="o_zip_2" maxLength='4' size="lg" required pattern="\d{4}" defaultValue={this.state.o_zip_2} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														郵便番号を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col md={4}>
												<Form.Group controlId="o_pref">
													<Form.Label>
														都道府県
													</Form.Label>
													<Form.Control type="text" name="o_pref" size="lg" placeholder="" required pattern=".{2,4}" defaultValue={this.state.o_pref} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														都道府県を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md={8}>
												<Form.Group controlId="o_addr_1">
													<Form.Label>
														市町村
													</Form.Label>
													<Form.Control type="text" name="o_addr_1" size="lg" placeholder="" required pattern=".{1,255}" defaultValue={this.state.o_addr_1} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														市町村を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col sm={6}>
												<Form.Group controlId="o_addr_2">
													<Form.Label>
														番地
													</Form.Label>
													<Form.Control type="text" name="o_addr_2" size="lg" placeholder="" required pattern=".{1,255}" defaultValue={this.state.o_addr_2} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														住所を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>

											<Col sm={6}>
												<Form.Group controlId="o_addr_3">
													<Form.Label>
														アパート・マンション名
													</Form.Label>
													<Form.Control type="text" name="o_addr_3" size="lg" placeholder="" pattern=".{0,255}" defaultValue={this.state.o_addr_3} disabled={this.state.isLockForm} />
												</Form.Group>
											</Col>
										</Row>
										<hr className="mb-4"/>
	<p className="small red">※ハイフンで区切って、電話番号１ - 電話番号２ - 電話番号3 で入力してください</p>
										<Row>
											<Col>
												<Form.Group controlId="o_tel_1">
													<Form.Label>
														電話番号1
													</Form.Label>
													<Form.Control type="text" name="o_tel_1" maxLength='4' size="lg" placeholder="" required pattern="\d{2,4}" defaultValue={this.state.o_tel_1} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														電話番号を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group controlId="o_tel_2">
													<Form.Label>
														電話番号2
													</Form.Label>
													<Form.Control type="text" name="o_tel_2" maxLength='4' size="lg" placeholder="" required pattern="\d{2,4}" defaultValue={this.state.o_tel_2} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														電話番号を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group controlId="o_tel_3">
													<Form.Label>
														電話番号3
													</Form.Label>
													<Form.Control type="text" name="o_tel_3" size="lg" placeholder="" required pattern="\d{2,4}" defaultValue={this.state.o_tel_3} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														電話番号を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col mb={3}>
												<Form.Group controlId="o_com_name">
													<Form.Label>
														会社名
													</Form.Label>
													<Form.Control type="text" name="o_com_name" size="lg" placeholder="" pattern=".{0,255}" defaultValue={this.state.o_com_name} disabled={this.state.isLockForm} />
													<Form.Control.Feedback type="invalid">
														会社名を入力してください
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col mb={3}>
												<Form.Group controlId="use_flag">
													<Form.Label>
														このアドレスを通常使うものに指定する
													</Form.Label>
													<Form.Check type="checkbox" name="use_flag" size="lg" placeholder="" pattern=".{0,255}" defaultValue={true} disabled={this.state.isLockForm} defaultChecked={this.state.use_flag} />
													<Form.Control.Feedback type="invalid">
														エラーがありました。
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col mb={6}>
																						<Button size="lg" type="submit" className="btn-blue" block>
																							変更
																						</Button>
											</Col>
											<Col mb={6}>
																						<Button type="button" size="lg" className="btn-bk" block onClick={()=>{ this.props.history.push('/mypage/address/'); }} >
																							戻る
																						</Button>
																							</Col>
																							</Row>
																					</Card.Body>

								</Card>
							</Container>
							</Form>
							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleDelete(e)}>
								<Button size="lg" type="submit" className="btn-bk" block >
									削除する
								</Button>
							</Form>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
