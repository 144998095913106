import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
import './kotorimachi.css';
import GetItemInfo from '../GetItemInfo';


class OthersKotorimachi extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
			<main id="kotorimachi-lp" className={this.state.cognitoid ? 'others kotorimachi-lp members' : 'others kotorimachi-lp'}>

<Container className="top-img">
<h2><Image src="/img/kotorimachi/kotori_top.jpg" alt="ことりまち商店街へようこそ" className="mt-5" fluid /></h2>
</Container>
<Container className="pu-item mb-5">
	 <h3>
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/kotorimachi/ttl_kotori01_sp.png" />
		<Image className="" src="/img/kotorimachi/ttl_kotori01.png" alt="注目のアイテムをご紹介！" fluid />
	</picture>
	 </h3>
<div className="my-5 text-center">
	<a href="/category/キャラクターから探す-ことりまち商店街-付箋/" className="opa06"><Image src="/img/kotorimachi/kotori_pu01.jpg" alt="注目のアイテム" className="mb-5 m1000" fluid /></a>
	{/*<a href="/item/H210521/" className="opa06"><Image src="/img/kotorimachi/kotori_pu02.jpg" alt="注目のアイテム" className="mb-5 m1000" fluid /></a>*/}
</div>
<div className="pucate-item my-5">
<Row>
<Col xs={6} sm={3} ><a href="/item/81073/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/81073_main.jpg" alt="" fluid /></a>
 <p><a href="/item/81073/"><GetItemInfo code="81073" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/95071/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95071_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95071/"><GetItemInfo code="95071" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57830/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57830_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57830/"><GetItemInfo code="57830" setkey="name" /></a></p></Col>
  <Col xs={6} sm={3} ><a href="/item/57645/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57645_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57645/"><GetItemInfo code="57645" setkey="name" /></a></p></Col>
</Row>
</div>
</Container>
 <Container className="my-5">
	 <h3>
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/kotorimachi/ttl_kotori02_sp.png" />
		<Image className="" src="/img/kotorimachi/ttl_kotori02.png" alt="お店から探す" fluid />
	</picture>
	 </h3>

	<Row>
	<Col xs={12} sm={6} ><a href="/search/八百屋%20キッチンカー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/car_vege.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/search/牛乳屋%20キッチンカー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/car_milk.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/search/ベーカリー%20キッチンカー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/car_bake.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/search/バーガーショップ%20キッチンカー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/car_burg.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-ブックストア/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_book.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-フラワーショップ/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_flower.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-パティスリー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_patisserie.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-コーヒーショップ/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_coffee.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-レストラン/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_rest.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-セレクトショップ/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_select.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-シネマ/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_cinema.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-スタンドバー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_bar.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-銭湯/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_sento.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-喫茶店/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_kissa.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-テーラー/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_tailor.png" alt="" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/キャラクターから探す-ことりまち商店街-和菓子店/" className="opa06"><Image className="mb-5" src="/img/kotorimachi/shop_wagashi.png" alt="" fluid /></a></Col>
		
 </Row>

 </Container>
 <Container className="my-5">
	 <h3 className="my-5">
	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/kotorimachi/ttl_kotori03_sp.png" />
		<Image src="/img/kotorimachi/ttl_kotori03.png" alt="アイテムから探す" fluid />
	</picture>
	 </h3>

	<Row>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-シール/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">シール</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-マスキングテープ/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">マスキングテープ</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-付箋/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">付箋</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-レター/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">レター</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-ノート/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">ノート</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-メモ/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">メモ</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-ファイル/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">ファイル</a></Col>
		<Col xs={12} sm={6} md={3} ><a href="/category/キャラクターから探す-ことりまち商店街-雑貨/" className="btn-pbl w-100 btn btn-smp mb-3 opa06">雑貨</a></Col>
 </Row>
 </Container>
<Container className="mb-3"><Row>
<Col xs={12} sm={{ span: 6, offset: 3 }} ><a href="/category/キャラクターから探す-ことりまち商店街/" className="btn-br w-100 btn btn-smp btn-lg py-3 my-5 opa06">全商品一覧はこちら <i className="fa fa-arrow-right"></i></a></Col>
</Row>
<div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div></Container>
</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersKotorimachi);
