import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersHumidifier extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/humidifier/header-humidifier_sp.jpg" />
	 		<Image src="/img/humidifier/header-humidifier.jpg" alt="オンラインストア限定" fluid />
	 	</picture>
	
	 </div>
	 <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>
	 {/* <div className="mb-5 pb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobileitem/period_sp.jpg" />
	 		<Image src="/img/mobileitem/period.jpg" alt="期間" fluid />
	 	</picture>
	
	</div> */}
	 </Container>
	 

	 <Container>
	 <div id="" className="my-5 pt-5">
	 <div className="my-5 pb-5">
	<picture>
	 <source media="(max-width: 767px)" srcSet="/img/humidifier/humidifier_spec_sp.jpg" />
	 		<Image src="/img/humidifier/humidifier_spec.jpg" alt="SPEC" fluid />
	 	</picture>
		 </div>	
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/mobilebattery/ttl_item_sp.png" />
		 <Image className="mt-5" src="/img/mobilebattery/ttl_item.png" alt="一覧" fluid />
	 </picture>
	 <p className="text-right pt-5">各種 ￥<span className="font_ll">3,300</span> (税込)</p>
	</div>
	<div className="mb_list mb-5 pb-5 text-center">
	<Row>
		<Col xs={6} sm={4} ><a href="/item/hu_301/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/hu_301_main.jpg" alt="" fluid /></a>
		<p><a href="/item/hu_301/">ごろごろにゃんすけ</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/hu_302/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/hu_302_main.jpg" alt="" fluid /></a>
		<p><a href="/item/hu_302/">しばんばん</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/hu_303/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/hu_303_main.jpg" alt="" fluid /></a>
		<p><a href="/item/hu_303/">ぴよこ豆</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/hu_304/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/hu_304_main.jpg" alt="" fluid /></a>
		<p><a href="/item/hu_304/">うさぎのムーちゃん</a></p></Col>
		<Col xs={6} sm={4} ><a href="/item/hu_305/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/hu_305_main.jpg" alt="" fluid /></a>
		<p><a href="/item/hu_305/">だっとちゃん</a></p></Col>
 </Row>
 </div>
	 </Container>




<Container className="mb-4 ">

	 <div className="oshirase">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <h4>警告事項</h4>
	 <p className="mb-5">●本製品を分解したり改造したりしないでください。<br />
●過度の衝撃や振動を与えないでください。<br />
●感電の危険があるため本体が水などにぬれた状態やぬれた手で使用しないでください。<br />
●小さなお子様の手が届かないところで使用、保管をしてください。<br />
●本体や付属品を夏場の車内、暖房器具、調理器具など高熱になる環境、また直射日光の当たる環境で使用、保管しないでください。<br />
●本体の清掃にシンナーなどの有機溶剤を使用しないでください。<br />
●万が一使用中に煙が出たり異臭を感じた時はすぐに使用を中止してください。<br />
●お子様のオモチャ（玩具）として使わせないでください。また、遊ばないように監督してください。本体を転倒させると水が溢れ、故障の原因になります。
</p>
<h4>注意事項</h4>
<p className="mb-5">
●本製品を使用、接続する各機器に示された安全情報や定格表示を合わせて確認のうえ使用してください。<br />
●本製品の接続端子に液体・金属・燃えやすいものなどの異物を入れないでください。<br />
●本来の目的以外に使用しないでください。<br />
●その他、異常を感じたらただちに使用を中止してください。
</p>
	 {/*<ul className="mb-5">
	<li>※本来の用途以外には使用しないでください。</li>
	<li>※ご使用の際には取扱説明書をよくお読みの上、正しくお使いください。</li>
	<li>※本製品のご使用中に伴う、接続機器の故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
		</ul>*/}
	</Col></Row>
	 </div>
	 
	
</Container>
<Container className="my-5">
	{/* 
<a href="/others/glasscase/" className="opa06 mb-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/glasscase/bnr_glasscase_l_sp.jpg" />
	 <Image src="/img/glasscase/bnr_glasscase_l.jpg" alt="オンラインストア限定ケース" fluid className="mb-5"/>
 </picture></a>*/}
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersHumidifier);
