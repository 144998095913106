import React from 'react';
import {
	Container,
	Image,
}from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";

class StationarySlick extends React.Component {
  render() {
    var settings = {
autoplay:true,
autoplaySpeed:5000,
centerMode: true,
swipeToSlide:true,
		dots:true,
		slidesToShow:3,
		slidesToScroll: 1,
		centerPadding: "60px",
		  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
		autoplaySpeed:5000,
		centerPadding: "0px",
      }
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
		autoplaySpeed:5000,
		centerPadding: "0px",
        speed: 500,
        swipe: true,
      }
    }
  ]
    };
    return (
	<Container fluid={true} className="clearfix py-5">
		<div className="stationery-head">
	      <Slider {...settings}>
		  <div className="slidsize"><a href="/search/月替わりステッカー/" className="opa06"><Image src="/img/slider/bnr_ms2105.jpg" /></a></div>
			<div className="slidsize"><a href="/item/202412/" className="opa06"><Image src="/img/slider/bnr_mwdeco.jpg" /></a></div>
			<div className="slidsize"><a href="/category/アイテムから探す-シール-TOP30/" className="opa06"><Image src="/img/slider/bnr_sealtop30.jpg" /></a></div>
		  
		  <div className="slidsize"><a href="/search/ウィンターセレクション/" className="opa06"><Image src="/img/slider/bnr_winter.jpg" /></a></div>
		  <div className="slidsize"><a href="/others/bine/" className="opa06"><Image src="/img/slider/bnr_bine.jpg" /></a></div>
		  
		  
		  
		  <div className="slidsize"><a href="/search/アクリルキーホルダー/" className="opa06"><Image src="/img/slider/bnr_ak2402.jpg" /></a></div>
		  
		  <div className="slidsize"><a href="/category/キャラクターから探す-BABYシリーズ/" className="opa06"><Image src="/img/slider/bnr_babysrs.jpg" /></a></div>
		  <div className="slidsize"><a href="/search/刺繍ワッペン/" className="opa06"><Image src="/img/slider/bnr_wappen.jpg" /></a></div>
		  <div className="slidsize"><a href="/others/kotorimachi/" className="opa06"><Image src="/img/slider/bnr_kotori2105.jpg" /></a></div>
		  {/**/}
		  <div className="slidsize"><a href="/category/アイテムから探す-モバイル用品-スマホグリップ/" className="opa06"><Image src="/img/slider/bnr_sp-grip.jpg" /></a></div>
		  
		<div className="slidsize"><a href="/search/クリアテープ/" className="opa06"><Image src="/img/slider/bnr_cleartape.jpg" /></a></div>
		  {/*<div className="slidsize"><a href="/category/アイテムから探す-モバイル用品-スマホリング/" className="opa06"><Image src="/img/slider/bnr_ring.jpg" /></a></div>
		  */}
		  <div className="slidsize"><a href="/search/マルチ充電ケーブル/" className="opa06"><Image src="/img/slider/bnr_cable.jpg" /></a></div>
		  <div className="slidsize"><a href="/search/ごろごろにゃんすけ フィギュア/" className="opa06"><Image src="/img/slider/bnr_nyansuke-fg.jpg" /></a></div>		  
		  <div className="slidsize"><a href="/search/アクリルスタンド/" className="opa06"><Image src="/img/slider/bnr_as.jpg" /></a></div>		   
		  
		  
		  {/*
		  <div className="slidsize"><a href="/search/ドリップコーヒーギフトセット/" className="opa06"><Image src="/img/slider/bnr_coffee2023.jpg" /></a>	</div>
		  <div className="slidsize"><a href="/others/speaker/" className="opa06"><Image src="/img/slider/bnr_speaker.jpg" /></a></div>
		  <div className="slidsize"><a href="/others/humidifier/" className="opa06"><Image src="/img/slider/slider_humidifier.jpg" /></a></div>
		  <div className="slidsize"><a href="/search/メガネケース/" className="opa06"><Image src="/img/slider/bnr_gcase.jpg" /></a></div>
		  <div className="slidsize"><a href="/search/ライフフル/" className="opa06"><Image src="/img/slider/bnr_lifeful.jpg" /></a></div>
		  <div className="slidsize"><a href="/category/アイテムから探す-文房具屋さん大賞2023/" className="opa06"><Image src="/img/slider/bnr_bungu2023.jpg" /></a></div>
		  
		  <div className="slidsize"><a href="/search/オンラインストア限定　BABYぬいぐるみ/" className="opa06"><Image src="/img/slider/bnr_nui2107.jpg" /></a></div>
		  <div className="slidsize"><a href="/others/mobilebattery/" className="opa06"><Image src="/img/mobilebattery/bnr_wc_s.jpg" /></a></div>
		  <div className="slidsize"><a href="/others/mobilebattery/#mobilebattery" className="opa06"><Image src="/img/mobilebattery/bnr_mb_s.jpg" /></a></div>
				<div className="slidsize"><a href="/search/クルーソックス/" className="opa06"><Image src="/img/202005/bnr_socks.jpg" /></a></div>
				<div className="slidsize"><a href="/search/ちぎロール/" className="opa06"><Image src="/img/202004/bnr_chigi02.jpg" /></a></div>
		  <div className="slidsize"><a href="/search/シアー%20フォト/" className="opa06"><Image src="/img/202102/bnr_sp-series.jpg" className="bdrimg" /></a></div>
				
				<div className="slidsize"><a href="/category/アイテムから探す-テープ-垂れているシリーズ/" className="opa06"><Image src="/img/202006/bnr_mt-diecut_02.jpg" /></a></div>
				<div className="slidsize"><a href="/search/ぺたロール/" className="opa06"><Image src="/img/202004/bnr_peta.jpg" /></a></div><div className="slidsize"><a href="/search/しばんばん レトロペーパー/" className="opa06"><Image src="/img/slider/bnr_retro_banban.jpg" /></a></div>
		<div className="slidsize"><a href="/search/ぴよこ豆 アクリルスタンド/" className="opa06"><Image src="/img/slider/bnr_piyoko-as.jpg" /></a></div>
		<div className="slidsize"><a href="/search/オンラインストア限定 トートバッグ/" className="opa06"><Image src="/img/slider/bnr_totebag.jpg" /></a></div>
		<div className="slidsize"><a href="/others/sandcase/" className="opa06"><Image src="/img/slider/bnr_sandcase_sld.jpg" /></a></div>
<div className="slidsize"><a href="/search/スーパー銭湯ぴよこ豆/" className="opa06"><Image src="/img/slider/bnr_piyoko-sento.jpg" /></a></div>
<div className="slidsize"><a href="/others/mobileitem/" className="opa06"><Image src="/img/slider/slider_mobileitem.jpg" /></a></div>*/}
		
		<div className="slidsize"><a href="/search/マルチ収納ボックス/" className="opa06"><Image src="/img/slider/bnr_storagebox.jpg" /></a></div>
		<div className="slidsize"><a href="/search/リーブル%20コラージュ/" className="opa06"><Image src="/img/slider/bnr_libre.jpg" /></a></div>
		<div className="slidsize"><a href="/search/スタンドスティックマーカー%E3%80%80おしりシリーズ/" className="opa06"><Image src="/img/slider/bnr_oshiri.jpg" /></a></div>
		
		
		<div className="slidsize"><a href="/item/95337/" className="opa06"><Image src="/img/slider/bnr_bathball.jpg" /></a></div>
		<div className="slidsize"><a href="/search/ブックカバー/" className="opa06"><Image src="/img/slider/bnr_bookcover.jpg" /></a></div>
		<div className="slidsize"><a href="/category/アイテムから探す-ランチ/" className="opa06"><Image src="/img/slider/bnr_lunch.jpg" /></a></div>
      </Slider>
	</div>
		</Container>
    );
  }
}

export default withRouter(StationarySlick);
