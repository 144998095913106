import { API, graphqlOperation } from 'aws-amplify';

const RecentlyViewed = {};

RecentlyViewed.Get = async function ()  {

	try{

		const getQuery = `query getRecentlyViewed {
			getRecentlyViewed {
				items{
					code
					name
					date_add
					date_edit
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(getQuery));
		const items = ref.data.getRecentlyViewed.items;
		
		items.sort(function(a,b){
			if(a.date_edit > b.date_edit) return -1;
			if(a.date_edit < b.date_edit) return 1;
			return 0;
		});
		
		let newData = [];
		let i = 0;
		for(const item of items){
			i++;
			if(i > 10){
				break;
			}
			newData.push(item);
		}
		
		return ({
			items: newData,
			error: null,
		});
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

RecentlyViewed.Add = async function (code, name)  {

	try{

		//有効期限
		const dateObj = new Date();
		const expDate = Math.round(dateObj.getTime() / 1000) + (86400 * 7);

		//チェック
		const checkQuery = `query getRecentlyViewedItem($code: String!) {
			getRecentlyViewedItem(code: $code) {
				code
			}
		}`;
		const checkData = {
			code  : code,
		};
		const refCheck  = await API.graphql(graphqlOperation(checkQuery, checkData));
		const check     = refCheck.data.getRecentlyViewedItem;

		if(check === null){
			
			//新規
			const addQuery = `mutation addRecentlyViewed($data: RecentlyViewedInput!) {
				addRecentlyViewed(input: $data) {
					code
				}
			}`;
			const addData = {
				data : {
					code       : code,
					name       : name,
					ttl_exp    : expDate,
				}
			};
			await API.graphql(graphqlOperation(addQuery, addData));
		}
		else{
			//更新
			const updateQuery = `mutation updateRecentlyViewed($data: RecentlyViewedInput!) {
				updateRecentlyViewed(input: $data) {
					code
				}
			}`;
			const updateData = {
				data : {
					code       : code,
					name       : name,
					ttl_exp    : expDate,
				}
			};
			await API.graphql(graphqlOperation(updateQuery, updateData));
		}
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}


export { RecentlyViewed };


