import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class CompanyMain extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/stationary_index/">TOP</a></div>
						<div><a href={'/company/'}>会社概要</a></div>

					</div>
				<h1>会社概要</h1>
</Container>


<Container className="longtxt">
	 <Row>
            		 <Col lg={{ span: 10, offset: 1 }} md={12}>
		<div className="company">
				<dl>
					<dt>商号</dt>
					<dd>株式会社クヌム</dd>
					<dt>所在地</dt>
					<dd>〒553-0004　大阪府大阪市福島区玉川4丁目15-11<br />
					タマガワファーストビル　5階</dd>
					<dt>電話番号</dt>
					<dd>06-7220-4222</dd>
					<dt>URL</dt>
					<dd><a href="http://khnum.net">http://khnum.net</a></dd>
					<dt>E-Mail</dt>
					<dd>マインドウェイブ オンラインストア専用： <a href="mailto:store@mindwave-store.com">store@mindwave-store.com</a></dd>
					<dt>代表取締役</dt>
					<dd>米村　栄一</dd>
					<dt>業務内容</dt>
					<dd>デザイン業務／印刷業務／企業コンサルティング業務／企画開発業務／システム開発業務／ホスティング業務</dd>
					<dt>営業時間</dt>
					<dd>月～金10:00～17:00<br />
					※インターネットでのご注文は24時間365日お受けいたしております。</dd>
					<dt>定休日</dt>
					<dd>土・日・祝日 他、年末年始の休日はサイト上でお知らせいたします。</dd>
				</dl>
	</div>
		</Col>
			</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(CompanyMain);
