import React from 'react';
import {
	Container,
	Image,
}from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class StationaryTab extends React.Component {
  constructor(props) {
    super();
    this.state = {
      // Takes active tab from props if it is defined there
      activeTab: props.activeTab || 1
    };

    // Bind the handleSelect function already here (not in the render function)
    this.handleSelect = this.handleSelect.bind(this);
  }

  render() {
    return (

			<Container className="pt-5 top_recomend">
					<h2 className='ttl_en'>RECOMMEND ITEM<p>検索の多い商品カテゴリ</p></h2>
<div className="tabs-sample">
<input id="sample01" type="radio" name="tab_item" value="1" defaultChecked /><label htmlFor="sample01" className="tab_item tab_ban">しばんばん</label>
<input id="sample02" type="radio" name="tab_item" value="2" /><label htmlFor="sample02" className="tab_item">にゃんすけ</label>
<input id="sample03" type="radio" name="tab_item" value="3" /><label htmlFor="sample03" className="tab_item" >ことりまち</label>
<input id="sample04" type="radio" name="tab_item" value="4" /><label htmlFor="sample04" className="tab_item">どうぶつ</label>
<input id="sample05" type="radio" name="tab_item" value="5" /><label htmlFor="sample05" className="tab_item">付箋</label>
<input id="sample06" type="radio" name="tab_item" value="6" /><label htmlFor="sample06" className="tab_item">テープ</label>
<input id="sample07" type="radio" name="tab_item" value="7" /><label htmlFor="sample07" className="tab_item">ファイル</label>
<input id="sample08" type="radio" name="tab_item" value="8" /><label htmlFor="sample08" className="tab_item">文具</label>


<div className="sample00_content">
<div className="recommend-wrap">
	
</div>
</div>




<div className="tab_content sample01_content">
<div className="recommend-wrap">
<div className="item-box">
<a href="/category/キャラクターから探す-しばんばん-シール/"><Image className="item-img" src="https://images.mindwave-store.com/public/78771_main.jpg" />
        <h5 className="list-title text-center">しばんばん シール</h5></a>
	</div>
	<div className="item-box">
		<a href="/category/キャラクターから探す-しばんばん-レター/"><Image className="item-img" src="https://images.mindwave-store.com/public/57623_main.jpg" />
		        <h5 className="list-title text-center">しばんばん レター</h5></a>
			</div>

				<div className="item-box">
			<a href="/category/キャラクターから探す-しばんばん-付箋/"><Image className="item-img" src="https://images.mindwave-store.com/public/55738_main.jpg" />
			        <h5 className="list-title text-center">しばんばん ふせん</h5></a>
				</div>

		<div className="item-box">
	<a href="/category/キャラクターから探す-しばんばん-雑貨/"><Image className="item-img" src="https://images.mindwave-store.com/public/61684_main.jpg" />
	        <h5 className="list-title text-center">しばんばん 雑貨</h5></a>
		</div>
		<div className="item-box">
				<a href="/category/キャラクターから探す-しばんばん-限定商品/"><Image className="item-img" src="https://images.mindwave-store.com/public/61551_main.jpg" />
				        <h5 className="list-title text-center">しばんばん 限定商品</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/キャラクターから探す-しばんばん-ぬいぐるみ/"><Image className="item-img" src="https://images.mindwave-store.com/public/94804_main.jpg" />
					        <h5 className="list-title text-center">ぬいぐるみ</h5></a>
						</div>
</div>
</div>

<div className="tab_content sample02_content">
<div className="recommend-wrap">
	<div className="item-box">
<a href="/category/キャラクターから探す-ごろごろにゃんすけ-シール/"><Image className="item-img" src="https://images.mindwave-store.com/public/77892_main.jpg" />
        <h5 className="list-title text-center">ごろごろにゃんすけ シール</h5></a>
	</div>

		<div className="item-box">
	<a href="/category/キャラクターから探す-ごろごろにゃんすけ-レター/"><Image className="item-img" src="https://images.mindwave-store.com/public/57611_main.jpg" />
	        <h5 className="list-title text-center">ごろごろにゃんすけ レター</h5></a>
		</div>

			<div className="item-box">
		<a href="/category/キャラクターから探す-ごろごろにゃんすけ-付箋/"><Image className="item-img" src="https://images.mindwave-store.com/public/56889_main.jpg" />
		        <h5 className="list-title text-center">ごろごろにゃんすけ ふせん</h5></a>
			</div>

				<div className="item-box">
			<a href="/category/キャラクターから探す-ごろごろにゃんすけ-雑貨/"><Image className="item-img" src="https://images.mindwave-store.com/public/61683_main.jpg" />
			        <h5 className="list-title text-center">ごろごろにゃんすけ 雑貨</h5></a>
				</div>

					<div className="item-box">
				<a href="/category/キャラクターから探す-ごろごろにゃんすけ-限定商品/"><Image className="item-img" src="https://images.mindwave-store.com/public/LA2207C_main.jpg" />
				        <h5 className="list-title text-center">ごろごろにゃんすけ 限定商品</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/キャラクターから探す-ごろごろにゃんすけ-ぬいぐるみ/"><Image className="item-img" src="https://images.mindwave-store.com/public/94798_main.jpg" />
					        <h5 className="list-title text-center">ごろごろにゃんすけ ぬいぐるみ</h5></a>
						</div>
</div>
</div>

<div className="tab_content sample03_content">
				<div className="recommend-wrap">
					<div className="item-box">
				<a href="/category/キャラクターから探す-ことりまち商店街-シール/"><Image className="item-img" src="https://images.mindwave-store.com/public/81392_main.jpg" />
				        <h5 className="list-title text-center">ことりまち商店街 シール</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/キャラクターから探す-ことりまち商店街-マスキングテープ/"><Image className="item-img" src="https://images.mindwave-store.com/public/95042_main.jpg" />
					        <h5 className="list-title text-center">ことりまち商店街 マステ</h5></a>
						</div>

							<div className="item-box">
						<a href="/category/キャラクターから探す-ことりまち商店街-付箋/"><Image className="item-img" src="https://images.mindwave-store.com/public/57740_main.jpg" />
						        <h5 className="list-title text-center">ことりまち商店街 付箋</h5></a>
							</div>

								<div className="item-box">
							<a href="/category/キャラクターから探す-ことりまち商店街-レター/"><Image className="item-img" src="https://images.mindwave-store.com/public/57820_main.jpg" />
							        <h5 className="list-title text-center">ことりまち商店街 レター</h5></a>
								</div>

									<div className="item-box">
								<a href="/category/キャラクターから探す-ことりまち商店街-メモ/"><Image className="item-img" src="https://images.mindwave-store.com/public/57806_main.jpg" />
								        <h5 className="list-title text-center">ことりまち商店街 メモ</h5></a>
									</div>

										<div className="item-box">
									<a href="/category/キャラクターから探す-ことりまち商店街-ファイル/"><Image className="item-img" src="https://images.mindwave-store.com/public/57749_main.jpg" />
									        <h5 className="list-title text-center">ことりまち商店街 ファイル</h5></a>
										</div>
				</div>
</div>

<div className="tab_content sample04_content">
				<div className="recommend-wrap">
					<div className="item-box">
				<a href="/category/アイテムから探す-どうぶつ-犬/"><Image className="item-img" src="https://images.mindwave-store.com/public/95082_main.jpg" />
				        <h5 className="list-title text-center">犬</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/アイテムから探す-どうぶつ-猫/"><Image className="item-img" src="https://images.mindwave-store.com/public/61428_main.jpg" />
					        <h5 className="list-title text-center">猫</h5></a>
						</div>

								<div className="item-box">
							<a href="/category/アイテムから探す-どうぶつ-うさぎ/"><Image className="item-img" src="https://images.mindwave-store.com/public/57468_main.jpg" />
							        <h5 className="list-title text-center">うさぎ</h5></a>
								</div>

									<div className="item-box">
								<a href="/category/アイテムから探す-どうぶつ-くま/"><Image className="item-img" src="https://images.mindwave-store.com/public/57757_main.jpg" />
								        <h5 className="list-title text-center">くま</h5></a>
									</div>

										<div className="item-box">
									<a href="/category/アイテムから探す-どうぶつ-パンダ/"><Image className="item-img" src="https://images.mindwave-store.com/public/55249_main.jpg" />
									        <h5 className="list-title text-center">パンダ</h5></a>
										</div>

							<div className="item-box">
						<a href="/category/アイテムから探す-どうぶつ-鳥/"><Image className="item-img" src="https://images.mindwave-store.com/public/56167_main.jpg" />
						        <h5 className="list-title text-center">鳥</h5></a>
							</div>
											</div>
</div>

<div className="tab_content sample05_content">
				<div className="recommend-wrap">
					<div className="item-box">
				<a href="/category/アイテムから探す-付箋-ToDoリストマーカー/"><Image className="item-img" src="https://images.mindwave-store.com/public/38743_main.jpg" />
								<h5 className="list-title text-center">ToDoリストマーカー</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/アイテムから探す-付箋-オキモチフセン/"><Image className="item-img" src="https://images.mindwave-store.com/public/55404_main.jpg" />
									<h5 className="list-title text-center">おきもちふせん</h5></a>
						</div>

							<div className="item-box">
						<a href="/category/アイテムから探す-付箋-スティックマーカー/"><Image className="item-img" src="https://images.mindwave-store.com/public/57905_main.jpg" />
										<h5 className="list-title text-center">スティックマーカー</h5></a>
							</div>

								<div className="item-box">
							<a href="/category/アイテムから探す-付箋-スタンドスティックマーカー/"><Image className="item-img" src="https://images.mindwave-store.com/public/54477_main.jpg" />
											<h5 className="list-title text-center">スタンドスティックマーカー</h5></a>
								</div>

									<div className="item-box">
								<a href="/category/アイテムから探す-付箋-ワンポイントマーカー/"><Image className="item-img" src="https://images.mindwave-store.com/public/751018_main.jpg" />
												<h5 className="list-title text-center">ワンポイントマーカー</h5></a>
									</div>

										<div className="item-box">
									<a href="/category/アイテムから探す-付箋-チョイスフセン/"><Image className="item-img" src="https://images.mindwave-store.com/public/55897_main.jpg" />
													<h5 className="list-title text-center">チョイスふせん</h5></a>
										</div>
				</div>
</div>

<div className="tab_content sample06_content">
<div className="recommend-wrap">
	<div className="item-box">
<a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80アソート/"><Image className="item-img" src="https://images.mindwave-store.com/public/94913_main.jpg" />
        <h5 className="list-title text-center">マスキングテープ アソート</h5></a>
	</div>

		<div className="item-box">
	<a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ストレート/"><Image className="item-img" src="https://images.mindwave-store.com/public/95054_main.jpg" />
	        <h5 className="list-title text-center">マスキングテープ ストレート</h5></a>
		</div>

			<div className="item-box">
		<a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ダイカット/"><Image className="item-img" src="https://images.mindwave-store.com/public/94356_main.jpg" />
		        <h5 className="list-title text-center">マスキングテープ ダイカット</h5></a>
			</div>

				<div className="item-box">
			<a href="/search/ライフフル/"><Image className="item-img" src="https://images.mindwave-store.com/public/80831_main.jpg" />
			        <h5 className="list-title text-center">マスキングテープ LIFEFUL</h5></a>
				</div>

					<div className="item-box">
				<a href="/search/マスキングシール/"><Image className="item-img" src="https://images.mindwave-store.com/public/78259_main.jpg" />
				        <h5 className="list-title text-center">マスキングシール</h5></a>
					</div>

						<div className="item-box">
					<a href="/search/クリアテープ/"><Image className="item-img" src="https://images.mindwave-store.com/public/95163_main.jpg" />
					        <h5 className="list-title text-center">クリアテープ</h5></a>
						</div>
</div>
</div>

<div className="tab_content sample07_content">
				<div className="recommend-wrap">
					<div className="item-box">
				<a href="/category/アイテムから探す-文具小物-バインダー/"><Image className="item-img" src="https://images.mindwave-store.com/public/51749_main.jpg" />
				        <h5 className="list-title text-center">バインダー</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/アイテムから探す-文具小物-クリアファイル/"><Image className="item-img" src="https://images.mindwave-store.com/public/57748_main.jpg" />
					        <h5 className="list-title text-center">クリアファイル</h5></a>
						</div>

							<div className="item-box">
						<a href="/search/スライダー付きポケットファイル/"><Image className="item-img" src="https://images.mindwave-store.com/public/48866_main.jpg" />
						        <h5 className="list-title text-center">スライダー付きポケットファイル</h5></a>
							</div>

								<div className="item-box">
							<a href="/search/10ポケットファイル/"><Image className="item-img" src="https://images.mindwave-store.com/public/57433_main.jpg" />
							        <h5 className="list-title text-center">10ポケットファイル</h5></a>
								</div>

									<div className="item-box">
								<a href="/search/A4ポケットファイル/"><Image className="item-img" src="https://images.mindwave-store.com/public/48005_main.jpg" />
								        <h5 className="list-title text-center">A4ポケットファイル</h5></a>
									</div>

										<div className="item-box">
									<a href="/category/アイテムから探す-アルバム/"><Image className="item-img" src="https://images.mindwave-store.com/public/57191_main.jpg" />
									        <h5 className="list-title text-center">アルバム</h5></a>
										</div>
				</div>
</div>

<div className="tab_content sample08_content">
		<div className="recommend-wrap">
			<div className="item-box">
		<a href="/category/アイテムから探す-文具小物-ダブルクリップ/"><Image className="item-img" src="https://images.mindwave-store.com/public/93648_main.jpg" />
		        <h5 className="list-title text-center">ダブルクリップ</h5></a>
			</div>

				<div className="item-box">
			<a href="/category/アイテムから探す-文具小物-ゼムクリップ/"><Image className="item-img" src="https://images.mindwave-store.com/public/94040_main.jpg" />
			        <h5 className="list-title text-center">ゼムクリップ</h5></a>
				</div>

					<div className="item-box">
				<a href="/category/アイテムから探す-文具小物-のり/"><Image className="item-img" src="https://images.mindwave-store.com/public/57270_main.jpg" />
				        <h5 className="list-title text-center">のり</h5></a>
					</div>

						<div className="item-box">
					<a href="/category/アイテムから探す-シャープ/"><Image className="item-img" src="https://images.mindwave-store.com/public/57286_main.jpg" />
					        <h5 className="list-title text-center">シャープペン</h5></a>
						</div>

							<div className="item-box">
						<a href="/category/アイテムから探す-ペン/"><Image className="item-img" src="https://images.mindwave-store.com/public/57053_main.jpg" />
						        <h5 className="list-title text-center">ペン</h5></a>
							</div>

								<div className="item-box">
							<a href="/category/アイテムから探す-ペンケース・ポーチ/"><Image className="item-img" src="https://images.mindwave-store.com/public/57437_main.jpg" />
							        <h5 className="list-title text-center">ペンケース・ポーチ</h5></a>
								</div>
</div>
</div>
</div>
			</Container>
    );
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    this.setState({
      activeTab: selectedTab
    });
  }
}

export default withRouter(StationaryTab);
