import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersAirpods extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/airpods/header-apc_sp.jpg" />
	 		<Image src="/img/airpods/header-apc.jpg" alt="オンラインストア限定" fluid />
	 	</picture>
	
	 </div>
	 
	 <div className="mb-5 pb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobileitem/period_sp.jpg" />
	 		<Image src="/img/mobileitem/period.jpg" alt="期間" fluid />
	 	</picture>
		 <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>
	</div>
	</Container>
	 <Container>
	 <div id="" className="mt-5">
	 <div className="my-5">
	 <picture>
	<source media="(max-width: 767px)" srcSet="/img/airpods/ttl_app_sp.png" />
		 <Image className="mt-5" src="/img/airpods/ttl_app.png" alt="" fluid />
	 </picture>
	 </div>
	</div>
	<div className="apc_list mb-5 text-center">
	<Row>
		<Col xs={6} sm={2} ><a href="/item/AP_pro_011/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_pro_011_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_pro_011/">ごろごろにゃんすけ</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_pro_012/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_pro_012_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_pro_012/">しばんばん</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_pro_013/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_pro_013_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_pro_013/">ぴよこ豆</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_pro_014/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_pro_014_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_pro_014/">ばいばいべあ</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_pro_015/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_pro_015_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_pro_015/">うさぎのムーちゃん</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_pro_016/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_pro_016_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_pro_016/">ツンダちゃん</a></p></Col>
		
 </Row>
 </div>

	 </Container>
	 <Container>
	 <div id="" className="mt-5">
	 <div className="my-5">
	 <picture>
	<source media="(max-width: 767px)" srcSet="/img/airpods/ttl_ap_sp.png" />
		 <Image className="mt-5" src="/img/airpods/ttl_ap.png" alt="" fluid />
	 </picture>
	 </div>
	</div>
	<div className="apc_list mb-5 text-center">
	<Row>
		<Col xs={6} sm={2} ><a href="/item/AP_011/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_011_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_011/">ごろごろにゃんすけ</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_012/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_012_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_012/">しばんばん</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_013/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_013_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_013/">ぴよこ豆</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_014/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_014_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_014/">ばいばいべあ</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_015/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_015_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_015/">うさぎのムーちゃん</a></p></Col>
		<Col xs={6} sm={2} ><a href="/item/AP_016/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/AP_016_main.jpg" alt="" fluid /></a>
		<p><a href="/item/AP_016/">ツンダちゃん</a></p></Col>
		
 </Row>
 </div>

	 </Container>




{/* */}

<Container className="my-5 ">

	 <div className="oshirase">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <ul className="mb-5">
	<li>※本製品はAir Podsを収納する為のケースです。他の製品や用途ででは使用しないでください。</li>
	<li>※本製品はキズ、衝撃から完全に機器を保護するものではありません。また、落下や強い衝撃には対応していません。</li>
	<li>※本製品を高温・冷温の場所に放置しないでください。変形・変質の原因になります。</li>
	<li>※素材にはTPU（熱可塑性ポリウレタン）を使用しております。TPUは特性上紫外線による樹脂の変質等の経年劣化で黄ばみが生じてまいります事をご了承ください。</li>
	<li>※TPUは耐熱性はあまりなく、火に近づけすぎたり熱い物にふれたりすると変形してしまいますので、車のダッシュボード上など、高温になる場所に置いたりしない様、十分注意をお願いいたします。それに伴う本製品、機器、他製品に対して一切の責任を負いかねます。</li>
	<li>※TPUは耐油性に優れてはおりますが、除光液やアセトンなどのケトン類は、TPUを溶かしますので、使用しないでください。</li>
	<li>※お手入れの際は、水でうすめた中性洗剤で拭き取るのをお勧めします。</li>
	<li>※思わぬ事故の危険性がありますので、小さなお子様の手に届かないところに保管してください。</li>
	<li>※本製品は、防水・防滴仕様ではありません。</li>
	<li>※本製品の使用中、お客様の過失及び誤った使用により機器やデータの破損・障害については、責任を負いかねますので、予めご了承ください。</li>
	</ul></Col></Row>
	 </div>
	 
	
</Container>
<Container className="my-5">
<a href="/others/mobileitem/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/mobileitem/bnr_mobileitem_sp.jpg" />
	 <Image src="/img/mobileitem/bnr_mobileitem.jpg" alt="オンラインストア限定" fluid />
 </picture></a>
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersAirpods);
