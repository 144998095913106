import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122

class OthersSmp extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <Row>
		 	  <div className="mb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/smp/header_sp_2020.jpg" />
	 		<Image src="/img/smp/header_pc_2020.jpg" alt="オンラインストア限定モバイルアイテム" fluid />
	 	</picture>
	 </div>
		 </Row>

		 {/*<p className="red"><strong>※他の通常商品（シールなど）を一緒にご購入の場合は、別送となり、別途送料が必要です。受注確定メールにて訂正した正しい金額をお知らせさせていただきます。<br />
		 ※マルチ手帳Lサイズが生産終了につき、在庫限りとなります。</strong></p>
		 <p className="red"><strong>※現在発生している新型コロナウイルス感染症の影響により、iPhoneケース【背面ガラス(ラウンド型)】 の「11」「11pro」の生産および出荷の遅延が避けられない見通しとなっております。これにより販売を一旦休止とさせていただきます。<br />
	入荷の目途が立ち次第、再販させていただきますので、お客様にはご迷惑をおかけし大変申し訳ございませんが、何卒ご理解を賜りますよう、よろしくお願い申し上げます。</strong></p>*/}
	{/*<Row className="mt-5">
		<Col xs={12} sm={6} ><a href="/category/アイテムから探す-モバイル用品-A1/"><Image src="/img/smp/new_a1.png" alt="第1弾" className="mb-5" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/アイテムから探す-モバイル用品-A2/"><Image src="/img/smp/new_a2.png" alt="第2弾" className="mb-5" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/アイテムから探す-モバイル用品-B1/"><Image src="/img/smp/new_b1.png" alt="第3弾" className="mb-5" fluid /></a></Col>
		<Col xs={12} sm={6} ><a href="/category/アイテムから探す-モバイル用品-B2/"><Image src="/img/smp/new_b2.png" alt="第4弾" className="mb-5" fluid /></a></Col>
	</Row>*/}
	</Container>

	 <Container>
	 <div id="smp_chara-list" className="my-5 pb-3">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/smp/ttl_chara_sp.png" />
		 <Image className="my-5" src="/img/smp/ttl_chara.png" alt="キャラクターで探す" fluid />
	 </picture>
	 <ul className="mb-5">
	 <li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-モバイル用品/" className="">ごろごろにゃんすけ</a></li>
	 <li><a href="/category/キャラクターから探す-コソコソさん-モバイル用品/" className="">コソコソさん</a></li>
	 <li><a href="/category/キャラクターから探す-しばんばん-モバイル用品/" className="">しばんばん</a></li>
	 <li><a href="/category/キャラクターから探す-くろしばんばん-モバイル用品/" className="">くろしばんばん</a></li>
	 <li><a href="/category/キャラクターから探す-うさぎのムーちゃん-モバイル用品/" className="">うさぎのムーちゃん</a></li>
	 <li><a href="/category/キャラクターから探す-ツンダちゃん-モバイル用品/" className="">ツンダちゃん</a></li>
	 <li><a href="/category/キャラクターから探す-ばいばいべあ-モバイル用品/" className="">ばいばいべあ</a></li>
	 <li><a href="/category/キャラクターから探す-ぴよこ豆-モバイル用品/" className="">ぴよこ豆</a></li>
	 <li><a href="/category/キャラクターから探す-ようこそかわうそ-モバイル用品/" className="">ようこそかわうそ</a></li>
	 <li><a href="/category/キャラクターから探す-ゆるあにまる-モバイル用品/" className="">ゆるあにまる</a></li>
	 </ul>
	</div>
	 </Container>

	  <Container>
		<div className="mt-5">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/smp/ttl_item_sp.png" />
		 <Image className="my-5" src="/img/smp/ttl_item.png" alt="アイテムで探す" fluid />
	 </picture>
		 <ul className="smp_btn">
			 <li><AnchorLink offset={this.state.scrolloffset} href="#ring" className="opa06"><Image src="/img/smp/btn_ring.jpg" alt="スマホリング" fluid /></AnchorLink></li>
			 <li><AnchorLink offset={this.state.scrolloffset} href="#smp01" className="opa06"><Image src="/img/smp/btn_smp01.jpg" alt="TPUアクリルはめ込みケース" fluid /></AnchorLink></li>
			 <li className="newset"><AnchorLink offset={this.state.scrolloffset} href="#smp02" className="opa06"><Image src="/img/smp/btn_smp03.jpg" alt="背面ガラスケース（ラウンド）" fluid />{/*<span className="newbadge">NEW</span>*/}</AnchorLink></li>
			 <li><AnchorLink offset={this.state.scrolloffset} href="#smp03" className="opa06"><Image src="/img/smp/btn_smp04.jpg" alt="背面ガラスケース（スクエア）" fluid /></AnchorLink></li>
			 <li><AnchorLink offset={this.state.scrolloffset} href="#smp04" className="opa06"><Image src="/img/smp/btn_smp02.jpg" alt="マルチ手帳ケース" fluid /></AnchorLink></li>
		 </ul>
		 </div>
</Container>

 <Container>
	  <div id="ring" className="mt-5 mb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/smp/smpring_sp.jpg" />
	 		<Image className="mt-5" src="/img/smp/smpring_pc.jpg" alt="スマホリング" fluid />
	 	</picture>
	 </div>
	 	 <Row>
	 		<Col xs={12} sm={{ span: 4, offset: 4 }} ><a href="/category/アイテムから探す-モバイル用品-スマホリング" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> スマホリング</a></Col>
	 	</Row>
</Container>


 <Container>
	  <div id="smp01" className="mb-5 mt-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/smp/acrylic_sp.jpg" />
	 		<Image className="mt-5" src="/img/smp/acrylic_pc.jpg" alt="TPUアクリルはめ込みケース" fluid />
	 	</picture>
	 </div>

	 	 <Row>
	 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-アクリルiPhoneケース-(8･7･6s･6)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone 8/7/6s/6/SE(第2世代対応型)</a></Col>
	 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-アクリルiPhoneケース-(Plus(8･7･6s･6)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone Plus 8/7/6s/6</a></Col>
	 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-アクリルiPhoneケース-(XS･X)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone XS/X</a></Col>
	 	</Row>
</Container>

 <Container>
	  <div id="smp02" className="mt-5 mb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/smp/round_sp.jpg" />
	 		<Image className="mt-5" src="/img/smp/round_pc.jpg" alt="背面ガラスケース（ラウンド）" fluid />
	 	</picture>
	 </div>

	 	 <Row>
	 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-背面ガラスiPhoneケース(ラウンド)-(8･7)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone 8/7/SE(第2世代対応型)</a></Col>
	 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-背面ガラスiPhoneケース(ラウンド)-(XR)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone Plus XR</a></Col>
		 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-背面ガラスiPhoneケース(ラウンド)-(XS･X)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone XS/X</a></Col>
	 	</Row>

		<Row className="mt-5">

			<Col xs={12} sm={{ span: 4, offset: 2 }} className="newset"><a href="/category/アイテムから探す-背面ガラスiPhoneケース(ラウンド)-(11)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone 11</a>{/*<span className="newbadge">NEW</span>*/}</Col>
			<Col xs={12} sm={4}  className="newset"><a href="/category/アイテムから探す-背面ガラスiPhoneケース(ラウンド)-(11pro)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone 11 pro</a>{/*<span className="newbadge">NEW</span>*/}</Col>
	 </Row>
	{/* <p className="red mt-5"><strong>※現在発生している新型コロナウイルス感染症の影響により、iPhoneケース【背面ガラス(ラウンド型)】 の「11」「11pro」の生産および出荷の遅延が避けられない見通しとなっております。これにより販売を一旦休止とさせていただきます。<br />
入荷の目途が立ち次第、再販させていただきますので、お客様にはご迷惑をおかけし大変申し訳ございませんが、何卒ご理解を賜りますよう、よろしくお願い申し上げます。</strong></p>*/}
</Container>

 <Container>
	  <div id="smp03" className="mt-5 mb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/smp/square_sp.jpg" />
	 		<Image className="mt-5" src="/img/smp/square_pc.jpg" alt="背面ガラスケース（スクエア）" fluid />
	 	</picture>
	 </div>

		 	 <Row>

		 		 <Col xs={12} sm={{ span: 4, offset: 2 }}><a href="/category/アイテムから探す-背面ガラスiPhoneケース(スクエア)-(8･7)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone Plus 8/7/SE(第2世代対応型)</a></Col>
		 		 <Col xs={12} sm={4} ><a href="/category/アイテムから探す-背面ガラスiPhoneケース(スクエア)-(XS･X)" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> iPhone XS/X</a></Col>
		 	</Row>

</Container>

 <Container>
	  <div id="smp04" className="mt-5 mb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/smp/book_sp.jpg" />
	 		<Image className="mt-5" src="/img/smp/book_pc.jpg" alt="マルチ手帳ケース" fluid />
	 	</picture>
	 </div>

	 	 <Row>
		  <Col xs={12} sm={{ span: 4, offset: 4 }} ><a href="/category/アイテムから探す-手帳型スマホケース-M" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> Mサイズ</a></Col>
	 		 {/*<Col xs={12} sm={4} ><a href="/category/アイテムから探す-手帳型スマホケース-L" className="btn-blue w-100 btn btn-smp btn-lg"><i className="fa fa-arrow-right"></i> Lサイズ（在庫限り）</a></Col>*/}
	 	</Row>
{/*<p className="red mt-5 text-center"><strong>※マルチ手帳Lサイズが生産終了につき、在庫限りとなります。</strong></p>*/}
	 		 </Container>

<Container className="mb-4 ">
	 <div className="oshirase">
	 <h3>使用上のご注意</h3>
	 <ul>
	<li>※スマートフォン用ケースです。本来の用途以外には使用しないでください。</li>
	<li>※スマホケースは、スマートフォン本体へのキズや汚れを完全に防ぐものではありません。</li>
	<li>※本製品のご使用中に伴う、スマートフォンの紛失、故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
	<li>※スマホケースを装着したまま充電スタンドの使用や、SIMカード、SDカードの出し入れはできません。</li>
	</ul>
	 </div>
	<div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


 {/*<Container>

	<h3><span>使用上のご注意</span></h3>


	 		 </Container>*/}
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersSmp);
