import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			ErrMsg          : '',
			addressData     : [],
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData () {

		try{

			//アドレスデータを取得
			const refAddress = await API.Address.GetAll();

			//データ更新
			this.setState({
				addressData   : refAddress.items ? refAddress.items : [],
				isLoading     : false,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	handleClose(){
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
						</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

							<Form>
							<Container>
								<h2>お届け先アドレス帳</h2>
								<Row>
									<Col className="offset-md-1 col-md-10">
										<Button type="button" size="lg" className="btn-blue"onClick={()=>{ this.props.history.push('/mypage/address/new/'); }} >新しいお届け先を登録する</Button>
											<div className="delivery-list">
										{this.state.addressData.map( item =>{

											const addressid     = item.addressid;
											const o_name_1      = item.o_name_1;
											const o_name_2      = item.o_name_2;
											const o_zip_1       = item.o_zip_1;
											const o_zip_2       = item.o_zip_2;
											const o_pref        = item.o_pref;
											const o_addr_1      = item.o_addr_1;
											const o_addr_2      = item.o_addr_2;
											const o_addr_3      = item.o_addr_3;
											const o_tel_1       = item.o_tel_1;
											const o_tel_2       = item.o_tel_2;
											const o_tel_3       = item.o_tel_3;
											const o_com_name    = item.o_com_name;
											const use_flag      = item.use_flag;
											return(

													<div className="delivery-add">
														<div key={addressid}>
														
														<h4>{o_name_1}　{o_name_2}　様</h4>
														<p>〒{o_zip_1}-{o_zip_2}<br/>
														{o_pref}{o_addr_1}{o_addr_2}　{o_addr_3}</p>
														<p>{o_com_name}</p>
														<p>TEL：{o_tel_1}-{o_tel_2}-{o_tel_3}</p>														
														{(() => {
											if (use_flag === true){
												return(
													<span className="useflag">既定の住所</span>
												);
											}
										})()}
														
														<div className="row-btn">
															<Button type="button" block className="btn-bk" onClick={()=>{ this.props.history.push('/mypage/address/' + addressid + '/'); }}>編集</Button>
														</div>
													</div>
														</div>
											);
										})}
										
										
</div>
<p className="red mt-5">※過去の注文情報に変更内容は反映されません。発送前のご注文のご住所等の変更はメール・お電話でご連絡ください。</p>

	</Col>
								</Row>
							</Container>
							</Form>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
