import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Image,
	Card,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isLockForm     : false,
			isSuccess      : false,
			isLogin        : false,
			isDoui         : false,
			ErrMsg         : '',
			email          : '',
			passwd         : '',
		}
		this._reload          = this._reload.bind(this);
		this._signUp          = this._signUp.bind(this);
		this._douiClick       = this._douiClick.bind(this);
		this._getData         = this._getData.bind(this);
		this.handleReload     = this.handleReload.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.handleClose      = this.handleClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	_reload() {
		this.setState({ isSubmit: false});
	}
	async _signUp(email, passwd){
		try {
			await API.Auth.signUp(email, passwd);
			return(null);
		}
		catch(error) {
			let error_message = '';
			if(error.code === 'InvalidParameterException'){
				error_message = 'パスワードは最低6文字以上設定してください。';
			}
			else if(error.code === 'UsernameExistsException'){
				error_message = 'ご入力を頂いたメールアドレスはすでに登録されています。';
			}
			else{
				error_message = error.message;
			}
			//console.log(error);
			return(error_message);
		}
	}
	_douiClick(e) {
		if(e.target.checked === true){
			this.setState({ isDoui: true });
		}
		else{
			this.setState({ isDoui: false });
		}
	}
	async _getData() {

		//ログインの確認
		try{
			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			//ログイン済みの場合フラグ
			if(cognitoid){
				this.setState({
					isLoading  : false,
					isLogin    : true,
				});
				return(0);
			}
		}
		catch(error) {
			//読み込み完了
			this.setState({
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//stateから直接取得する更新項目
			const isDoui  = this.state.isDoui;

			if(isDoui === false){
				this.setState({
					ErrMsg     : '※ご利用規約およびプライバシーポリシーに同意してください。<br/>',
					isLockForm : false,
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const email  = formObj.email.value;
			const passwd = formObj.passwd.value;

			//サインアップ
			const err = await this._signUp(email, passwd);
			//console.log(err);
			if(err){
				this.setState({
					ErrMsg     : err + '<br/>',
					isLockForm : false,
				});
				return(0);
			}
			else{
				//成功
				this.setState({
					isSuccess     : true,
					isLockForm    : false,
				 });
			}

		}
		catch(error) {
			//console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
		//
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLogin){
			return (
				<Redirect to="/mypage/"/>
			);
		}
		else if(this.state.isSuccess){
			return (
				<Redirect to="/member/verify/"/>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/member/">新規会員登録</a></div>
							</div>
						</Container>




						<Container>
						<h1><Image src="/img/menbership_ttl.jpg" alt="新規会員登録" fluid />
							</h1>
							<h2>- 会員登録のおすすめ -</h2>
							<p>
								「マインドウェイブ オンラインストア」に会員登録していただくと、お届け先などのお客様情報を毎回ご入力いただく手間が省け、商品のご注文が簡単になります。<br />
								新商品やお得な情報は会員様限定のメールマガジンでご案内しております。また、会員様限定の特典やキャンペーンも多数企画しております☆<br />
								ぜひこの機会に会員登録をおすすめいたします！
							</p>
						</Container>

						<Container>
						    <h3><i class="fas fa-paw"></i> いろいろな会員特典 <i class="fas fa-paw"></i></h3>
						<Row>
						<Col md={4} mt={5}>
						        <Row>
													<Col sm={4} md={12} xs={4}>
						  <div className="guide-pat"><Image src="/img/icon-member01n.png" /></div>
						          </Col>
														<Col sm={8} md={12} xs={8}>
						                <h3 className="guide-pat-title">メルマガ配信</h3>
						    <p>ご希望の会員様に新商品の入荷やお得な情報を不定期に配信いたします。</p>
						    </Col>
						    </Row>
						</Col>

						  <Col md={4} mt={5} mb={5}>
						                    <Row>
						<Col md={12} sm={4} xs={4}>
						  <div className="guide-pat"><Image src="/img/icon-member02n.png" /></div>
						          </Col>
						              <Col md={12} sm={8} xs={8}>
						               <h3 className="guide-pat-title">会員限定クーポンの配布</h3>
						    <p>会員の方を対象とした、割引クーポンの配布を企画しております。</p>
						    </Col>
						</Row>
						</Col>

						<Col md={4} mt={5} mb={5}>
						    <Row>
						  <Col md={12} sm={4} xs={4}>
						  <div className="guide-pat"><Image src="/img/icon-member03n.png" /></div>
						          </Col>
						                <Col md={12} sm={8} xs={8}>
						    <h3 className="guide-pat-title">限定プレゼント</h3>
						    <p>もれなく付いたり、抽選で当たったり。会員の方だけにお届けするプレゼント企画もございます。</p>
						    </Col>
						      </Row>
						    </Col>
						</Row>
						</Container>

						<Container>
							<h3><span>会員登録の前に</span></h3>
							<p>次の「ご利用規約」の内容をお読みいただき、「ご利用規約およびプライバシーポリシーに同意する」にチェックして次へ進んでください。</p>
							<Row>
								<Col sm={12}>
									<div className="rule-frame">
										<h4>ご利用規約</h4>
										<p>
											株式会社クヌム（以下、弊社）が運営する『マインドウェイブ オンラインストア』（以下、当サイト）では｢利用規約｣を設けております。<br/>
											当サイトをご利用の方は、以下の条件にご同意いただいたものとみなします。
										</p>
										<div className="rule">
											<dl>
												<dt>
													第1条 利用規約の改定
												</dt>
												<dd>
													弊社は予告なく｢利用規約｣を変更する事があります。<br/>
													その場合は変更後の｢利用規約｣が適用されますので、あらかじめご了承下さい。
												</dd>
												<dt>
													第2条 本サービスの内容
												</dt>
												<dd>
													弊社がご利用者に提供するサービスは以下のとおりとし、サービスの詳細については当サイトにて別途通知いたします。
													<ul>
														<li>当サイトで取扱う製品等の販売</li>
														<li>その他、今後弊社がはじめるサービス</li>
													</ul>
												</dd>
												<dt>
													第3条 免責
												</dt>
												<dd>
													弊社では当サイトに掲示した情報について、以下の点について責任を負うものではありません。
													<ul>
														<li>掲示された情報が、全て正しく、全て安全で、全て有用であること。</li>
														<li>掲示された情報が、常に最新のものであること</li>
														<li>当サイトの利用によって起こった一切の障害</li>
														<li>予告なしにサーバーの停止、情報の変更、提供の中止・中断を行うこと。またそれによって生じたいかなる 障害。</li>
														<li>
															入力されたＩＤとパスワードが事前に登録されたものと一致することを弊社所定の方法により確認した場合、
															それが、盗用、不正使用その他の事故により、本人以外の者が利用している場合に生じるいかなる障害
														</li>
														<li>氏名、住所、電話番号、Eメールアドレス、その他弊社への届出内容に変更が生じた場合、早やかに所定の届出を行わなかったことで被る障害</li>
													</ul>
												</dd>

												<dt>
													第4条 リンク先ウェブサイトのサービスについて
												</dt>
												<dd>
													当サイトからリンクされた弊社関連会社のウェブサイトをご利用される際には、リンク先各ウェブサイトの利用規約にしたがって下さい。
												</dd>
												<dt>
													第5条 IDおよびパスワード
												</dt>
												<dd>
													本サービスで交付するID・パスワードについての管理および使用（変更を含む）は、ID登録されたご利用者の責任とさせていただきます。
													また、ID登録されたご利用者は、氏名、住所、電話番号、Eメールアドレス、その他弊社に届け出ている内容に変更が生じた場合は、
													速やかに弊社指定の方法で通知するものとします。
												</dd>
												<dt>
													第6条 個人情報
												</dt>
												<dd>
													ご利用者は、本サービスの運営、宣伝物の送付、商品等の取引、代金回収等の目的の範囲において、弊社がご利用者に関する個人情報を利用することに同意するものとします。
													なお、弊社が取得したお客様の個人情報は、弊社が別途定める｢個人情報の取扱いについて｣に従って適切な保護に努めます。
												</dd>
												<dt>
													第7条 禁止事項
												</dt>
												<dd>
													本サービスのご利用にあたり、以下の行為を禁止させていただきます。
													<ul>
														<li>本利用条件に違反する行為</li>
														<li>第三者もしくは弊社に不利益ないし損害を与える行為、またはその恐れのある行為</li>
														<li>公序良俗に反する行為、またはその恐れのある行為</li>
														<li>犯罪行為、またはその恐れのある行為</li>
														<li>営利を目的とする行為、またはその準備を目的とする行為</li>
														<li>コンピュータウイルス等有害なプログラムを使用もしくは提供する行為、またはその恐れのある行為</li>
														<li>他のお客様のID・パスワードを不正に使用する行為</li>
														<li>他のお客様、または第三者のプライバシーを侵害する行為</li>
														<li>その他弊社が不適切と判断する行為</li>
													</ul>
												</dd>
												<dt>
													第8条 準拠法
												</dt>
												<dd>
													本規約の解釈、適用については日本法に準拠します。
												</dd>
											</dl>
										</div>
									</div>
								</Col>
							</Row>
						</Container>

						<Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
						<Container>
							<h3><span>会員登録はこちらから</span></h3>
							<Row>
								<Col className="offset-sm-1 col-sm-10 offset-md-2 col-md-8 mt-5">
									<Card>
										<Card.Body>
											<Form.Check inline name="doui" type="checkbox" id="inline-checkbox-1" label="ご利用規約およびプライバシーポリシーに同意する" onClick={this._douiClick} disabled={this.state.isLockForm} />
											<Form.Group controlId="email">
												<Form.Label>メールアドレス</Form.Label>
											        <Form.Control name="email" type="email" placeholder="メールアドレス" pattern=".{1,255}" size="lg" required disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													メールアドレスを入力してください
												</Form.Control.Feedback>
											</Form.Group>

											<Form.Group controlId="passwd">
												<Form.Label>パスワード</Form.Label>
											        <Form.Control name="passwd" type="password" placeholder="パスワード" pattern=".{1,255}" size="lg" required disabled={this.state.isLockForm} />
												<Form.Control.Feedback type="invalid">
													パスワードを入力してください
												</Form.Control.Feedback>
											</Form.Group>


											<Button type="submit" className="btn-blue" size="lg" block disabled={this.state.isLockForm}>新規登録</Button>
											<p className="red mt-5 font-weight-bold">※メールアドレス確認・ご本人確認のため、ご入力いただいたメールアドレス宛に「検証コード」をお送りします。<br/>メールで到着した検証コードを次の画面で入力してください。</p>

										</Card.Body>
									</Card>
									<p className="red my-5">※「ご入力を頂いたメールアドレスはすでに登録されています」というエラーが表示される場合、会員登録操作が最後まで完了していない可能性がございます。詳しくは<a href="/guide/faq/" target='_blank'>【Ｑ＆Ａ】</a>をご確認ください。</p>

									<Button type="button" className="btn-blue" size="lg" block disabled={this.state.isLockForm} onClick={() => this.props.history.push('/member/verify/')} >検証コードの入力はこちら</Button>
									<Button type="button" className="btn-blue" size="lg" block disabled={this.state.isLockForm} onClick={() => this.props.history.push('/member/resent/')} >検証コードの再送はこちら</Button>
								</Col>
							</Row>
						</Container>
						</Form>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withRouter(Default);
