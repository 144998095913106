
const AwsExports = {
    Auth: {
        identityPoolId: 'ap-northeast-1:a4ef319c-4e76-4de1-bb09-9749d6e3070b',
        userPoolId: 'ap-northeast-1_EaQc1I8Sq',
        userPoolWebClientId: '7iht3nk0p2iuvp9gl4pd81uev3',
        region: 'ap-northeast-1',
    },
    API: {
        'aws_appsync_graphqlEndpoint': 'https://chd6tagaffhkzn5rnfpkzs3vbu.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        'aws_appsync_region': 'ap-northeast-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    },
};

export default AwsExports;
