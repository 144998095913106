import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class GuideSend extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
				<Container>
					<div className="head-logo sec">
						<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
					</div>

						<div className="pan">
							<div><a href="/stationary_index/">TOP</a></div>
							<div><a href={'/guide/'}>ショッピングガイド</a></div>
							<div>配送・送料について</div>
						</div>
					<h1>SHOPPING GUIDE<p>ショッピングガイド</p></h1>
 </Container>

<Container fluid={true}>
   <Row>
        <ul className="sub-menu">
							<li><a href="/guide/">ご注文の流れ</a></li>
							<li><a href="/guide/payment/">お支払いについて</a></li>
							<li><a href="/guide/send/">配送・送料について</a></li>
							<li><a href="/guide/#wrapping">ラッピングについて</a></li>
							<li><a href="/guide/faq/">よくあるご質問</a></li>
							<li><a href="/member/l">会員登録のおすすめ</a></li>
         </ul>
     </Row>
 </Container>

<Container className="longtxt">
	 <Row>
	 <Col lg={{ span: 10, offset: 1 }} md={12}>
<h2>配送・送料について</h2>

<Row>
<Col md={6} mt={5} mb={5}>
     <Row>
  <Col xs={4} sm={12}>
  <div className="guide-pat"><Image src="/svg/icon-send01-2.svg" /></div>
          </Col>
              <Col xs={8} sm={12}>
<h3 className="guide-pat-title">ゆうパケット</h3>
    <p>お届け先住所の郵便受けに投函する配送サービスです。お受け取りのためのご在宅は不要ですので小さな荷物におすすめの配送方法です。</p>
    </Col>
     </Row>
  <ul className="guide-point">
  <li>ゆうパケット送料：全国一律220円（税込）</li>
  <li>ポスト投函のため荷物の補償がございません</li>
    <li>お届け日時指定はできません<span className="small">（およそ発送から3日前後）</span></li>
  </ul>
</Col>
<Col md={6} className="mt5">
               <Row>
  <Col xs={4} sm={12}>
  <div className="guide-pat"><Image src="/svg/icon-send02-2.svg" /></div>
          </Col>
              <Col xs={8} sm={12}>
    <h3 className="guide-pat-title">宅急便</h3>
    <p>商品のお届けの際に生じた運送会社の過失による破損や紛失などの場合、運送約款に基づいた補償があり、より安心してご利用いただける配送方法です。</p>
    </Col>
     </Row>

    <ul className="guide-point">
  <li>宅急便送料：全国一律660円（税込）</li>
  <li>北海道・沖縄・離島は、送料1,320円</li>
    <li>お届け日時指定が可能です<span className="small">（最短で発送の翌日着）</span></li>
  </ul>
</Col>
</Row>

<div className="sendrule mt-5">
<hr />
<Row>
<Col sm={4}>
<h4>お届け先の郵便ポストへ直接投函</h4>
<p>ポスト投函後の紛失などは補償がありません</p>
<p className="red">お届け時間の指定ができません。（発送から3日前後で到着）</p>
</Col>
<Col sm={8}>
<dl>
	<dt><Image src="/img/order/icon_dmbin.svg" alt="ゆうパケット" style={{ width: 180}} /></dt>
	<dd><p>ノートやメモ帳など少し厚みのある商品など、同マークの商品３個まで「ゆうパケット」での発送が可能です。</p>
	<p className="red">※４個以上は自動的に「宅急便」指定となります。</p></dd>
<hr />
	<dt><Image src="/img/order/icon_dm10.svg" alt="パケット10" style={{ width: 180}} /></dt>
	<dd><p>ぺたんこシールや鉛筆など、同マークの商品１０個まで「ゆうパケット」での発送が可能です。</p>
	<p>1０個中３個まで、「上記ゆうパケット可」商品が入る場合もゆうパケットでのお届けが可能です。</p>
	<p className="red">※１１個以上は自動的に「宅急便」指定となります。</p>
	<p className="red">※ゆうパケット可能商品が４個以上入る場合は「宅急便」指定となります。</p></dd>
</dl>
</Col>
</Row>
<hr />
<Row>
<Col sm={4}>
<h4>配達員が手渡しでお届け</h4>
<p>配送途中の運送会社による商品の紛失でも送料・商品代金の補償がつきます。</p>
<p>お届け日時の指定が可能です。（最短で1～5日で到着）</p>
</Col>
<Col sm={8}>
<dl>
	<dt><Image src="/img/order/icon_yamato.svg" alt="宅急便・宅急便" style={{ width: 180}} /></dt>
	<dd><p>ペンケースやランチボックスなど、1個から「宅急便」指定となります。「宅配ボックス」の利用をご希望の場合はご注文時に備考欄に記載してください。</p>
	<p className="red">ゆうパケット対応商品でも、お急ぎの場合やお届け日時のご希望のあるお客様は「宅急便」をお選びいただけます。</p></dd>
</dl></Col>
</Row>
<hr />
</div>


<div >
<h3><span>ゆうパケット</span></h3>

    <h4><span className="marker_y">「ゆうパケット」のアイコンのついた商品に限り、配達方法でゆうパケットを選択いただけます。</span></h4>
  <ul className="guide-ul">
  <li>但し、４つ以上ご購入の場合は、４つ全てが「ゆうパケット」の商品で、サイズがゆうパケット対応可能範囲であっても、『宅急便』でのお届けとなり、送料全国一律660円となります。</li>
  <li>「ゆうパケット」の商品と、「宅急便」の商品を同時に購入される場合は、自動的に「宅急便」送料となります。</li>
  <li>ゆうパケットの寸法は、長辺４０ｃｍ以内、厚さ２ｃｍ以内におさまり、長辺+底辺+高さが７０ｃｍ以内のものが対象となります。</li>
  <li>ゆうパケットは配達時、受領印を必要としないで直接ポストへの投函となります。ポストに入らない場合は専用袋に入れてドアノブにかける場合がございます。また、ドアノブにかけることが出来ない場合は玄関先に置いておく場合もございますのでご了承ください。</li>
  <li>万一、紛失や盗難または破損した場合、当店からの補償は一切ございませんのでご了承の上、ご利用ください。</li>
  </ul>

   <h4><span className="marker_y">「ゆうパケット１０」のアイコンのついた商品は10個までお選びいただけます。</span></h4>
  <ul className="guide-ul">
  <li>１１個以上ご購入の場合は、『宅急便』でのお届けとなり、送料全国一律660円となります。</li>
  <li>その他ゆうパケットの寸法などは上記と同じ条件となります。</li>
  </ul>
</div>

<div>
<h3><span>宅急便</span></h3>
 <p>北海道・沖縄など別途送料が掛かる場合は、ご注文の際の住所より自動計算いたします。その他、別途送料の必要な地域へのお届けは、「ご注文確認メール」の際に担当者より正式な金額をメールにてご連絡いたします<span className="marker_y">（ご注文後に自動送信されるメールとは別のメールです。ご注意ください）</span>。 </p>
 <p className="my-3">※ヤマト運輸の宅急便の配送にかかる日数について、2023年6月1日（木）から下記区間でお荷物のお届け日数と指定時間帯が変更となり、<span className="marker_y">最短でのお届けが出荷日の翌々日午前中以降</span>となります。<br />詳しくは【<a href="https://www.yamato-hd.co.jp/important/info_230417_1.html" target="_blank" rel='noopener noreferrer'>ヤマト運輸 <i class="fas fa-external-link-alt"></i></a> 】へご確認ください。<br />
【対象エリア】東京都、神奈川県、千葉県、埼玉県、茨城県、群馬県、栃木県、山梨県、新潟県 </p>

 <div className="card">
   <h4 className="card-title"><span>代金引換でのお届け</span></h4>
 <div className="card-body">
 <p>代金は商品配達時に運送業者の配達員へ現金でお支払いください。一部離島につきましては、代金引換がご利用できない場合がございます。あらかじめご了承ください。
ゆうパケットをご希望の方は、代金引換をご利用いただけません。</p>
<table className="table table-bordered text-center">
                               <thead className="thead-dark">
                                        <tr>
                                            <th>商品代金＋送料(税込)</th>
                                            <th>手数料(税込)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1万円以下</td>
                                            <td>330円</td>
                                        </tr>
                                        <tr>
                                            <td>3万円以下</td>
                                            <td>440円</td>
                                        </tr>
                                        <tr>
                                            <td>10万円以下</td>
                                            <td>660円</td>
                                        </tr>
                                        <tr>
                                            <td>10万円以上</td>
                                            <td>1,100円</td>
                                        </tr>

                                    </tbody>
                                </table>
</div>
   </div>
</div>
</Col>
</Row>
</Container>
<Container>
<Row>
<Col lg={{ span: 10, offset: 1 }} md={12}>
<div>
<h3>お届け日時指定</h3>
<div className="send-time">
<ul>
  <li>午前中</li>
  <li>14時 ～ 16時</li>
  <li>16時 ～ 18時</li>
  <li>18時 ～ 20時</li>
  <li>19時 ～ 21時</li>
  </ul>
</div>


<p>ご注文受付後、1週間前後で宅急便にてお届けいたします。ご注文時に配達希望日時（注文日より1週間以降）をご指定いただければ、ご希望の日時にお届けできます。
ご注文が混みあっている場合は、お届けまでお時間を頂く商品もございます。その場合は、当社からご連絡させていただきます。
</p>
</div>
</Col>
</Row>
</Container>
<Container>
<Row>
<Col lg={{ span: 10, offset: 1 }} md={12}>
<div>
<h3><span>送料無料・送料込み［メーカー発送］商品について</span></h3>
<dl>
<dt><span className="marker_y">【送料無料】商品</span></dt>
<dd>北海道・沖縄県へのお届けは、追加送料分660円（税込）のみ頂戴します。ご注文時は送料が0円となりますので、受注確定メールにて訂正してお伝えいたします。</dd>
<dt><span className="marker_y">《送料込み》商品</span></dt>
<dd>北海道・沖縄県へのお届も送料0円でお届けします。</dd>
<dt><span className="marker_y">［メーカー発送］商品</span></dt>
<dd>【スマホケース・iPhoneケース・スマホリング・モバイルバッテリー・ワイヤレス充電器】等［メーカー発送］と記載のある商品は、他の商品とは発送が異なり、受注生産で、商品が出来次第メーカーよりレターパックで発送となります。
そのため、お届け日時の指定、ラッピング、代金引換でのお支払いをお受けできません。あらかじめご了承いただきますようお願い申し上げます。<br />
また、通常商品（シールなど）を一緒にご購入の場合は、別々でのお届けとなり、送料が必要となる場合がございます。</dd>
</dl>
<h4 className="red"> 送料変更のある方へは、スタッフにより送料を修正した正しい金額を「受注確定メール」にてご連絡いたします。</h4>
</div>
</Col>


<Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
<p><Image src="/img/guide/img_send_free.png" alt="メーカー発送商品の場合" fluid /></p>
</Col>
</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(GuideSend);
