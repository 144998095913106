import { API, graphqlOperation } from 'aws-amplify';

const Setting = {};

Setting.Get = async function ()  {
		
	try{

		const Query = `query getSetting {
			getSetting {
				carriage_free_flag
				carriage_free_price
				carriage_free_ec_user_only_flag
				carriage_free_order_history_kbn
				carriage_1
				carriage_2
				carriage_3
				carriage_4
				carriage_5
				cashdeli_1
				cashdeli_2
				wrapping_1
				date_diff
			}
		}`;
		const ref = await API.graphql(graphqlOperation(Query));
		
		if(ref.data.getSetting !== null){
			return ({
				item: {
					carriage_free_flag              : ref.data.getSetting.carriage_free_flag ? true : false,
					carriage_free_price             : ref.data.getSetting.carriage_free_price ? ref.data.getSetting.carriage_free_price : 0,
					carriage_free_ec_user_only_flag : ref.data.getSetting.carriage_free_ec_user_only_flag ? true : false,
					carriage_free_order_history_kbn : ref.data.getSetting.carriage_free_order_history_kbn ? ref.data.getSetting.carriage_free_order_history_kbn : '',
					carriage_1                      : ref.data.getSetting.carriage_1,
					carriage_2                      : ref.data.getSetting.carriage_2,
					carriage_3                      : ref.data.getSetting.carriage_3,
					carriage_4                      : ref.data.getSetting.carriage_4,
					carriage_5                      : ref.data.getSetting.carriage_5,
					cashdeli_1                      : ref.data.getSetting.cashdeli_1,
					cashdeli_2                      : ref.data.getSetting.cashdeli_2,
					wrapping_1                      : ref.data.getSetting.wrapping_1,
					date_diff                       : ref.data.getSetting.date_diff,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: '送料等のセッティングが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}



export { Setting };


