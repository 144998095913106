import { API, graphqlOperation } from 'aws-amplify';

const Profile = {};

Profile.Get = async function ()  {
		
	try{
		const Query = `query getProfile {
			getProfile {
				cognitoid
				email
				email_unverify
				name_1
				name_2
				name_furi_1
				name_furi_2
				zip_1
				zip_2
				pref
				addr_1
				addr_2
				addr_3
				tel_1
				tel_2
				tel_3
				birth_y
				birth_m
				birth_d
				sex
				mg_kbn_1
				mg_kbn_2
				mg_kbn_3
				not_email_kbn
				en_kbn
				date_add
				date_edit
			}
		}`;
		const ref = await API.graphql(graphqlOperation(Query));
		//console.log(ref);
		const item = ref.data.getProfile;
		
		const cognitoid     = item.cognitoid     ;
		const email         = item.email         ;
		const email_unverify = item.email_unverify;
		const name_1        = item.name_1        ;
		const name_2        = item.name_2        ;
		const name_furi_1   = item.name_furi_1   ;
		const name_furi_2   = item.name_furi_2   ;
		const zip_1         = item.zip_1         ;
		const zip_2         = item.zip_2         ;
		const pref          = item.pref          ;
		const addr_1        = item.addr_1        ;
		const addr_2        = item.addr_2        ;
		const addr_3        = item.addr_3        ;
		const tel_1         = item.tel_1         ;
		const tel_2         = item.tel_2         ;
		const tel_3         = item.tel_3         ;
		const birth_y       = item.birth_y       ;
		const birth_m       = item.birth_m       ;
		const birth_d       = item.birth_d       ;
		const sex           = item.sex           ;
		const mg_kbn_1      = item.mg_kbn_1      ;
		const mg_kbn_2      = item.mg_kbn_2      ;
		const mg_kbn_3      = item.mg_kbn_3      ;
		const not_email_kbn = item.not_email_kbn ;
		const en_kbn        = item.en_kbn        ;
		const date_add      = item.date_add      ;
		const date_edit     = item.date_edit     ;
		
		if(en_kbn){
			return ({
				item: {
					cognitoid     : cognitoid     ,
					email         : email         ,
					email_unverify : email_unverify,
					name_1        : name_1        ,
					name_2        : name_2        ,
					name_furi_1   : name_furi_1   ,
					name_furi_2   : name_furi_2   ,
					zip_1         : zip_1         ,
					zip_2         : zip_2         ,
					pref          : pref          ,
					addr_1        : addr_1        ,
					addr_2        : addr_2        ,
					addr_3        : addr_3        ,
					tel_1         : tel_1         ,
					tel_2         : tel_2         ,
					tel_3         : tel_3         ,
					birth_y       : birth_y       ,
					birth_m       : birth_m       ,
					birth_d       : birth_d       ,
					sex           : sex           ,
					mg_kbn_1      : mg_kbn_1      ,
					mg_kbn_2      : mg_kbn_2      ,
					mg_kbn_3      : mg_kbn_3      ,
					not_email_kbn : not_email_kbn ,
					en_kbn        : en_kbn        ,
					date_add      : date_add      ,
					date_edit     : date_edit     ,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: 'ユーザが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}

Profile.Add = async function (newData)  {

	try{

		const email         = newData.email;
		const name_1        = newData.name_1;
		const name_2        = newData.name_2;
		const name_furi_1   = newData.name_furi_1;
		const name_furi_2   = newData.name_furi_2;
		const zip_1         = newData.zip_1;
		const zip_2         = newData.zip_2;
		const pref          = newData.pref;
		const addr_1        = newData.addr_1;
		const addr_2        = newData.addr_2;
		const addr_3        = newData.addr_3 ? newData.addr_3 : 'null';
		const tel_1         = newData.tel_1;
		const tel_2         = newData.tel_2;
		const tel_3         = newData.tel_3;
		const birth_y       = newData.birth_y;
		const birth_m       = newData.birth_m;
		const birth_d       = newData.birth_d;
		const sex           = newData.sex;
		const mg_kbn_1      = newData.mg_kbn_1 ? true : false;

		//新規
		const Query = `mutation addProfile($data: ProfileInput!) {
			addProfile(input: $data) {
				cognitoid
			}
		}`;
		const Data = {
			data : {
				email       : email,
				name_1      : name_1       ,
				name_2      : name_2       ,
				name_furi_1 : name_furi_1  ,
				name_furi_2 : name_furi_2  ,
				zip_1       : zip_1        ,
				zip_2       : zip_2        ,
				pref        : pref         ,
				addr_1      : addr_1       ,
				addr_2      : addr_2       ,
				addr_3      : addr_3       ,
				tel_1       : tel_1        ,
				tel_2       : tel_2        ,
				tel_3       : tel_3        ,
				birth_y     : birth_y      ,
				birth_m     : birth_m      ,
				birth_d     : birth_d      ,
				sex         : sex          ,
				mg_kbn_1    : mg_kbn_1     ,
				en_kbn      : true         ,
			}
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const item = ref.data.addProfile;
		
		const cognitoid = item.cognitoid;

		return ({
			item  :{
				cognitoid : cognitoid,
			},
			error : null
		});
	}
	catch(error) {
		return({ error: error });
	}
}


Profile.Update = async function (newData)  {

	try{
		
		const email_unverify = newData.email_unverify;
		const name_1        = newData.name_1;
		const name_2        = newData.name_2;
		const name_furi_1   = newData.name_furi_1;
		const name_furi_2   = newData.name_furi_2;
		const zip_1         = newData.zip_1;
		const zip_2         = newData.zip_2;
		const pref          = newData.pref;
		const addr_1        = newData.addr_1;
		const addr_2        = newData.addr_2;
		const addr_3        = newData.addr_3 ? newData.addr_3 : 'null';
		const tel_1         = newData.tel_1;
		const tel_2         = newData.tel_2;
		const tel_3         = newData.tel_3;
		const birth_y       = newData.birth_y;
		const birth_m       = newData.birth_m;
		const birth_d       = newData.birth_d;
		const sex           = newData.sex;
		const mg_kbn_1      = newData.mg_kbn_1 ? true : false;
		
		//更新
		const Query = `mutation updateProfile($data: ProfileInput!) {
			updateProfile(input: $data) {
				cognitoid
			}
		}`;
		const Data = {
			data : {
				email_unverify : email_unverify,
				name_1      : name_1       ,
				name_2      : name_2       ,
				name_furi_1 : name_furi_1  ,
				name_furi_2 : name_furi_2  ,
				zip_1       : zip_1        ,
				zip_2       : zip_2        ,
				pref        : pref         ,
				addr_1      : addr_1       ,
				addr_2      : addr_2       ,
				addr_3      : addr_3       ,
				tel_1       : tel_1        ,
				tel_2       : tel_2        ,
				tel_3       : tel_3        ,
				birth_y     : birth_y      ,
				birth_m     : birth_m      ,
				birth_d     : birth_d      ,
				sex         : sex          ,
				mg_kbn_1    : mg_kbn_1     ,
			}
		};
		await API.graphql(graphqlOperation(Query, Data));

		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

Profile.EmailUpdate = async function (email, email_unverify)  {

	try{
		
		//更新
		const Query = `mutation updateProfile($data: ProfileInput!) {
			updateProfile(input: $data) {
				cognitoid
			}
		}`;
		const Data = {
			data : {
				email_unverify : email_unverify,
 				email       : email        ,
			}
		};
		await API.graphql(graphqlOperation(Query, Data));

		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

Profile.UnSub = async function (flag)  {

	try{
		
		//更新
		const Query = `mutation executeCognitoAdmin($flag: String) {
			executeCognitoAdmin(flag: $flag) {
				cognitoid
			}
		}`;
		const Data = { flag : flag };
		const ref = await API.graphql(graphqlOperation(Query, Data));
		console.log(ref.data.executeCognitoAdmin.cognitoid);

		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

export { Profile };


