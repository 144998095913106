import React from 'react';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class OthersMain extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/"><Image src="/svg/logo_header_cosme.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/">TOP</a></div>
						<div><a href={'/guide/'}>カテゴリ紹介一覧</a></div>

					</div>
				<h1>RECOMMEND<p>カテゴリ紹介一覧</p></h1>
</Container>

<Container>
	 <Row>
<Col>
カテゴリ詳細画面いろいろ
</Col>
</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersMain);
