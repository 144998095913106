import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './30th.css';
import Confetti from 'react-confetti';
//import EventListener from 'react-event-listener';
//import useWindowSize from '../@Include/useWindowSize';
//import GetItemInfo from '../GetItemInfo';


class Anniv30th extends React.Component {



	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							cognitoid      :  '',
							width:'',
							height:'',
						}
						this._getData     = this._getData.bind(this);
					}



					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}


	//-----------追加 200122-----------
	render() {
		//const { width, height } = useWindowSize();
		const confetti_style={			position:'fixed'		}
		return (


							<div className="anniv30th_wrap">

							<Confetti className="confetti" style={confetti_style} gravity={0.02} numberOfPieces={80} drawShape={ctx => { ctx.fillRect(0, 0, 10, 15);  }} />

							<Header/>
				<main className={this.state.cognitoid ? 'others anniv30th members' : 'others anniv30th'}>
<Container>
	 <Row>
		 	  <div className="mb-5">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/30th/30th_header2_sp.png" />
	 		<Image src="/img/30th/30th_header2_pc.png" alt="30th Anniversary Series" fluid />
	 	</picture>
	 </div>
		 </Row>
</Container>

<Container>
<div className="ranklist">
		<ul>
				<li><a href="/others/nyansuke/"><Image src="/img/30th/30th_charabnr01.png" alt="ごろごろにゃんすけ" /></a></li>
				<li><a href="/others/shibanban/"><Image src="/img/30th/30th_charabnr02.png" alt="しばんばん" /></a></li>
				<li><a href="/category/キャラクターから探す-ぴよこ豆/"><Image src="/img/30th/30th_charabnr03.png" alt="ぴよこ豆" /></a></li>
				<li><a href="/category/キャラクターから探す-ツンダちゃん/"><Image src="/img/30th/30th_charabnr04.png" alt="ツンダちゃん" /></a></li>
				<li><a href="/category/キャラクターから探す-うさぎのムーちゃん/"><Image src="/img/30th/30th_charabnr05.png" alt="うさぎのムーちゃん" /></a></li>
				<li><a href="/category/キャラクターから探す-ゆるあにまる"><Image src="/img/30th/30th_charabnr06.png" alt="ゆるあにまる" /></a></li>
				<li><a href="/category/キャラクターから探す-にゃばんばん/"><Image src="/img/30th/30th_charabnr07.png" alt="にゃばんばん" /></a></li>
				<li><a href="/category/キャラクターから探す-KIGURU%20ME/"><Image src="/img/30th/30th_charabnr08.png" alt="KIGURU-ME" /></a></li>
				<li><a href="/category/キャラクターから探す-ウサコレフレンズ/"><Image src="/img/30th/30th_charabnr09.png" alt="ウサコレフレンズ" /></a></li>
				<li><a href="/category/キャラクターから探す-PACOPiPi/"><Image src="/img/30th/30th_charabnr10.png" alt="PACOPiPi" /></a></li>
				<li><a href="/category/キャラクターから探す-ばいばいべあ/"><Image src="/img/30th/30th_charabnr11.png" alt="ばいばいべあ" /></a></li>
		</ul>
</div>
</Container>

 <Container>
			<Row>
	 		 <Col xs={12} sm={{ span: 6, offset: 3 }} ><a href="/category/キャラクターから探す-30thアニバーサリーシリーズ/" className="btn-blue w-100 btn btn-smp btn-lg mt-3 mb-5 pt-3 pb-3">30周年アニバーサリー商品一覧 <i className="fa fa-arrow-right"></i></a></Col>
	 	</Row>

		<Row>
		 <Col xs={12} sm={{ span: 6, offset: 3 }} ><a href="https://www.mindwave.co.jp/30th_vote/award.html" target="_blank" rel="noopener noreferrer" className="btn-pink w-100 btn btn-smp btn-lg mt-3 mb-5 pt-3 pb-3">キャラクター総選挙 結果発表を見る <i className="fa fa-external-link-alt"></i></a></Col>
	</Row>

</Container>





				</main>
									<Footer/>
										</div>
		);
	}
}

export default  withRouter(Anniv30th);
