import React from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements';

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.handleNonce          = this.handleNonce.bind(this);
		this.handleNonceError     = this.handleNonceError.bind(this);
	}
	handleNonce(token) {
		this.props.setCardNonce(token);
	}
	handleNonceError(err) {
		this.props.setCardNonce('', err);
	}
	async componentDidMount () {
		//
	}
	render() {
		return (
			<StripeProvider apiKey="pk_live_sAXeZ1a3kZKvKG78aIIoNhRM00s2umseTS">
				<Container fluid>
					<Row>
						<Col xs={12} md={{ span: 10, offset: 1 }} className="p-0">
							<Elements>
								<InjectedStripeForm handleNonce={this.handleNonce} handleNonceError={this.handleNonceError} {...this.props} />
							</Elements>
						</Col>
					</Row>
				</Container>
			</StripeProvider>
		);
	}
}


class StripeForm extends React.Component {
	constructor (props) {
		super(props);
		this.handleSubmit   = this.handleSubmit.bind(this);
	}
	async handleSubmit(e){
		e.preventDefault();
		try{
			const formObj     = e.currentTarget;
			const use_flag    = formObj.use_flag.checked ? true : false;
			//console.log(use_flag);
		
			const result = await this.props.stripe.createToken({currency: 'jpy', name: this.props.name_1 + ' ' + this.props.name_2});
			//console.log(result);
			
			//エラー確認
			try{
				const error = result.error.message;
				//console.log(error);
				if(error){
					this.props.handleNonceError(error);
					return(0);
				}
			}
			catch(err){
				//console.log(err);
			}
			
			//エラー確認
			try{
				
				//トークン取得
				const token = result.token;
				//console.log(token);
				if(token){

					//通常使うフラグを追加
					token.use_flag = use_flag;
					
					//カードの登録へ
					this.props.handleNonce(token);
				}
				else{
					this.props.handleNonceError('クレジットカード情報の登録に失敗しました。');
				}
			}
			catch(err){
				//console.log(err);
			}
		}
		catch(err){
			//console.log(err);
			this.props.handleNonceError(err);
		}
	}
	render() {
		const styleData = {
					base: {
						fontSize: '16px',
						color: '#373F4A',
						fontFamily: 'Helvetica Neue',
						'::placeholder': {
							color: '#BDBFBF',
						},
					},
				};
		return (
			<Form onSubmit={this.handleSubmit}>
				<div style={{margin: '30px 0px 30px', border: '2px solid silver', borderRadius:'0.5em', padding: '10px 5px'}}>
					<CardElement style={styleData} />
				</div>
				<div style={{fontSize:'0.8em'}}><p>※セキュリティコード（CVC）は入力欄をクリック・タップすると入力できます。</p></div>
				<div style={{margin: '0px 0px 10px', visibility: this.props.isUser ? 'visible' : 'hidden'}}>
					<Form.Check type="checkbox" name="use_flag" defaultValue={true} label="通常使うカードに設定する" />
				</div>
				<Button type="submit" className="btn-blue mb-4">登録する</Button>
			</Form>
		);
	}
}

const InjectedStripeForm = injectStripe(StripeForm);

export default withRouter(Default);
