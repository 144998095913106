import React from 'react';
import { Auth } from 'aws-amplify';
import { API } from '../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from './@Include/NotFound';
//import Header from './@Include/Header';
//import Footer from './@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			IdentityId     : '',
			cognitoid      : '',
		}
		this.handleReload         = this.handleReload.bind(this);
		this._getData             = this._getData.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//Cognito IDの取得
			const currentCredentials = await Auth.currentCredentials();
			const IdentityId         = currentCredentials.data.IdentityId;

			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			const ref = await API.Profile.Get();
			console.log(ref);

			this.setState({
				isLoading     : false,
				IdentityId    : IdentityId,
				cognitoid     : cognitoid,
			});
		}
		catch(error) {
			this.setState({
				isLoading     : false,
			});
			return(0);
		}
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
								<div>
				{/*		<Header/>*/}
					<main className="index-main">
 <Container>
<h1 className="sogo-logo"><Image src="/svg/logo_header.svg" /></h1>

{/*

<div className="mt-3">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202308/bnr_free2308_sp.jpg" />
 <Image src="/img/202308/bnr_free2308.jpg" alt="送料無料キャンペーン" fluid className="bdrimg" />
</picture>
</div>

<div className="mt-5 pt-5">
<a href="https://images.mindwave-store.com/mz/questionnaire/nenga_present2023_info.html" className="opa06" target="_blank" rel="noopener noreferrer">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202310/bnr_nenga2024_sp.jpg" />
 <Image src="/img/202310/bnr_nenga2024.jpg" alt="キャンペーン" fluid />
</picture></a>
</div>


<div className="mt-5">
<a href="/search/2024年の新春福袋/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/fukubukuro/bnr_hb2024-02_sp.jpg" />
	 <Image src="/img/fukubukuro/bnr_hb2024-02.jpg" alt="福袋" fluid className="bdrimg" />
 </picture></a>
</div>
*/}
{/* <div className="mt-5">
	<picture>
		<source media="(max-width: 767px)" srcSet="/img/202405/bnr_nuicon2405_sp.jpg" />
			<Image src="/img/202405/bnr_nuicon2405.jpg" alt="コンテスト開催" fluid className="bdrimg"/>
		</picture>
		<div className="mt-2 pb-1">
	<ul className="flexbox-start list-2">
 <li><a href="https://www.instagram.com/p/C7DORq9KO4V/" className="btn-cosme w-100 btn">Instagram</a></li>
 <li><a href="https://x.com/mindwaveinc/status/1791262346113147058" className="btn-cosme w-100 btn">X</a></li>
 </ul>
 <ul className="flexbox-start list-3">
 <li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-ぬいぐるみ/" className="btn-cosme w-100 btn">にゃんすけ対象商品</a></li>
 <li><a href="/category/キャラクターから探す-しばんばん-ぬいぐるみ/" className="btn-cosme w-100 btn">しばんばん対象商品</a></li>
 <li><a href="/category/キャラクターから探す-ぴよこ豆-ぬいぐるみ/" className="btn-cosme w-100 btn">ぴよこ豆対象商品</a></li>
 </ul>
		 
	</div>
</div>

<div className="mt-3">
<a href="https://images.mindwave-store.com/mz/ncolumn/cm/2408/cm_240830.html" className="opa06" target="_blank" rel="noopener noreferrer">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202407/bnr_pack2407_sp.jpg" />
 <Image src="/img/202407/bnr_pack2407.jpg" alt="キャンペーン" fluid />
</picture></a>
</div>
<div className="mt-3">
		<a href="/search/◆あわせ買い対象◆/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202409/bnr_awase2409_sp.jpg" />
		 <Image src="/img/202409/bnr_awase2409.jpg" alt="キャンペーン" className="bdrimg" fluid />
	 </picture></a> </div> 
<div className="mt-3">
		<a href="/search/ごろごろにゃんすけ%2010th%20ANNIVERSARY/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202403/bnr_nyansuke10th_sp.jpg" />
		 <Image src="/img/202403/bnr_nyansuke10th.jpg" alt="ごろごろにゃんすけ" className="bdrimg" fluid />
	 </picture></a> </div>
<div className="mt-5">
		<a href="/search/◆あわせ買い対象◆/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202410/bnr_awase2410_sp.jpg" />
		 <Image src="/img/202410/bnr_awase2410.jpg" alt="キャンペーン" className="bdrimg" fluid />
	 </picture></a> </div>
<div className="mt-5 pt-5">
<a href="/others/fukubukuro/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202411/bnr_hb2025_sp.jpg" />
	 <Image src="/img/202411/bnr_hb2025.jpg" alt="福袋" fluid className="bdrimg" />
 </picture></a>
</div>*/}
<div className="mt-5">
	<a href="/search/ウィンターセール/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202411/bnr_sale2411_sp.jpg" />
	 <Image src="/img/202411/bnr_sale2411.jpg" alt="SALE" fluid className="bdrimg" />
 </picture></a>
 <div className="mt-2 pb-1">
 <ul className="flexbox-start list-4">
 <li><a href="/search/ウィンターセール-シール/" className="btn-red w-100 btn">シール</a></li>
 <li><a href="/search/ウィンターセール-テープ/" className="btn-red w-100 btn">テープ</a></li>
 <li><a href="/search/ウィンターセール-ふせん/" className="btn-red w-100 btn">ふせん</a></li>
 <li><a href="/search/ウィンターセール-紙文具/" className="btn-red w-100 btn">紙文具</a></li>
 <li><a href="/search/ウィンターセール-文具小物/" className="btn-red w-100 btn">文具小物</a></li>
 <li><a href="/search/ウィンターセール-筆記用具/" className="btn-red w-100 btn">筆記用具</a></li>
 <li><a href="/search/ウィンターセール-鉛筆・消しゴム/" className="btn-red w-100 btn">鉛筆・消しゴム</a></li>
 <li><a href="/search/ウィンターセール-雑貨/" className="btn-red w-100 btn">雑貨</a></li></ul>
 <ul className="flexbox-start list-3">
 <li><a href="/search/ウィンターセール-20％OFF/" className="btn-red w-100 btn">20％OFF</a></li>
 <li><a href="/search/ウィンターセール-30％OFF/" className="btn-red w-100 btn">30％OFF</a></li>
 <li><a href="/search/ウィンターセール-40％OFF/" className="btn-red w-100 btn">40％OFF</a></li>
 </ul>
</div>
 </div>
<div className="mt-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/top/bnr_sticker_sp.jpg" />
	 <Image src="/img/top/bnr_sticker.jpg" alt="書き下ろしステッカープレゼント！" fluid />
 </picture>
</div>




{/*<div className="mt-3">
<picture>
<source media="(max-width: 767px)" srcSet="/img/info/info_holidays_sp.jpg" />
	 <Image src="/img/info/info_holidays.jpg" alt="休業のご案内" fluid />
</picture>
</div>


<div className="mt-3">
			 <picture>
			<source media="(max-width: 767px)" srcSet="/img/guide/img_wrapping-xmas_sp.png" />
				 <Image src="/img/guide/img_wrapping-xmas.png" alt="季節限定ラッピング" className="bdrimg" fluid/>
			 </picture>
</div>
 
<div className="mt-3">
 <picture>
<source media="(max-width: 767px)" srcSet="/img/guide/img_vtwrap01_sp.jpg" />
	<Image src="/img/guide/img_vtwrap01_pc.jpg" alt="季節限定ラッピング" fluid className="bdrimg"/>
</picture>
</div>
*/}

{/*ここに休業案内 */} 
<div className="my-3">
<picture>
<source media="(max-width: 767px)" srcSet="/img/info/info_holidays_sp.jpg" />
	 <Image src="/img/info/info_holidays.jpg" alt="休業のご案内" fluid />
</picture>
</div>

</Container>


 <Container>
      <Row>
				<Col sm={12} md={6} className="pt-5 pb-5">
					<h2><a href="/stationary_index/" className="sogo_ttl"><Image src="/img/sogo_ttl_sta.svg" alt="文具ストアTOP" /></a></h2>

	<p className="mt-5"><a href="/stationary_index/" className="btn btn-blue w-100 btn-lg"  size="lg">文具ストアTOPへ <i className="fa fa-arrow-right"></i></a></p>
		{/*<p><a href="/search/2020年　GWセール/" className="opa06"><Image src="/img/202004/bnr_gw-sale_sp.jpg" fluid /></a></p>

		<p><a href="/search/ぴよこ豆ステーショナリー/" className="opa06"><Image src="/img/201912/bnr_191213_01.jpg" fluid /></a></p>*/}
		<p><a href="/others/30th/" className="opa06"><Image src="/img/top/bnr_30th_sp.jpg" alt="30周年特設ページ" fluid /></a></p>
		{/*<p><a href="/others/nyansuke/" className="opa06"><Image src="/img/202002/bnr_lp-nyansuke_sp.jpg" alt="ごろごろにゃんすけ" fluid /></a></p>
		<p><a href="/others/shibanban/" className="opa06"><Image src="/img/202002/bnr_lp-banban_sp.jpg" alt="しばんばん" fluid /></a></p>
		<p><a href="/category/キャラクターから探す-ぴよこ豆/" className="opa06"><Image src="/img/202005/bnr_piyoko03.jpg" alt="ぴよこ豆新商品はこちら" fluid className="bdrimg" /></a></p>*/}
		<ul className="sogo_sns">
		<li><a href="/others/nyansuke/" className="opa06"><Image src="/img/top/bnr_lp-nyansuke_sp.jpg" alt="ごろごろにゃんすけ" fluid /></a></li>
		<li><a href="/others/shibanban/" className="opa06"><Image src="/img/top/bnr_lp-banban_sp.jpg" alt="しばんばん" fluid /></a></li>
		<li><a href="/others/piyokomame/" className="opa06"><Image src="/img/top/bnr_lp-piyoko_sp.jpg" alt="ぴよこ豆" fluid /></a></li>
			<li><a href="/category/キャラクターから探す-うさぎのムーちゃん/" className="opa06"><Image src="/img/top/bnr_lp-muchan_sp.jpg" alt="うさぎのムーちゃん" fluid /></a></li></ul>

<h3 className="maskingtape mt-5 pt-5">話題の新商品</h3>
				<ul className="sogo_sns">
				<li><a href="/category/アイテムから探す-ランチ/" className="opa06"><Image src="/img/slider/bnr_lunch.jpg" fluid /></a></li>
				<li><a href="/others/bine/" className="opa06"><Image src="/img/slider/bnr_bine.jpg" fluid /></a></li>
				<li><a href="/search/スーパー銭湯ぴよこ豆/" className="opa06"><Image src="/img/slider/bnr_piyoko-sento.jpg" fluid /></a></li>
				
				<li><a href="/category/キャラクターから探す-BABYシリーズ/" className="opa06"><Image src="/img/slider/bnr_babysrs.jpg" fluid /></a></li>
				<li><a href="/search/アクリルスタンド/" className="opa06"><Image src="/img/slider/bnr_as.jpg" fluid /></a></li>
				<li><a href="/search/スタンドスティックマーカー%E3%80%80おしりシリーズ/" className="opa06"><Image src="/img/slider/bnr_oshiri.jpg" fluid /></a></li>
				<li><a href="/search/アクリルキーホルダー/" className="opa06"><Image src="/img/slider/bnr_ak2402.jpg" fluid /></a></li>
				
					{/*
					<li><a href="/search/マルチ収納ボックス/" className="opa06"><Image src="/img/slider/bnr_storagebox.jpg" fluid /></a></li>
					<li><a href="/others/mobilebattery/#mobilebattery" className="opa06"><Image src="/img/mobilebattery/bnr_mb_s.jpg" fluid /></a></li>
					<li><a href="/search//オンラインス/" className="opa06"><Image src="/img/slider/bnr_lifeful.jpg" fluid /></a></li><li><a href="/search/ラバーマスコット/" className="opa06"><Image src="/img/202009/bnr_rubberm.jpg" fluid /></a></li>
					<li><a href="/search/クルーソックス/" className="opa06"><Image src="/img/202005/bnr_socks.jpg" fluid /></a></li>
				<li><a href="/category/アイテムから探す-限定商品-雑貨小物/" className="opa06"><Image src="/img/202009/bnr_gm-case2009.jpg" fluid /></a></li><li><a href="/search/ごろごろにゃんすけ%20ぬいぐるみマスコット%20ミニ/" className="opa06"><Image src="/img/202004/bnr_msct-mini.jpg" fluid /></a></li>
					<li><a href="/category/アイテムから探す-布製品-carariシリーズ/" className="opa06"><Image src="/img/202005/bnr_carari.jpg" fluid /></a></li>
					<li><a href="/search/ちぎロール/" className="opa06"><Image src="/img/202004/bnr_chigi02.jpg" fluid /></a></li>*/}
					
					
					<li><a href="/others/kotorimachi/" className="opa06"><Image src="/img/slider/bnr_kotori2105.jpg" fluid /></a></li>
					<li><a href="/search/月替わりステッカー/" className="opa06"><Image src="/img/slider/bnr_ms2105.jpg" fluid /></a></li>
					
					<li><a href="/others/mobileitem/" className="opa06"><Image src="/img/slider/slider_mobileitem.jpg" fluid /></a></li>
					{/*
					<li><a href="/search/ぺたロール/" className="opa06"><Image src="/img/202004/bnr_peta.jpg" fluid /></a></li>
					<li><a href="/category/キャラクターから探す-ようこそかわうそ/" className="opa06"><Image src="/img/202007/bnr_kawauso.jpg" fluid /></a></li>
					<li><a href="/search/チョイシー ステッカー/" className="opa06"><Image src="/img/202003/bnr_choicy.jpg" fluid /></a></li>
					<li><a href="/search/オレたち最強ズ/" className="opa06"><Image src="/img/202002/bnr_saikyo.jpg" fluid /></a></li>
					<li><a href="/search/マイルドライナー/" className="opa06"><Image src="/img/202001/bnr_mildliner.jpg" fluid /></a></li>
					<li><a href="/search/スポーツテーマ/" className="opa06"><Image src="/img/201911/bnr_191129_01.jpg" fluid thumbnail /></a></li>
					<li><a href="/search/フォトシリーズ27 A5綴じノート/" className="opa06"><Image src="/img/201911/bnr_191122_01.jpg" fluid /></a></li>
					<li><a href="/search/JUICE STAND/" className="opa06"><Image src="/img/202004/bnr_juicestand.jpg" fluid /></a></li>
					<li><a href="/search/ラバーマスコット%E3%80%80立体マスコット/" className="opa06"><Image src="/img/201911/bnr_191115_02.jpg" fluid /></a></li>*/}
				</ul>
				<h3 className="maskingtape mt-5 pt-5">こちらも注目!!</h3>
				<Container>
	 <Row>
	 <Col xs={6} sm={6} className="mb-3"><a href="/search/OUTLET/"><Image src="/img/top/outletsale.jpg" alt="outlet sale" fluid className="bdrimg" /></a></Col>
	 <Col xs={6} sm={6} className="mb-3"><a href="/category/アイテムから探す-シール-他フレークシール/"><Image src="/img/top/topcate_flake2024.jpg" alt="フレークシール" fluid className="bdrimg" /></a></Col>
	 <Col xs={6} sm={6} className="mb-3"><a href="/category/アイテムから探す-付箋/"><Image src="/img/top/topcate_husen2024.jpg" alt="付箋" fluid className="bdrimg" /></a></Col>
	 <Col xs={6} sm={6} className="mb-3"><a href="/category/アイテムから探す-雑貨-ぬいぐるみ/"><Image src="/img/top/topcate_nuigurumi2024.jpg" fluid className="bdrimg" /></a></Col>

	 </Row>
 </Container>

					{/*<h3 className="maskingtape mt-5 pt-5">2020年 この時期におすすめの新商品</h3>
					 <Container>
			<Row>
			<Col xs={4} sm={4}><Row><a href="/search/サロン ド フルール/"><Image src="/img/202001/bnr_0109_1.png" className="p-1" fluid /></a>	</Row></Col>
			<Col xs={4} sm={4}><Row><a href="/search/花ごころシリーズ/"><Image src="/img/201910/bnr_winter02.jpg" alt="花ごころ" className="p-1" fluid /></a></Row></Col>
			<Col xs={4} sm={4}><Row><a href="/search/桜ごころシリーズ/"><Image src="/img/201910/bnr_winter03.jpg" alt="桜ごころ" className="p-1" fluid /></a></Row></Col>
			<Col xs={4} sm={4}><Row><a href="/search/2019年09月始まり/"><Image src="/img/202001/bnr_0109_3.png" fluid className="p-1" /></a></Row></Col>
			<Col xs={4} sm={4}><Row><a href="/category/アイテムから探す-シール-スケジュールシール/"><Image src="/img/201911/bnr_winter08.jpg" className="p-1" fluid /></a></Row></Col>
			<Col xs={4} sm={4}><Row><a href="/search/2020年02月始まり%20B6マンスリー%20しばんばんとごろごろにゃんすけ/"><Image src="/img/202001/bnr_0109_2.png" className="p-1" fluid /></a></Row></Col>
			</Row>
		</Container>*/}

									<p className="mt-5"><a href="/stationary_index/" className="btn btn-blue w-100 btn-lg"  size="lg">文具ストアTOPへ <i className="fa fa-arrow-right"></i></a></p>
				</Col>


				<Col sm={12} md={6} className="pt-5 pb-5">
					<h2><a href="https://cosme.mindwave-store.com/" className="sogo_ttl"><Image src="/img/sogo_ttl_cos.svg" alt="コスメストアTOP" /></a></h2>
				<p><a href="https://cosme.mindwave-store.com/" className="btn btn-pink w-100 btn-lg"  size="lg">コスメストアTOPへ <i className="fa fa-arrow-right"></i></a></p>

				<p><a href="https://cosme.mindwave-store.com/category/フルプルコスメ-フルプルバスソルト/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/slider/bnr_bathsalt2109.jpg" fluid /></a></p>
		
		{/*<p><a href="https://cosme.mindwave-store.com/search/贅沢美肌堂/" className="opa06"><Image src="/img/202003/bnr_matcha-set_c.jpg" fluid /></a></p>
		<p><a href="https://cosme.mindwave-store.com/category/キャラクターから探す-すみっコぐらし/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/202008/bnr_fp-sumikko.jpg" fluid /></a></p>
		<p><a href="https://cosme.mindwave-store.com/search/【10月30日までの期間限定】/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/202009/bnr_gift2009_c.jpg" fluid /></a></p>
		<p><Image src="/img/202007/bnr_matcha-cp_sp.jpg" fluid /></p>
		<p><a href="https://cosme.mindwave-store.com/search/贅沢美肌堂/" className="opa06"><Image src="/img/202007/bnr_matcha-set2007.jpg" fluid /></a></p>
	 <p><a href="https://cosme.mindwave-store.com/search/すみっコぐらし フルプルクリーム/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/top/bnr_furupuru_sumikko.jpg" fluid /></a></p>
	 <p><a href="https://cosme.mindwave-store.com/item/CM191201/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/top/bnr_bathsoltset2.jpg" fluid /></a></p>
	 <p><a href="https://cosme.mindwave-store.com/category/フルプルコスメ-フルプルハンド&ネイルクリーム/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/top/bnr_hand02.jpg" fluid /></a></p>*/}
	 


					<Col className="bg_gray pb-5 mb-3">
					<h3 className="maskingtape pt-5">オリジナルギフトセット10%OFF！</h3>
					<Row className="top_setitem mt-3">
					{/* <Col xs={12}><a href="https://cosme.mindwave-store.com/item/CA211001/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA211001.jpg" fluid className="my-3" /><h5 className="list-title">バスソルト6種の湯めぐりセット A</h5></a></Col> */}
<Col xs={12}><a href="https://cosme.mindwave-store.com/item/CA230901/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA230901.jpg" fluid className="my-3" /><h5 className="list-title">バスソルト6種の湯めぐりセット B</h5></a></Col>
{/* <Col xs={12}><a href="https://cosme.mindwave-store.com/item/CA210206/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210206.jpg" fluid className="my-3" /><h5 className="list-title">KIGURU・ME保湿＋マスキングテープセット</h5></a></Col>


<Col xs={6}><a href="https://cosme.mindwave-store.com/item/CA210204/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210204.jpg" fluid /><h5 className="list-title">にゃんすけ保湿＋マスキングテープセット</h5></a></Col>
<Col xs={6}><a href="https://cosme.mindwave-store.com/item/CA210208/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210208.jpg" fluid /><h5 className="list-title">にゃんすけとフルプルクリームセット</h5></a></Col>
<Col xs={6}><a href="https://cosme.mindwave-store.com/item/CA210209/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210209.jpg" fluid /><h5 className="list-title">にゃんすけ 癒しのマスコット&フルプルセット</h5></a></Col>*/}
<Col xs={12}><a href="https://cosme.mindwave-store.com/item/CA230303/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA230303.jpg" fluid className="my-3" /><h5 className="list-title">くろしばんばん 癒しのぬいぐるみ&バスソルトセット</h5></a></Col>
					
						{/*<Col xs={6}><a href="https://cosme.mindwave-store.com/item/CA210205/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210205.jpg" fluid /><h5 className="list-title">しばんばん保湿＋マスキングテープセット</h5></a></Col>
						<Col xs={6}><a href="https://cosme.mindwave-store.com/item/CA210202/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210202.jpg" fluid /><h5 className="list-title">3DAYSしばんばんフェイスマスクセット</h5></a></Col>
<Col xs={6}><a href="https://cosme.mindwave-store.com/item/CA210203/"><Image src="https://cosme.mindwave-store.com/img/set/bnr_CA210203.jpg" fluid /><h5 className="list-title"> 6DAYS集中!フェイスマスクセットト</h5></a></Col>

						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CA191003/"><Image src="https://cosme.mindwave-store.com/img/cosme/gift35.jpg" fluid /><h5 className="list-title">3DAYSしばんばんフェイスマスクセット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CA191009/"><Image src="https://cosme.mindwave-store.com/img/cosme/gift34.jpg" fluid /><h5 className="list-title">6DAYS集中！フェイスマスクセット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CA190406/"><Image src="https://cosme.mindwave-store.com/img/cosme/gift06.jpg" fluid /><h5 className="list-title">バスソルト6種の湯めぐりセット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CA191007/"><Image src="https://cosme.mindwave-store.com/img/cosme/gift05.jpg" fluid /><h5 className="list-title">しばんばんしっかり保湿コンプリートセット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CA191008/"><Image src="https://cosme.mindwave-store.com/img/cosme/gift33.jpg" fluid /><h5 className="list-title">にゃんすけしっかり保湿コンプリートセット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CA191002/"><Image src="https://cosme.mindwave-store.com/img/cosme/gift32.jpg" fluid /><h5 className="list-title">デリシャススターズ保湿セット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CM190501/"><Image src="/img/202001/gift15_02.jpg" fluid /><h5 className="list-title">にゃんすけ香りの魔法コロンギフト</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CM190601/"><Image src="/img/202001/gift16_02.jpg" fluid /><h5 className="list-title">にゃんすけの手ぬぐい＆フルプルクリームセット</h5></a></Col>
						<Col xs={4}><a href="https://cosme.mindwave-store.com/item/CM190701/"><Image src="/img/202001/gift18_02.jpg" fluid /><h5 className="list-title">しばんばん癒しのぬいぐるみ&バスソルトセット</h5></a></Col>*/}
						</Row>
</Col>{/*
<p><a href="https://cosme.mindwave-store.com/others/zeitakubihadado" className="opa06"><Image src="https://cosme.mindwave-store.com/img/top/bnr_maccha_one_1225.jpg" fluid /></a></p>
    <Row className="pt-5">
 							<Col xs={12} ><a href="https://cosme.mindwave-store.com/item/CM190801/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/cosme/gift19_03.jpg" rounded alt="しばんばん しっかり潤い補給セット" fluid /></a></Col>
 							<Col xs={12} ><a href="https://cosme.mindwave-store.com/item/CM190701/" className="opa06"><Image src="https://cosme.mindwave-store.com/img/cosme/gift1907_2.jpg" rounded alt="しばんばん 癒しのぬいぐるみ&バスソルトセット" fluid /></a></Col>
       </Row>
	 <p><a href="https://cosme.mindwave-store.com/others/zeitakubihadado" className="opa06"><Image src="https://cosme.mindwave-store.com/img/top/bnr_maccha_one.jpg" fluid /></a></p>*/}
	<p className="mt-5"><a href="https://cosme.mindwave-store.com/" className="btn btn-pink w-100 btn-lg"  size="lg">コスメストアTOPへ <i className="fa fa-arrow-right"></i></a></p>
		 </Col>
      </Row>

 </Container>
 {/*<Container className="my-3">
	<a href="/search/◆ウィンターギフト◆/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202111/bnr_gift2111_sp.jpg" />
	 <Image src="/img/202111/bnr_gift2111.jpg" alt="GIFT SET" fluid />
 </picture></a>
 </Container>
 <Container className="my-3">
 <picture>
<source media="(max-width: 767px)" srcSet="/img/guide/img_wrapping-xmas_sp.png" />
	<Image src="/img/guide/img_wrapping-xmas.png" alt="季節限定ラッピング" fluid className="bdrimg"/>
</picture>
</Container>

 <Container  className="mt-3 mb-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/top/bnr_meisai_sp.jpg" />
	 <Image src="/img/top/bnr_meisai.jpg" alt="ご案内" fluid />
</picture>
</Container>

  <Container className="mt-3 mb-5">
	<a href="/search/すみっコぐらし ギフトセット/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202104/bnr_mgift2104_sp.jpg" />
	 <Image src="/img/202104/bnr_mgift2104.jpg" alt="ギフトセット" fluid />
 </picture></a>
 </Container>
 <Container className="mt-3 mb-5">
  <picture>
 <source media="(max-width: 767px)" srcSet="/img/202009/img_wrapping-bk_sp.png" />
 	 <Image src="/img/202009/img_wrapping-bk.png" alt="季節限定ラッピング" fluid/>
  </picture>
 </Container>*/}
 {/*<Container className="mt-3 mb-5">
 <a href="/search/◆2020春のお楽しみパック◆/" className="opa06">
  <picture>
 <source media="(max-width: 767px)" srcSet="/img/202003/bnr_spring-pack_sp.jpg" />
 		<Image src="/img/202003/bnr_spring-pack.jpg" alt="2020春のお楽しみパック" fluid />
 	</picture></a>
 </Container>
 <Container className="my-5">
 <a href="/search/【12月25日までの期間限定】/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/202011/bnr_gift2011_sp.jpg" />
	 <Image src="/img/202011/bnr_gift2011.jpg" alt="SALE" fluid />
 </picture></a>
 </Container>
 <Container className="mb-3">
		<a href="/search/スーパー銭湯ぴよこ豆/" className="opa06">
	<picture>
	<source media="(max-width: 767px)" srcSet="/img/202402/bnr_piyoko-sento2024_sp.jpg" />
		 <Image src="/img/202402/bnr_piyoko-sento2024.jpg" alt="スーパー銭湯ぴよこ豆" fluid />
	 </picture></a> </Container>*/}
 <Container className="mb-5">
<a href="/guide/#wrapping" className="opa06">
			 <picture>
			<source media="(max-width: 767px)" srcSet="/img/guide/img_card_sp.jpg" />
				 <Image src="/img/guide/img_card.jpg" alt="ラッピング" className="bdrimg" fluid/>
			 </picture>
</a>
</Container>



					</main>
								{/*			<Footer/>*/}
											</div>
			);
		}
	}
}

export default withRouter(Default);
