import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Badge,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../@Include/NotFound';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
import SiteFlag from '../@Include/SiteFlag';
import NoWrapping from '../@Include/NoWrapping'; //追加khnum 200416

class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isLockForm     : false,
			isShowModal    : false,
			ErrMsg         : '',
			code           : '',
			name           : '',
			m_code         : '',
			m_name         : '',
			price          : '',
			price_notax    : '',
			piece_type     : '',
			title          : '',
			text_1         : '',
			text_2         : '',
			text_3         : '',
			text_4         : '',
			text_5         : '',
			select_1       : '',
			select_2       : '',
			select_3       : '',
			zaiko          : '',
			zaiko_flag     : '',
			flag_1         : '',
			flag_2         : '',
			flag_3         : '',
			flag_4         : '',
			flag_5         : '',
			flag_6         : '',
			flag_7         : '',
			flag_8         : '',
			flag_9         : '',
			en_kbn         : '',
			date_add       : '',
			date_edit      : '',
			isWishlist    : false,//追加khnum 200218
		}
		this.handleReload     = this.handleReload.bind(this);
		this.cartAdd          = this.cartAdd.bind(this);
		this.wishlistAdd      = this.wishlistAdd.bind(this);
		this.wishlistRemove      = this.wishlistRemove.bind(this);
		this._getData         = this._getData.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.handleClose      = this.handleClose.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//IDの取得
			let code = this.props.match.params.code;

			//↓↓↓↓↓ 追加khnum 200218 ↓↓↓↓
			//お気に入りリストの取得
			const refWishList = await API.WishList.Get();

			let wishlistDatas=refWishList.items;

			wishlistDatas.filter(wishlistData=> {
			if(wishlistData.code === code){
					this.setState({
						isWishlist  : true,
					});
					return(0);
				}else{
					return(0);
				}
			});

			//console.log(this.state.isWishlist);
			//↑↑↑↑↑ 追加khnum 200218 ↑↑↑↑

			if(!code){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
			}

			//2023年新年福袋対応
			//2024年も同対応 以下芦田商品ID差し替え
			//該当の商品IDの場合はNotFoundにする。
			if(
				code === 'F2024_1000A' ||
				code === 'F2024_1000B' ||
				code === 'F2024_3000A' ||
				code === 'F2024_3000B' ||
				code === 'F2024_3000C' ||
				code === 'F2024_3000D' ||
				code === 'F2024_5000A' ||
				code === 'F2024_5000B' ||
				code === 'F2024_5000C' ||
				code === 'F2024_10000A' ||
				code === 'F2024_10000B'
			)
			{
				throw new Error('商品が見つかりません。');
			}
			//代わりに対応する長い文字列が指定された場合は該当の商品IDに差し替える
			switch (code) {
				case 'fqoom3c0-iukdfsz92vakgove-ekdnlnn3ch':
					code = 'F2024_1000A';
					break;
				case 'd5nhhtqh-66v1zpo7loweutus-oypdrq0nyw':
					code = 'F2024_1000B';
					break;
				case '3gd8cs2u-u9ctw67zwgco2klp-25s2qxt34h':
					code = 'F2024_3000A';
					break;
				case 'su938tv8-mrmv69imqktn4cxo-h87zl0r5gj':
					code = 'F2024_3000B';
					break;
				case 'hy1mk7w5-q0ycfd1giehpxsye-b1pms8rr0s':
					code = 'F2024_3000C';
					break;
				case 'ivhgoowt-xj7czx7oiss86hq5-ezjb8a1hxf':
					code = 'F2024_3000D';
					break;
				case 'gk63n5t0-823ab21lq3vbip2t-bb2ooo5sbu':
					code = 'F2024_5000A';
					break;
				case '08z2ovn3-3bc8jne5vke76ipt-db08kn7v6f':
					code = 'F2024_5000B';
					break;
				case 'jh2mvc9h-nn7lg7mnta6zc5ho-sv44ryh6k0':
					code = 'F2024_5000C';
					break;
				case '37p1iisa-bcwyd4p56ukepqnd-hxyswt4ypt':
					code = 'F2024_10000A';
					break;
				case 'yqp9pygc-ebrsb8um0kyg5vrz-cchnm67k6n':
					code = 'F2024_10000B';
					break;
				default:
					console.log('ok');
			}

			//商品データ取得
			const ref = await API.Item.Get(SiteFlag.en, code);

			if(ref.error === null){
				const name        = ref.item.name;
				const m_code      = ref.item.m_code;
				const m_name      = ref.item.m_name;
				const price       = ref.item.price;
				const price_notax = ref.item.price_notax;
				const piece_type  = ref.item.piece_type;
				const title       = ref.item.title;
				const text_1      = ref.item.text_1;
				const text_2      = ref.item.text_2;
				const text_3      = ref.item.text_3;
				const text_4      = ref.item.text_4;
				const text_5      = ref.item.text_5;
				const select_1    = ref.item.select_1;
				const select_2    = ref.item.select_2;
				const select_3    = ref.item.select_3;
				const zaiko       = ref.item.zaiko;
				const zaiko_flag  = ref.item.zaiko_flag;
				const flag_1      = ref.item.flag_1;
				const flag_2      = ref.item.flag_2;
				const flag_3      = ref.item.flag_3;
				const flag_4      = ref.item.flag_4;
				const flag_5      = ref.item.flag_5;
				const flag_6      = ref.item.flag_6;
				const flag_7      = ref.item.flag_7;
				const flag_8      = ref.item.flag_8;
				const flag_9      = ref.item.flag_9;
				const en_kbn      = ref.item.en_kbn;
				const date_add    = ref.item.date_add;
				const date_edit   = ref.item.date_edit;

				//最近見た商品
				await API.RecentlyViewed.Add(code, name);

				this.setState({
					isLoading      : false,
					code           : code        ,
					name           : name        ,
					m_code         : m_code      ,
					m_name         : m_name      ,
					price          : price       ,
					price_notax    : price_notax ,
					piece_type     : piece_type  ,
					title          : title       ,
					text_1         : text_1      ,
					text_2         : text_2      ,
					text_3         : text_3      ,
					text_4         : text_4      ,
					text_5         : text_5      ,
					select_1       : select_1    ,
					select_2       : select_2    ,
					select_3       : select_3    ,
					zaiko          : zaiko       ,
					zaiko_flag     : zaiko_flag  ,
					flag_1         : flag_1      ,
					flag_2         : flag_2      ,
					flag_3         : flag_3      ,
					flag_4         : flag_4      ,
					flag_5         : flag_5      ,
					flag_6         : flag_6      ,
					flag_7         : flag_7      ,
					flag_8         : flag_8      ,
					flag_9         : flag_9      ,
					en_kbn         : en_kbn      ,
					date_add       : date_add    ,
					date_edit      : date_edit   ,
				});
			}
			else{
				//エラー処理
				const error = ref.error;
				console.log(error);
				if(error === '商品が見つかりません。'){
					this.setState({
						isLoading   : false,
						isNotFound  : true,
					});
					return(0);
				}
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				console.log(err_msg);
				this.setState({
					//ErrMsg     : err_msg + '<br/>',
					isLoading  : false,
					isNotFound : true,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				//isLoading  : false,
				ErrMsg     : err_msg,
				isLoading  : false,
				isNotFound : true,
			});
			return(0);
		}
	}
	async cartAdd (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;

			//stateから直接取得する更新項目
			const code          = this.state.code;

			//Formから直接取得する更新項目
			const number          = formObj.number.value;

			//カート追加
			const ref = await API.Cart.Add(code, number);

			if(ref.error === null){
				//カートに移動
				this.props.history.push('/cart/');
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async wishlistAdd (code) {

		//更新処理
		try{
			//カート追加
			const ref = await API.WishList.Add(code);

			this.setState({
				isWishlist  : true,
			});

			console.log(ref);
			if(ref.error === null){
				this.setState({
					isShowModal : true,
					isLockForm  : false,
				 });
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async wishlistRemove (code) {

		//更新処理
		try{
			//カート追加
			const ref = await API.WishList.Remove(code);

			this.setState({
				isWishlist  : false,
			});

			console.log(ref);
			if(ref.error === null){
				this._getData();
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}

	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose() {
		this.setState({
			isLockForm     : false,
			isShowModal    : false,
			ErrMsg         : '',
		});
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>
						<div className="head-logo sec">
							<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
						</div>
						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div>{this.state.name} 商品詳細</div>
							</div>
						</Container>


						<Container>
							<h1>{this.state.name}</h1>
							<Row>

								<Col sm={7}>
									<div className="tabs-itemd">
									<input id="item01" type="radio" name="tab_itemdetail" value="1" /><label className="tab_itemdetail" htmlFor="item01">
										<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_main.jpg'} /></label>
									<input id="item02" type="radio" name="tab_itemdetail" value="2" /><label className="tab_itemdetail" htmlFor="item02">
									<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub01.jpg'} /></label>
									<input id="item03" type="radio" name="tab_itemdetail" value="3" /><label className="tab_itemdetail" htmlFor="item03">
									<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub02.jpg'} /></label>
									<input id="item04" type="radio" name="tab_itemdetail" value="4" /><label className="tab_itemdetail" htmlFor="item04">
									<Image src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub03.jpg'} /></label>

									<div className="item00">
									<Image className="item-img" src={'https://images.mindwave-store.com/public/' + this.state.code + '_main.jpg'} />
									</div>

									<div className="tab_content_item item01_content">
									<Image className="item-img" src={'https://images.mindwave-store.com/public/' + this.state.code + '_main.jpg'} />
									</div>
									<div className="tab_content_item item02_content">
									<Image className="item-img" src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub01.jpg'} />
									</div>
									<div className="tab_content_item item03_content">
									<Image className="item-img" src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub02.jpg'} />
									</div>
									<div className="tab_content_item item04_content">
									<Image className="item-img" src={'https://images.mindwave-store.com/public/' + this.state.code + '_sub03.jpg'} />
									</div>
									</div>


									<p className="item-catch">{this.state.title}</p>
									<div>{ this.state.text_1.split('&lt;br&gt;').map( text => { return(<div key={text}>{text.toString()}<br/></div>); } )}</div>

				{/*						<p className="text-tag">{ this.state.select_1.split('&lt;br&gt;').map( text => { return(<div key={text}>{text.toString()}<br/></div>); } )}</p> */}


								</Col>

								<Col sm={5}>
									<Form noValidate validated={this.state.isValidated} onSubmit={e => this.cartAdd(e)}>
									<p className="item_id">商品ID：{this.state.code}</p>
									<p className="item-price"><span>販売価格：</span>￥{this.state.price ? this.state.price.toLocaleString() : 0}<span>（税込）</span>

									{(() => {
																				if (this.state.flag_3 === true){
																					return(
									<Badge pill variant="info">SALE</Badge>
																					);
																				}
																			})()}
																			<br /><span>通常価格：￥{this.state.price_notax ? this.state.price_notax.toLocaleString() : 0}（税抜）</span>
																			</p>

									<div className="item_detail">
										<dl>
										<dt>配送方法</dt>
										<dd>
												<Image className="itemsns" src="/img/order/icon_dm10.svg" alt="パケット10" style={{ visibility: this.state.flag_6 ? 'visibility' : 'hidden'}} />
												<Image className="itemsns" src="/img/order/icon_dmbin.svg" alt="ゆうパケット" style={{ visibility: this.state.flag_4 ? 'visibility' : 'hidden'}} />
												<Image className="itemsns" src="/img/order/icon_yamato.svg" alt="宅急便・宅配便" style={{ visibility: this.state.flag_5 ? 'visibility' : 'hidden'}} />
										</dd>
										{(() => {
											if (this.state.zaiko_flag === false){
												return(
													<div>
														<dt>在庫数</dt>
														<dd>{this.state.zaiko}　個</dd>
													</div>
												);
											}
										})()}
										</dl>
									</div>

										<div className=" mb-5">
											{(() => {
													if (this.state.zaiko === 0){
														return(
																								<Form.Group>
															<Button className="btn-dark w-100" block size="lg" >SOLD OUT</Button>
															</Form.Group>
														);
																}
															else{
																return (
																	<Form.Group className="form-inline mt-5">
																	<label htmlFor="number" className="pr-5">購入数</label>
																	<select className="form-control form-control-lg w-50" id="number" name="number" defaultValue={'1'} required disabled={this.state.isLockForm} >
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																	<option value="6">6</option>
																	<option value="7">7</option>
																	<option value="8">8</option>
																	<option value="9">9</option>
																	<option value="10">10</option>
																	</select>
																<Button className="btn-pink w-100" block size="lg" type="submit" disabled={this.state.isLockForm} >カートに入れる</Button>
																	<p><small> ※在庫数以上の数量をご希望の方は、ご注文の前にお問い合わせください。</small></p>
																</Form.Group>
														)
													}
												})()}
												
												{/* ■■■■■■ 追加 200416 ■■■■■■ */
													(() => {
														
														for(var i in NoWrapping){
															const nowrappingcode  =  NoWrapping[i].id;
															if (this.state.code === nowrappingcode){
																return(
																	<Image className="full-width" src="/img/order/icon_nowrapping.svg" alt="ラッピング不可" style={{ }} />
																);
															}
														}
														
													})()
													/* ■■■■■■ 追加 200416 ■■■■■■ */
												}
										</div>

									<ul className="detail-snslist">
									<li onClick={()=>{ if(!this.state.isWishlist){this.wishlistAdd(this.state.code)}else{this.wishlistRemove(this.state.code)} }} className={'active-'+this.state.isWishlist}><i className= {this.state.isWishlist===true ? 'animated flip faster fas fa-heart yellow' : 'fas fa-heart animated pulse faster'} ></i> お気に入り</li>
									<li><a href="https://www.facebook.com/mindwaveinc"><i className="fab fa-facebook-square"></i> Facebook</a></li>
									<li><a href="https://twitter.com/intent/tweet?url=https://www.mindwave-store.com/&screen_name=mindwaveinc"><i className="fab fa-twitter-square"></i> Twitter</a></li>
									</ul>
									<div className="item_detail">
										<dl>
										<dt>柄名</dt>
										<dd>{ this.state.text_2.split('&lt;br&gt;').map( text => { return(<div key={text}>{text.toString()}<br/></div>); } )}</dd>
										<dt>素材</dt>
										<dd>{ this.state.text_4.split('&lt;br&gt;').map( text => { return(<div key={text}>{text.toString()}<br/></div>); } )}</dd>
										<dt>詳細</dt>
										<dd>{ this.state.text_3.split('&lt;br&gt;').map( text => { return(<div key={text}>{text.toString()}<br/></div>); } )}</dd>

{/*<dt>select_2（未使用）</dt>
										<dd>{ this.state.select_2.split('&lt;br&gt;').map( text => { return(<div key={text}>{text.toString()}<br/></div>); } )}</dd>*/}





										</dl>
									</div>
										</Form>
								</Col>
							</Row>
						</Container>

						{/*		<Container>
							<h4>こちらもオススメです</h4>
						</Container>*/}

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withRouter(Default);
