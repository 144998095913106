import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class GuideMain extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/stationary_index/">TOP</a></div>
						<div><a href={'/guide/'}>ショッピングガイド</a></div>

					</div>
				<h1>SHOPPING GUIDE<p>ショッピングガイド</p></h1>
</Container>

<Container fluid={true}>
   <Row>
        <ul className="sub-menu">
       <li><a href="/guide/">ご注文の流れ</a></li>
       <li><a href="/guide/payment/">お支払いについて</a></li>
       <li><a href="/guide/send/">配送・送料について</a></li>
       <li><a href="/guide/#wrapping">ラッピングについて</a></li>
       <li><a href="/guide/faq/">よくあるご質問</a></li>
       <li><a href="/member/l">会員登録のおすすめ</a></li>
         </ul>
     </Row>
 </Container>

<Container className="longtxt">
	 <Row>
	 <Col lg={{ span: 10, offset: 1 }} md={12}>
     <h2>お買い物方法</h2>
<h3><span>ご注文の流れ</span></h3>
<p className="text-center">
 <picture><source media="(max-width: 767px)" srcSet="/svg/img_flow_sp.svg" />
	<Image src="/svg/img_flow.svg" alt="ご注文の流れ" fluid />
	</picture>
</p>
<p className="red text-center mt-5 exclamation">ご注文確認メールは基本的に翌営業日（土日祝定休日）に送信いたしております。</p>
<p className="red text-center exclamation">商品在庫は複数店舗で共有しておりますため、ご注文状況によってはご注文後でも欠品となってしまう場合がございます。<br />
その場合は、大変申し訳ございませんが、該当商品キャンセルのご案内をメールにてお知らせさせていただきます。</p>
<p className="red text-center exclamation">明細書は同梱しておりません。ご必要な場合は注文時の備考欄にご記載くださいませ。</p>
		        <Row className="mt-5">
									<Col md={6} mb={3}>
               <div className="caution">
               <h4>未成年の方のご購入について</h4>
             <p>当サイトではお子様向けの商品を多く販売しておりますが、未成年（20歳未満）の方がご購入される際には、必ず保護者の方の同意の上ご利用ください。保護者の方の同意が無い場合にはご購入をお断りさせていただく場合がございます。</p>
             </div>
									</Col>
            <Col md={6} mb={3}>
                <div className="caution">
                  <h4>ご注文確認メールが届かない場合</h4>
             <p>gmail、hotmail、yahooメールなど、フリーのメールをご利用の場合は「迷惑メール」フォルダに自動的に入る場合があります。
									 携帯電話のドメイン指定受信設定をしている場合は「＠mindwave-store.com」のドメインを受信できるよう設定をお願い致します。</p>
									</div>
									</Col>
									 </Row>


<h3 id="wrapping"><span>ラッピングについて</span></h3>
{/*<p><span className="marker_y">有料（300円税別）</span>にてラッピング用の不織布巾着をご用意しております。ご購入の際に「ご注文手続き」入力欄にラッピングの項目が表示されますので、選択してください。</p>*/}
 <Row>
  <Col md={6}>
	<p><span className="marker_y">有料（税込440円）</span>にてラッピング（不織布巾着＋選べるメッセージカード）をご用意しております。<br />
	ご購入の際に「ご注文手続き」入力欄にラッピングの項目が表示されますので、ご希望のラッピング＋メッセージカードを選択してください。</p>
<p>すでにラッピング済みのギフト商品は、ラッピングされたセット状態のままでのお届けとなります。例えば、ギフトセットを２つご購入いただいた場合、それをバラして１つに包み直すことはできません。</p>

</Col>
<Col md={6}><Image className="wrapimg mb-3" src="/img/guide/img_card_sp.jpg" alt="ラッピングイメージ" /></Col>
</Row>
<p className="red exclamation">１回のご注文につき、商品をまとめて１袋にお入れします。小分け袋はご用意がございません。</p>
<p className="red exclamation">メーカー発送商品はラッピングできません。</p>
<p className="red exclamation">商品サイズによっては、ラッピングできない商品がございます。</p>
<p className="red exclamation">ラッピング不可商品のみご注文されている場合は、ラッピングはキャンセルとさせていただきます。</p>
<p className="red exclamation">ご注文にラッピング不可の商品が含まれる場合は、ラッピング不可商品以外の商品のみを包装いたします。</p>
<p className="red exclamation">梱包の都合上、商品価格のラベル・タグ・印字は取ることができません。一部商品は目隠しシールで価格を隠すことは可能ですので、ご希望の方はご注文時の備考欄にご記載ください。</p>

			{/*<div className="mt-3">
 <picture>
<source media="(max-width: 767px)" srcSet="/img/guide/img_xmaswrap_sp.jpg" />
		<Image src="/img/guide/img_xmaswrap_pc.jpg" alt="12月限定クリスマスラッピング" fluid />
	</picture>
</div>

<div className="mt-3">
 <picture>
<source media="(max-width: 767px)" srcSet="/img/guide/img_vtwrap01_sp.jpg" />
		<Image src="/img/guide/img_vtwrap01_pc.jpg" alt="季節限定ラッピング" fluid className="bdrimg"/>
	</picture>
</div>*/}

		 <div>
		        <h3><span>ご注文後のキャンセル・変更について</span></h3>
		        <p>弊社では、お客様に少しでも早く商品をお届けしたい為、ご注文確認後、すぐに出荷に取りかかります（営業時間中のみ。休業日や営業時間外にいただいたご注文に関しては、翌営業日の注文受付となります。）<br />
		  ご変更やキャンセルをご希望の場合は、ご注文者様のお名前、ご連絡先、ご注文商品内容を明記の上、正しいご注文内容、又はキャンセル内容を記載し、早急に{/*<a href="mailto:store@mindwave-store.com">store@mindwave-store.com</a>*/} 運営部までご連絡ください。</p>
		{/*  <p><span className="marker_y">出荷後のご連絡となった場合（休日・営業時間終了後も含めて）は、お客様のご都合による返品扱い（送料・手数料はお客様ご負担）</span>となりますので、あらかじめご了承願います。</p>*/}
		<p><span className="marker_y">出荷後のご連絡となった場合（休日・営業時間終了後も含めて）は、変更・キャンセル等は対応できかねます</span>ので、あらかじめご了承くださいませ。</p>
		 <p className="red exclamation">※ご注文完了後にマイページで会員情報を変更されましても、過去の注文には変更された情報は反映されません。<br />
		 　注文に関しまして何かご変更がある場合は、必ずメールかお電話にてご連絡くださいませ。</p>
		 <p className="red exclamation">※［メーカー発送］商品は、ご注文受付メール後のキャンセル・商品変更はできかねます。</p>
		 </div>
		 <div>
		        <h3><span>お届け日について</span></h3>
		        <p>長期間の商品のお取り置きはできかねますため、お届け日は基本的に<span className="marker_y">2ヶ月以内のお日にちをご指定ください。</span><br />
						万が一2ヶ月以上先のお日にちをご指定されている場合は、ご連絡の上、お届け日を変更させていただきますので、あらかじめご了承くださいませ。</p>
		 </div>
		 <div>
		        <h3><span>在庫の確認について</span></h3>
		        <p>すべての商品の在庫は常に動いておりますが、サイト上で表示されている在庫数は、基本的に当オンラインストア用に確保している在庫でありご用意可能な数量となります。表示の在庫数以上の商品点数をご希望の場合は、事前にお問い合わせいただけましたら、商品によっては在庫を補充させていただくことも可能です。お気軽にご相談くださいませ。</p>
		           {/*<p>店頭でのご購入をご希望の方は、お問い合わせの際にお住まいの地域、商品IDを教えていただけましたら、メーカーに問い合わせてお調べいたします。<br />
		   ただし、各お店での在庫の有無までは把握しておりませんので、該当商品が確実に店頭にあるかはお答えすることができません。予めご了承ください。</p>*/}
		 </div>
		 <div>
		        <h3 id="returns"><span>返品・交換について</span></h3>
				<p>当店では、<span className="marker_y">お客様都合（注文間違い、イメージと違う等）による返品・交換は承っておりません。</span></p>
		        <p>お届けした商品に不良・破損が発生している場合、又はご注文の商品と異なる商品が届いた場合は、交換させていただきますので、大変お手数ではございますが、事前にお知らせいただき当店からの返信をご確認の上、商品到着後8日以内にご返送願います。<br />
		 返送先は出荷センターとなります。ご連絡を頂きましたら返送先情報をお知らせいたします。<br />※交換在庫がない場合は、返品（返金）対応とさせていただきます。{/*到着後、確認が出来次第、良品・正当な商品をお客様へお届けいたします。返品・交換により生じた差額は、弊社でお客様からの商品到着確認後、ご指定の口座番号へお振込みいたします。 */}</p>
		 <p className="red exclamation">商品お受け取り後、すぐに商品状態をご確認くださいませ。<br />
お受け取り後8日以上経過してからの不良の返品・交換はお受け付けできかねますので、ご注意いただきますようお願い申し上げます。</p>
								 {/*<h4>▼ 次の場合の返品はお受け付けできかねます。</h4>
							 <ul>
									 <li>● セール商品</li>
									 <li>● 既に開封・使用された商品(ご使用後不良品と解った場合は除く)</li>
									 <li>● お客様の責任でキズや汚れが生じた商品（故意に商品に傷をつけての返品と判断させて頂いた場合には、返品・返金はお受けできません）</li>
									 <li>● ゆうパケット配送時の紛失・破損</li>
								 </ul>*/}
		          <p className="red exclamation mt-4">［メーカー発送］商品は、ご注文受付メール後のキャンセル、また初期不良以外の返品・交換はお受けできかねます。</p>
				  <p className="red exclamation mt-4">ゆうパケット配送の紛失・破損につきましては、補償対象外となりますため、返品・交換はお受けできかねます。</p>
		 </div>
</Col>
</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(GuideMain);
