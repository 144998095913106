import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class CompanyMain extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/stationary_index/">TOP</a></div>
						<div><a href={'/company/'}>会社概要</a></div>
						<div>ご利用規約</div>

					</div>
				<h1>ご利用規約</h1>
</Container>


<Container className="longtxt">
	 <Row>
            		 <Col lg={{ span: 10, offset: 1 }} md={12}>
		<p>株式会社クヌム（以下、弊社）が運営する『マインドウェイブ オンラインストア』（以下、当サイト）では｢利用規約｣を設けております。当サイトをご利用の方は、以下の条件にご同意いただいたものとみなします。</p>
		<div className="rule">
				<dl>
					<dt>第1条 利用規約の改定</dt>
					<dd>弊社は予告なく｢利用規約｣を変更する事があります。<br />
					その場合は変更後の｢利用規約｣が適用されますので、あらかじめご了承下さい。</dd>

					<dt>第2条 本サービスの内容</dt>
					<dd>弊社がご利用者に提供するサービスは以下のとおりとし、サービスの詳細については当サイトにて別途通知いたします。
					<ul>
						<li>当サイトで取扱う製品等の販売</li>
						<li>その他、今後弊社がはじめるサービス</li>
					</ul>
					</dd>

					<dt>第3条 免責</dt>
					<dd>弊社では当サイトに掲示した情報について、以下の点について責任を負うものではありません。
					<ul>
					<li>掲示された情報が、全て正しく、全て安全で、全て有用であること。</li>
					<li>掲示された情報が、常に最新のものであること</li>
					<li>当サイトの利用によって起こった一切の障害</li>
					<li>予告なしにサーバーの停止、情報の変更、提供の中止・中断を行うこと。またそれによって生じたいかなる障害。</li>
					<li>入力されたＩＤとパスワードが事前に登録されたものと一致することを弊社所定の方法により確認した場合、それが、盗用、不正使用その他の事故により、本人以外の者が利用している場合に生じるいかなる障害</li>
					<li>氏名、住所、電話番号、Eメールアドレス、その他弊社への届出内容に変更が生じた場合、早やかに所定の届出を行わなかったことで被る障害</li>
					</ul>
					</dd>

					<dt>第4条 リンク先ウェブサイトのサービスについて</dt>
					<dd>当サイトからリンクされた弊社関連会社のウェブサイトをご利用される際には、リンク先各ウェブサイトの利用規約にしたがって下さい。</dd>

					<dt>第5条 IDおよびパスワード</dt>
					<dd>本サービスで交付するID・パスワードについての管理および使用（変更を含む）は、ID登録されたご利用者の責任とさせていただきます。 また、ID登録されたご利用者は、氏名、住所、電話番号、Eメールアドレス、その他弊社に届け出ている内容に変更が生じた場合は、速やかに弊社指定の方法で通知するものとします。</dd>

						<dt>第6条 個人情報</dt>
					<dd>ご利用者は、本サービスの運営、宣伝物の送付、商品等の取引、代金回収等の目的の範囲において、弊社がご利用者に関する個人情報を利用することに同意するものとします。 なお、弊社が取得したお客様の個人情報は、弊社が別途定める｢個人情報の取扱いについて｣に従って適切な保護に努めます。</dd>

						<dt>第7条 禁止事項</dt>
					<dd>本サービスのご利用にあたり、以下の行為を禁止させていただきます。
					<ul>
					<li>本利用条件に違反する行為</li>
					<li>第三者もしくは弊社に不利益ないし損害を与える行為、またはその恐れのある行為</li>
					<li>公序良俗に反する行為、またはその恐れのある行為</li>
					<li>犯罪行為、またはその恐れのある行為</li>
					<li>営利を目的とする行為、またはその準備を目的とする行為</li>
					<li>コンピュータウイルス等有害なプログラムを使用もしくは提供する行為、またはその恐れのある行為</li>
					<li>他のお客様のID・パスワードを不正に使用する行為</li>
					<li>他のお客様、または第三者のプライバシーを侵害する行為</li>
					<li>その他弊社が不適切と判断する行為</li>
					</ul>
					</dd>

					<dt>第8条 利用の中止、解除</dt>
					<dd>当社は、以下の各項目の事項に該当する場合、オンラインストア会員へすみやかに通知のうえ、個別の売買契約の解除をすることができます。なお、（1）により解除をしたときは、当社は、負担した費用をオンラインストア会員に請求することができるものとします。
<ol>
<li>オンラインストア会員が商品の受領に応じない、またはオンラインストア会員の都合により商品の引渡しができないと当社が判断した場合</li>
<li>善良な管理者の注意義務をもってしても予測できなかった当社システムの不具合やエラーにより売買契約の条件等に不備が生じた場合</li>
<li>商品ページの価格表記が当社店舗にて販売している価格と比較して誤っていることが明らかな場合<br />
当社は、オンラインストア会員が以下の各号の項目に該当する場合、なんらの通知を行うことなく、本サービスの利用を中止、購入申し込みを受けた商品等の配送停止、
または個別の売買契約の解除をすることができます。なお、当社は、オンラインストア会員に対して当該措置の理由を開示する義務を負いません。
<ul>
<li>当社への支払を怠った場合</li>
<li>当社への届出事項に虚偽があることが判明した場合</li>
<li>合理的な理由なく商品等の交換、返品、受領拒否を繰り返し行った場合</li>
<li>オンラインストア利用規約または利用規約に違反した場合</li>
<li>その他当社が本サービスの利用継続に適さないと合理的に判断した場合</li>
</ul>
</li>
<li>前２項によりオンラインストア会員に損害が発生したとしても、当社は一切責任を負いません。また、当社は、当該措置につき、当該会員へのなんらの補償も行わず、責任も一切負いません。</li>
			</ol>
				</dd>

					<dt>第8条 準拠法</dt>
					<dd>本規約の解釈、適用については日本法に準拠します。</dd>

				</dl>
	</div>
		</Col>
			</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(CompanyMain);
