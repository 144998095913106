import { API, graphqlOperation } from 'aws-amplify';

const StripeCard = {};

StripeCard.Get = async function (card_id)  {
		
	try{
		const Query = `query getStripeCard($card_id: String!) {
			getStripeCard(card_id: $card_id) {
				card_brand
				last_4
				exp_month
				exp_year
				cardholder_name
				fingerprint
				use_flag
			}
		}`;
		const Data = {
			card_id : card_id,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const item = ref.data.getStripeCard;
		
		const card_brand      = item.card_brand      ;
		const last_4          = item.last_4          ;
		const exp_month       = item.exp_month       ;
		const exp_year        = item.exp_year        ;
		const cardholder_name = item.cardholder_name ;
		const fingerprint     = item.fingerprint     ;
		const use_flag        = item.use_flag        ;
		
		if(card_brand){
			return ({
				item: {
					card_id         : card_id         ,
					card_brand      : card_brand      ,
					last_4          : last_4          ,
					exp_month       : exp_month       ,
					exp_year        : exp_year        ,
					cardholder_name : cardholder_name ,
					fingerprint     : fingerprint     ,
					use_flag        : use_flag        ,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: 'クレジットカード情報が見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

StripeCard.GetAll = async function ()  {
		
	try{
		const Query = `query getStripeCardAll {
			getStripeCardAll {
				items{
					card_id
					card_brand
					last_4
					exp_month
					exp_year
					cardholder_name
					fingerprint
					use_flag
				}
			}
		}`;
		const ref = await API.graphql(graphqlOperation(Query));
		const items = ref.data.getStripeCardAll.items;

		const newData = [];

		for(const item of items){
				newData.push({
					card_id         : item.card_id         ,
					card_brand      : item.card_brand      ,
					last_4          : item.last_4          ,
					exp_month       : item.exp_month       ,
					exp_year        : item.exp_year        ,
					cardholder_name : item.cardholder_name ,
					fingerprint     : item.fingerprint     ,
					use_flag        : item.use_flag        ,
				});
		}
		
		if(newData.length !== 0){
			return ({
				items: newData,
				error: null,
			});
		}
		else{
			return ({
				items: null,
				error: 'クレジットカード情報が見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			items: null,
			error: error,
		});
	}
}

StripeCard.CustomerAdd = async function (email, name_1, name_2)  {

	try{
		//新規
		const Query = `mutation executeStripeCustomer($email: String, $name_1: String, $name_2: String) {
			executeStripeCustomer(email: $email, name_1: $name_1, name_2: $name_2) {
				result
				error
			}
		}`;
		const Data = {
			email  : email,
			name_1 : name_1,
			name_2 : name_2,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const stripe_id = ref.data.executeStripeCustomer.result;
		
		return ({
			stripe_id: stripe_id,
			error    : null,
		});
	}
	catch(error) {
		console.log(error);
		return ({
			stripe_id: null,
			error    : error,
		});
	}
}

StripeCard.CardAdd = async function (token, stripe_id)  {

	try{
		if(!stripe_id){
			const getQuery = `query getStripeCardAll {
				getStripeCardAll {
					scannedCount
				}
			}`;
			const ref = await API.graphql(graphqlOperation(getQuery));
			const count = ref.data.getStripeCardAll.scannedCount;
			
			//カートは1度に100件まで
			if(count >= 5){ return({ error : "クレジットカードは5件までしか登録出来ません。" }); }
		}
		
		//新規
		const Query = `mutation executeStripeCard($flag: String, $token: String, $stripe_id: String) {
			executeStripeCard(flag: $flag, token: $token, stripe_id: $stripe_id) {
				result
				error
			}
		}`;
		const Data = {
			flag       : 'create',
			token      : JSON.stringify(token),
			stripe_id  : stripe_id,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));
		const card_id = ref.data.executeStripeCard.result;
		const error = ref.data.executeStripeCard.error;

		return ({
			card_id : card_id,
			error: error,
		});
	}
	catch(error) {
		console.log(error);
		return({
			card_id: null,
			error: error,
		});
	}
}


StripeCard.Remove = async function (card_id)  {

	try{
		//削除
		const Query = `mutation executeStripeCard($flag: String, $card_id: String) {
			executeStripeCard(flag: $flag, card_id: $card_id) {
				result
				error
			}
		}`;
		const Data = {
			flag : 'delete',
			card_id : card_id,
		};
		await API.graphql(graphqlOperation(Query, Data));

		return ({ error: null });
	}
	catch(error) {
		console.log(error);
		return({ error: error });
	}
}

StripeCard.UseFlag = async function (card_id)  {

	try{
		//削除
		const Query = `mutation executeStripeCard($flag: String, $card_id: String) {
			executeStripeCard(flag: $flag, card_id: $card_id) {
				result
				error
			}
		}`;
		const Data = {
			flag : 'useflag',
			card_id : card_id,
		};
		await API.graphql(graphqlOperation(Query, Data));

		return ({ error: null });
	}
	catch(error) {
		console.log(error);
		return({ error: error });
	}
}

StripeCard.ErrorLog = async function (site_flag, cognitoid, email, name_1, name_2, err)  {
	
	try{
	
		const dateObj     = new Date();
		const year        = dateObj.getFullYear().toString();
		const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day         = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);
		const isoDate     = dateObj.toISOString();
		const localDate   = year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒';
		const expDate     = Math.round(dateObj.getTime() / 1000) + (86400 * 90);
            	
		const Query = `mutation addStripeCardErrorLog($data: StripeCardErrorLogInput!) {
			addStripeCardErrorLog(input: $data) {
				result
			}
		}`;
		const Data = {
			data : {
				cognitoid   : cognitoid,
				isoDate     : isoDate,
				localDate   : localDate,
				email       : email      ? email      : null,
				name_1      : name_1     ? name_1     : null,
				name_2      : name_2     ? name_2     : null,
		                err         : err        ? err        : null,
		                site_flag   : site_flag  ? site_flag  : null,
		                section     : 'pre',
		                ttl_exp     : expDate,
			}
		};
		//console.log(Data);
		await API.graphql(graphqlOperation(Query, Data));

		return ({
			item  :{
				cognitoid : cognitoid,
				isoDate   : isoDate,
			},
			error : null
		});
	}
	catch(error) {
		//console.log(error);
		return({ error: error });
	}
}

export { StripeCard };


