import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class CompanyMain extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
			<Container>
				<div className="head-logo sec">
					<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
				</div>

					<div className="pan">
						<div><a href="/stationary_index/">TOP</a></div>
						<div><a href={'/company/'}>会社概要</a></div>
						<div>個人情報の取り扱いについて</div>

					</div>
				    <h1>個人情報の取り扱いについて</h1>
</Container>


<Container className="longtxt">
	 <Row>
            		 <Col lg={{ span: 10, offset: 1 }} md={12}>
		<p>株式会社クヌム（以下、当社）では、業務実施上で取得した個人情報は下記の目的で利用いたします。<br />
いずれの場合にも、以下に定めたプライバシーポリシーに則り、個人情報の適切な取扱いに努めてまいります。当社サービスをご利用いただいた場合、このプライバシーポリシーに同意していただいたものとみなします。</p>
		<div className="rule">
				<dl>
					<dt>個人情報の利用目的</dt>
					<dd>弊社では当サイトに掲示した情報について、以下の点について責任を負うものではありません。
					<ul>
					<li>ご注文のお申し込みの確認や商品のお届け</li>
					<li>各種商品のご請求、お支払いとその確認のため</li>
					<li>お客様に合った商品や新商品などの情報を的確にお知らせするため</li>
					<li>事業システム改善のためのアンケート送付・回収</li>
					<li>ご本人からのお問い合わせへの対応</li>
					</ul>

				</dd>

					<dt>個人情報の管理について</dt>
					<dd>当社は個人情報保護法の趣旨のもと、プライバシーポリシーに基づき細心の注意を払ってお客様の個人情報を取り扱います。<br />
お客さまの個人情報は、不正アクセス・紛失・破壊・改ざん・漏えいなどが起こらないように、適切な管理を実施いたします。
										<ol>
					<li>個人情報の取り扱いは業務用必要な担当社員にのみに制限し、個人情報が含まれる媒体などの保管・管理に関して、個人情報保護の為の予防措置を講じます。</li>
					<li>個人情報の入力が必要なすべてのページで、業界標準の暗号化通信であるSecure Sockets Layer（SSL）暗号を使用しています。</li>
					<li>サービスに支障が生じないことを前提として、個人情報の受領時から一定期間経過後、個人情報は随時削除していきます。</li>
					</ol>
					</dd>

					<dt>第三者への提供について</dt>
					<dd>当社は、ご本人のご承諾がない限り収集した個人情報を第三者に提供いたしません。ただし、以下の場合には、お客様の事前の同意なく当社はお客様の個人情報を開示できるものとします。
					<ol>
					<li>警察や裁判所その他の政府機関から召喚状、令状、命令等によって要求された場合</li>
					<li>人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
					<li>商品の配送や回収をする場合</li>
					</ol>
					</dd>

					<dt>個人情報の共有について</dt>
					<dd>当サイトから収集したお客様の個人情報は、お客様から事前に同意を得ることなく第三者に開示、提供、販売、共有したりすることはありません。</dd>

					<dt>個人情報の開示・訂正・削除について</dt>
					<dd>お客様からご自身の個人情報の開示、訂正、削除等などのお申し出があった場合、その請求がお客様ご本人によるものであることを確認した上で、合理的な範囲以内で速やかに対処いたします。詳しい方法についてはお問い合わせください。</dd>

						<dt>プライバシーポリシーの改定について</dt>
					<dd>当社は、より一層お客様の個人情報の保護を図るため、もしくは法令の制定や変更等に伴いプライバシーポリシーを変更することがありますので、定期的にご確認されることをお勧めいたします。</dd>

						<dt>Cookieについて</dt>
					<dd>当サイトでは、最適なサービスをご提供するためにクッキー（Cookie）を使用しています。Cookieとは、お客様がご使用のコンピュータを識別するデータのことです。お客様のコンピュータを識別することはできますが、お客様自身を識別することはできません。本サイトのカートは、利便性を考慮してCookieを使用しておりますが、データはお客様のコンピュータに保存され、弊社のサーバー側では記録しておりません。
					</dd>
				</dl>
	</div>

  <div className="card mt-5">
<h4 className="card-title"><span>個人情報に関するお問い合わせはこちら</span></h4>
<div className="card-body">
<p>株式会社 クヌム</p>
<p>〒553-0004<br />
大阪府大阪市福島区玉川4丁目15-11 タマガワファーストビル5階<br />
TEL  　06-7220-4222<br />
FAX　  06-7220-4223 <br />
MAIL　<a href="mailto:store@mindwave-store.com">store@mindwave-store.com</a>
</p>
</div>
					 </div>
		</Col>
			</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(CompanyMain);
