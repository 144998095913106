import { API, graphqlOperation } from 'aws-amplify';

const PreOrder = {};

PreOrder.Get = async function (flag)  {

	try{

		//パラメータ確認
		if(!flag){ return({ error : "flagがありません。" }); }

		const getQuery = `query getPreOrder($flag: String!) {
			getPreOrder(flag: $flag) {
				flag
				payment_type
				carriage_type
				wrapping_type
				coupon_type
				email
				name_1
				name_2
				name_furi_1
				name_furi_2
				zip_1
				zip_2
				pref
				addr_1
				addr_2
				addr_3
				tel_1
				tel_2
				tel_3
				addressid
				o_name_1
				o_name_2
				o_name_furi_1
				o_name_furi_2
				o_zip_1
				o_zip_2
				o_pref
				o_addr_1
				o_addr_2
				o_addr_3
				o_tel_1
				o_tel_2
				o_tel_3
				o_com_name
				o_date_type
				o_date_h
				order_memo
				isNextPage
				isODate
				isSend
				square_id
				card_id
				card_name
				card_nonce
			}
		}`;
		const getData = { flag: flag };
		const ref = await API.graphql(graphqlOperation(getQuery, getData));
		const data = ref.data.getPreOrder;
		
		return ({
			item: data ? data : null,
			error: null,
		});
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}


PreOrder.Add = async function (data)  {

	try{

		//有効期限
		const dateObj = new Date();
		const expDate = Math.round(dateObj.getTime() / 1000) + (86400 * 1);
		
		data.ttl_exp= expDate;
		
		//パラメータ確認
		if(!data.flag){ return({ error : "data.flagがありません。" }); }
		
		//チェック
		const checkQuery = `query getPreOrder($flag: String!) {
			getPreOrder(flag: $flag) {
				flag
			}
		}`;
		const checkData = { flag: data.flag };
		const refCheck  = await API.graphql(graphqlOperation(checkQuery, checkData));
		const check     = refCheck.data.getPreOrder;

		if(check === null){
			
			//新規
			const addQuery = `mutation addPreOrder($data: PreOrderInput!) {
				addPreOrder(input: $data) {
					flag
				}
			}`;
			const addData = {
				data : data,
			};
			await API.graphql(graphqlOperation(addQuery, addData));
		}
		else{
			//更新
			await PreOrder.Update(data);
		}
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

PreOrder.Update = async function (data)  {

	try{
		//有効期限
		const dateObj = new Date();
		const expDate = Math.round(dateObj.getTime() / 1000) + (86400 * 1);
		
		data.ttl_exp= expDate;
		
		//更新
		const updateQuery = `mutation updatePreOrder($data: PreOrderInput!) {
			updatePreOrder(input: $data) {
				flag
			}
		}`;
		const updateData = {
			data : data,
		};
		await API.graphql(graphqlOperation(updateQuery, updateData));
		
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

PreOrder.Remove = async function (flag)  {

	try{
		//パラメータ確認
		if(!flag){ return({ error : "flagがありません。" }); }

		//削除
		const Query = `mutation removePreOrder($flag: String!) {
			removePreOrder(flag: $flag) {
				flag
			}
		}`;
		const Data = { flag: flag };
		
		await API.graphql(graphqlOperation(Query, Data));
		return ({ error: null });
	}
	catch(error) {
		return({ error: error });
	}
}

export { PreOrder };


