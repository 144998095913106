import { API, graphqlOperation } from 'aws-amplify';

const Coupon = {};

Coupon.Get = async function (coupon_type)  {
		
	try{
		//パラメータ確認
		if(!coupon_type){ return({ error : "クーポン種別がありません。" }); }

		const Query = `query getCoupon($coupon_type: String!) {
			getCoupon(coupon_type: $coupon_type) {
				coupon_type
				coupon
			}
		}`;
		const Data = { coupon_type : coupon_type };
		const ref = await API.graphql(graphqlOperation(Query, Data));
		console.log(ref);
		const item = ref.data.getCoupon;
		
		const coupon = item.coupon;
		
		if(coupon_type){
			return ({
				item: {
					coupon_type   : coupon_type     ,
					coupon        : coupon      ,
				},
				error: null,
			});
		}
		else{
			return ({
				item: null,
				error: 'クーポンが見つかりません。',
			});
		}
	}
	catch(error) {
		return({
			item: null,
			error: error,
		});
	}
}


export { Coupon };
