import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			ErrMsg          : '',
			orderno         : '',
			total_price     : '',
			payment_type    : '',
			payment_price   : '',
			carriage_type   : '',
			carriage        : '',
			cashdeli        : '',
			cashdeli_2      : '',
			wrapping_type   : '',
			wrapping        : '',
			coupon_type     : '',
			coupon          : '',
			cardid          : '',
			email           : '',
			name_1          : '',
			name_2          : '',
			name_furi_1     : '',
			name_furi_2     : '',
			zip_1           : '',
			zip_2           : '',
			pref            : '',
			addr_1          : '',
			addr_2          : '',
			addr_3          : '',
			tel_1           : '',
			tel_2           : '',
			tel_3           : '',
			o_name_1        : '',
			o_name_2        : '',
			o_name_furi_1   : '',
			o_name_furi_2   : '',
			o_zip_1         : '',
			o_zip_2         : '',
			o_pref          : '',
			o_addr_1        : '',
			o_addr_2        : '',
			o_addr_3        : '',
			o_tel_1         : '',
			o_tel_2         : '',
			o_tel_3         : '',
			o_com_name      : '',
			o_date_type     : '',
			o_date_h        : '',
			order_memo      : '',
			delivery_no     : '',
			delivery_date   : '',
			status          : '',
			memo            : '',
			date_add        : '',
			order_items     : [],
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData () {

		try{
			//IDの取得
			const orderno = this.props.match.params.orderno;
			if(!orderno){
					this.setState({
						isLoading   : false,
						ErrMsg  : 'システムIDが読み取れません。<br/>',
					});
					return(0);
			}

			//アドレスデータを取得
			const ref = await API.Order.Get(orderno);
			//console.log(ref);

			if(ref.item === null){
					this.setState({
						isLoading   : false,
						ErrMsg  : 'データが見つかりません。<br/>',
					});
					return(0);
			}

			const total_price   = ref.item.total_price  ;
			const payment_type  = ref.item.payment_type ;
			const payment_price = ref.item.payment_price;
			const carriage_type = ref.item.carriage_type;
			const carriage      = ref.item.carriage     ;
			const cashdeli      = ref.item.cashdeli     ;
			const cashdeli_2    = ref.item.cashdeli_2   ;
			const wrapping_type = ref.item.wrapping_type;
			const wrapping      = ref.item.wrapping     ;
			const coupon_type   = ref.item.coupon_type  ;
			const coupon        = ref.item.coupon       ;
			const cardid        = ref.item.cardid       ;
			const email         = ref.item.email        ;
			const name_1        = ref.item.name_1       ;
			const name_2        = ref.item.name_2       ;
			const name_furi_1   = ref.item.name_furi_1  ;
			const name_furi_2   = ref.item.name_furi_2  ;
			const zip_1         = ref.item.zip_1        ;
			const zip_2         = ref.item.zip_2        ;
			const pref          = ref.item.pref         ;
			const addr_1        = ref.item.addr_1       ;
			const addr_2        = ref.item.addr_2       ;
			const addr_3        = ref.item.addr_3       ;
			const tel_1         = ref.item.tel_1        ;
			const tel_2         = ref.item.tel_2        ;
			const tel_3         = ref.item.tel_3        ;
			const o_name_1      = ref.item.o_name_1     ;
			const o_name_2      = ref.item.o_name_2     ;
			const o_name_furi_1 = ref.item.o_name_furi_1;
			const o_name_furi_2 = ref.item.o_name_furi_2;
			const o_zip_1       = ref.item.o_zip_1      ;
			const o_zip_2       = ref.item.o_zip_2      ;
			const o_pref        = ref.item.o_pref       ;
			const o_addr_1      = ref.item.o_addr_1     ;
			const o_addr_2      = ref.item.o_addr_2     ;
			const o_addr_3      = ref.item.o_addr_3     ;
			const o_tel_1       = ref.item.o_tel_1      ;
			const o_tel_2       = ref.item.o_tel_2      ;
			const o_tel_3       = ref.item.o_tel_3      ;
			const o_com_name    = ref.item.o_com_name   ;
			const o_date_type   = ref.item.o_date_type  ;
			const o_date_h      = ref.item.o_date_h     ;
			const order_memo    = ref.item.order_memo   ;
			const delivery_no   = ref.item.delivery_no  ;
			const delivery_date = ref.item.delivery_date;
			const status        = ref.item.status       ;
			const memo          = ref.item.memo         ;
			const date_add      = ref.item.date_add     ;
			const order_items   = ref.item.order_items  ;

			//日付
			const dateObj     = new Date(date_add);
			const year        = dateObj.getFullYear().toString();
			const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day         = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const second      = ('00' + dateObj.getSeconds().toString()).slice(-2);

			//データ更新
			this.setState({
				isLoading       : false,
				orderno         : orderno,
				total_price     : total_price     ,
				payment_type    : payment_type    ,
				payment_price   : payment_price   ,
				carriage_type   : carriage_type   ,
				carriage        : carriage        ,
				cashdeli        : cashdeli        ,
				cashdeli_2      : cashdeli_2      ,
				wrapping_type   : wrapping_type   ,
				wrapping        : wrapping        ,
				coupon_type     : coupon_type     ,
				coupon          : coupon          ,
				cardid          : cardid          ,
				email           : email           ,
				name_1          : name_1          ,
				name_2          : name_2          ,
				name_furi_1     : name_furi_1     ,
				name_furi_2     : name_furi_2     ,
				zip_1           : zip_1           ,
				zip_2           : zip_2           ,
				pref            : pref            ,
				addr_1          : addr_1          ,
				addr_2          : addr_2          ,
				addr_3          : addr_3          ,
				tel_1           : tel_1           ,
				tel_2           : tel_2           ,
				tel_3           : tel_3           ,
				o_name_1        : o_name_1        ,
				o_name_2        : o_name_2        ,
				o_name_furi_1   : o_name_furi_1   ,
				o_name_furi_2   : o_name_furi_2   ,
				o_zip_1         : o_zip_1         ,
				o_zip_2         : o_zip_2         ,
				o_pref          : o_pref          ,
				o_addr_1        : o_addr_1        ,
				o_addr_2        : o_addr_2        ,
				o_addr_3        : o_addr_3        ,
				o_tel_1         : o_tel_1         ,
				o_tel_2         : o_tel_2         ,
				o_tel_3         : o_tel_3         ,
				o_com_name      : o_com_name      ,
				o_date_type     : o_date_type     ,
				o_date_h        : o_date_h        ,
				order_memo      : order_memo      ,
				delivery_no     : delivery_no     ,
				delivery_date   : delivery_date   ,
				status          : status          ,
				memo            : memo            ,
				date_add        : year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒',
				order_items     : order_items     ,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	handleClose(){
		//
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
	</Container>
							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>

<Container>

							<h3 id="view01">受注番号：{this.state.orderno}／ご注文日：{this.state.date_add}</h3>
							<table className="table order_detail">
							<thead>
							<tr>
							<th>商品情報</th>
							<th>価格</th>
							<th>数量</th>
							<th>小計</th>
							</tr>
							</thead>
							<tbody>

							{this.state.order_items.map( item =>{
								const code       = item.code;
								const name       = item.name;
								const price      = item.price;
								const sub_price  = item.sub_price;
								const piece      = item.piece;
								const piece_type = item.piece_type;
								return(
									<tr key={code}>
										<td className="itemname">
											<p className="itemimg"><Image className="item-img" src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} /></p>
											<p>{name}</p>
										</td>
										<td>￥{price}</td>
										<td>{piece}{piece_type} </td>
										<td>￥{sub_price} </td>
									</tr>
								);
							})}
							</tbody>
							</table>

							<table className="table order-view">
							<thead>
							<tr>
							<th scope="col">お届け先</th>
							<th scope="col">お支払い方法</th>
							<th scope="col">お届け方法</th>
							<th scope="col">配達日指定</th>
							</tr>
							</thead>
							<tbody>
							<tr>
							<td data-label="お届け先" className="text-left">
								<p className="font-weight-bold">　{this.state.o_name_1}　{this.state.o_name_2}　様宛</p>
								〒{this.state.o_zip_1}-{this.state.o_zip_2}<br/>
								{this.state.o_pref}{this.state.o_addr_1}{this.state.o_addr_2}　{this.state.o_addr_3 === 'null' ? '' : this.state.o_addr_3}<br/>
								{this.state.o_com_name === 'null' ? '' : this.state.o_com_name}<br/>
								TEL：{this.state.o_tel_1}-{this.state.o_tel_2}-{this.state.o_tel_3}
							</td>
							<td data-label="お支払い方法">{this.state.payment_type}</td>
							<td data-label="お届け方法">{this.state.carriage_type}</td>
							<td data-label="配達日指定">{this.state.o_date_type}{this.state.o_date_h}</td>
							</tr>
							</tbody>
							<thead>
							<tr>
							<th colSpan="4">その他ご要望など（備考・連絡事項）</th>
							</tr>
							</thead>
							<tbody>
							<tr>
							<td  data-label="その他ご要望など"  colSpan="4" className="text-left"><br/>{this.state.order_memo === 'null' ? '' : this.state.order_memo}</td>
							</tr>
							</tbody>
							</table>

</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
