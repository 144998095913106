import { API, graphqlOperation } from 'aws-amplify';

const MailSend = {};

MailSend.Join = async function (flag)  {

	try{

		const Query = `mutation executeMailSendJoin($flag: String) {
			executeMailSendJoin(flag: $flag) {
				result
				error
			}
		}`;
		const Data = {
			flag       : flag,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));

		return ({
			result : ref,
			error: null,
		});
	}
	catch(error) {
		console.log(error);
		return({
			result: null,
			error: error,
		});
	}
}

MailSend.Resign = async function (flag)  {

	try{

		const Query = `mutation executeMailSendResign($flag: String) {
			executeMailSendResign(flag: $flag) {
				result
				error
			}
		}`;
		const Data = {
			flag       : flag,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));

		return ({
			result : ref,
			error: null,
		});
	}
	catch(error) {
		console.log(error);
		return({
			result: null,
			error: error,
		});
	}
}

MailSend.Contact = async function (flag, email, name, tel, message)  {

	try{

		const Query = `mutation executeMailSendContact($flag: String, $email: String, $name: String, $tel: String, $message: String) {
			executeMailSendContact(flag: $flag, email: $email, name: $name, tel: $tel, message: $message) {
				result
				error
			}
		}`;
		const Data = {
			flag    : flag,
			email   : email,
			name    : name,
			tel     : tel,
			message : message,
		};
		const ref = await API.graphql(graphqlOperation(Query, Data));

		return ({
			result : ref,
			error: null,
		});
	}
	catch(error) {
		console.log(error);
		return({
			result: null,
			error: error,
		});
	}
}

export { MailSend };


