import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersStrapcase extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/strapcase/header-strap_sp.jpg" />
	 		<Image src="/img/strapcase/header-strap.jpg" alt="オンラインストア限定　ストラップ付きケース" fluid />
	 	</picture>
	 </div>
	 <div className="mb-3">
	   <picture>
	 <source media="(max-width: 767px)" srcSet="/img/mobileitem/period_sp.jpg" />
	 		<Image src="/img/mobileitem/period.jpg" alt="期間" fluid />
	 	</picture>
	 </div>

		 {/*
<p className="red"><strong>※他の通常商品（シールなど）を一緒にご購入の場合は、別送となり、別途送料が必要です。受注確定メールにて訂正した正しい金額をお知らせさせていただきます。<br />
		 ※マルチ手帳Lサイズが生産終了につき、在庫限りとなります。</strong></p>*/}
		 
	</Container>


	 <Container>
	 <div id="" className="my-5 pb-3">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/android/ttl_design_sp.png" />
		 <Image className="my-5" src="/img/android/ttl_design.png" alt="デザイン一覧" fluid />
	 </picture>
	</div>
	<div className="my-5">
		 

<picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution3_sp.png" />
		<Image className="" src="/img/android/ac-caution3.png" alt="ご注意" fluid />
	</picture>
</div>
</Container>
	<Container fluid={true} className="py-5 my-5">
	<Container><picture>
 <source media="(max-width: 767px)" srcSet="/img/strapcase/strap_design_sp.jpg" />
		<Image className="" src="/img/strapcase/strap_design.jpg" alt="デザイン一覧" fluid />
	</picture>
	</Container>

<p className="text-right mb-5 pt-2 white">各機種 ￥<span className="font_ll">2,860</span> (税込)</p>


	 </Container>

	  <Container>
		<div className="mt-5 model">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/android/ttl_model_sp.png" />
		 <Image className="mt-5 mb-3" src="/img/android/ttl_model.png" alt="機種・型番で探す" fluid />
	 </picture>
		   <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>

	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution2_sp.png" />
		<Image className="mb-5" src="/img/android/ac-caution2.png" alt="ご注意" fluid />
	</picture>
	<Row id="iphone" className="model_listbox">
	 	<Col xs={12}><div className="model_name bg_gray mb-3"><p>iPhone</p></div></Col>
	 	<Col xs={12}>
		<Row>
		<Col xs={12}>
		<ul className="model_list">
		<li><a href="/category/アイテムから探す-ストラップ付きiPhoneケース-(8･7)" className=""><i className="fa fa-mobile-alt"></i> iPhone 7/8/SE2(第2･3世代)　</a></li>
		
		<li><a href="/category/アイテムから探す-ストラップ付きiPhoneケース-(XR・11)" className=""><i className="fa fa-mobile-alt"></i> iPhone XR/11</a></li>
		<li><a href="/category/アイテムから探す-ストラップ付きiPhoneケース-(12･12pro)" className=""><i className="fa fa-mobile-alt"></i> iPhone 12/12pro</a></li>
		<li><a href="/category/アイテムから探す-ストラップ付きiPhoneケース-(13・14)" className=""><i className="fa fa-mobile-alt"></i> iPhone 13/14/15</a></li>
		</ul></Col>
	
		</Row>
		</Col>
		 </Row>
	 
		 </div>
</Container>
{/* */}

<Container className="mb-4 ">
	 <div className="oshirase bg_gray">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <ul className="mb-5">
	<li>※スマートフォン用ケースです。本来の用途以外には使用しないでください。</li>
	<li>※スマホケースは、スマートフォン本体へのキズや汚れを完全に防ぐものではありません。</li>
	<li>※本製品のご使用中に伴う、スマートフォンの紛失、故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
	<li>※スマホケースを装着したまま充電スタンドの使用や、SIMカード、SDカードの出し入れはできません。</li>
	<li>※汗、雨、摩擦などの使用環境により、ストラップの劣化や損傷をきたす可能性がございます。</li>
	<li>※過度な力が加わるとストラップが切れたり、外れたりする場合があります。</li>
	<li>※ストラップが人や動物、物へ絡まってしまうと、事故やケガにつながる恐れがあります。ご使用の際には周りに十分ご注意ください。</li>
	</ul></Col></Row>
	 </div>
	
</Container>
<Container className="my-5">
<a href="/others/glasscase/" className="opa06 mb-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/glasscase/bnr_glasscase_l_sp.jpg" />
	 <Image src="/img/glasscase/bnr_glasscase_l.jpg" alt="オンラインストア限定ケース" fluid className="mb-5"/>
 </picture></a>
<a href="/others/mobilebattery/" className="opa06">
<picture>
<source media="(max-width: 767px)" srcSet="/img/mobilebattery/bnr_mb_sp.jpg" />
	 <Image src="/img/mobilebattery/bnr_mb.jpg" alt="オンラインストア限定モバイルバッテリー" fluid className="bdrimg"/>
 </picture></a>
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersStrapcase);
