import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from '../@Include/Auth';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			ErrMsg          : '',
			email           : '',
			name_1          : '',
			name_2          : '',
			name_furi_1     : '',
			name_furi_2     : '',
			zip_1           : '',
			zip_2           : '',
			pref            : '',
			addr_1          : '',
			addr_2          : '',
			addr_3          : '',
			tel_1           : '',
			tel_2           : '',
			tel_3           : '',
			birth_y         : '',
			birth_m         : '',
			birth_d         : '',
			sex             : '',
			mg_kbn_1        : false,
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData() {

		try{

			//会員データを取得
			const refProfile = await API.Profile.Get();

			//データがない場合
			if(refProfile.item === null){
				this.setState({
					isLoading  : false,
					ErrMsg     : '※会員データが存在しません。<br/>',
				 });
				return(0);
			}

			//各項目取得
			const email         = refProfile.item.email             ? refProfile.item.email         :  '';
			const name_1        = refProfile.item.name_1            ? refProfile.item.name_1        :  '';
			const name_2        = refProfile.item.name_2            ? refProfile.item.name_2        :  '';
			const name_furi_1   = refProfile.item.name_furi_1       ? refProfile.item.name_furi_1   :  '';
			const name_furi_2   = refProfile.item.name_furi_2       ? refProfile.item.name_furi_2   :  '';
			const zip_1         = refProfile.item.zip_1             ? refProfile.item.zip_1         :  '';
			const zip_2         = refProfile.item.zip_2             ? refProfile.item.zip_2         :  '';
			const pref          = refProfile.item.pref              ? refProfile.item.pref          :  '';
			const addr_1        = refProfile.item.addr_1            ? refProfile.item.addr_1        :  '';
			const addr_2        = refProfile.item.addr_2            ? refProfile.item.addr_2        :  '';
			const addr_3        = refProfile.item.addr_3            ? refProfile.item.addr_3        :  '';
			const tel_1         = refProfile.item.tel_1             ? refProfile.item.tel_1         :  '';
			const tel_2         = refProfile.item.tel_2             ? refProfile.item.tel_2         :  '';
			const tel_3         = refProfile.item.tel_3             ? refProfile.item.tel_3         :  '';
			const birth_y       = refProfile.item.birth_y           ? refProfile.item.birth_y       :  '';
			const birth_m       = refProfile.item.birth_m           ? refProfile.item.birth_m       :  '';
			const birth_d       = refProfile.item.birth_d           ? refProfile.item.birth_d       :  '';
			const sex           = refProfile.item.sex               ? refProfile.item.sex           :  '';
			const mg_kbn_1      = refProfile.item.mg_kbn_1          ? true                          :  false;

			//表示更新
			this.setState({
				isLoading     : false,
				email         : email         ,
				name_1        : name_1        ,
				name_2        : name_2        ,
				name_furi_1   : name_furi_1   ,
				name_furi_2   : name_furi_2   ,
				zip_1         : zip_1         ,
				zip_2         : zip_2         ,
				pref          : pref          ,
				addr_1        : addr_1        ,
				addr_2        : addr_2        ,
				addr_3        : addr_3 === 'null' ? '' : addr_3,
				tel_1         : tel_1         ,
				tel_2         : tel_2         ,
				tel_3         : tel_3         ,
				birth_y       : birth_y       ,
				birth_m       : birth_m       ,
				birth_d       : birth_d       ,
				sex           : sex           ,
				mg_kbn_1      : mg_kbn_1      ,
			});

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
		}
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading       : true,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	handleClose(){
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/mypage/">マイページ</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								MY PAGE
								<p>
									マイページ
								</p>
							</h1>
						</Container>

							<Container fluid>
								<Row>
									<ul className="sub-menu">
										<li><a href="/mypage/profile/">ご登録内容の変更</a></li>
										<li><a href="/mypage/wishlist/">お気に入り商品</a></li>
										<li><a href="/mypage/address/">お届け先アドレス帳</a></li>
										<li><a href="/cart/">カート</a></li>
										<li><a href="/mypage/order/">過去のご注文</a></li>
										<li><a href="/mypage/unsub/">会員退会</a></li>
									</ul>
								</Row>
							</Container>


							<Form>
								<Container>
								<h2>現在の会員情報</h2>

								<Row>
									<Col className="offset-md-1 col-md-10">
									<p className="red">※会員情報を変更してもお届け先住所は変更されません。お届け先は<a href="/mypage/address/">【こちら】</a>からご変更ください。<br />
									※過去の注文情報に変更内容は反映されません。発送前のご注文のご住所等の変更はメール・お電話でご連絡ください。</p>
										<hr className="mt-4"/>
										<Row>
											<Col md={3}>
												お名前
											</Col>
											<Col md={9}>
												{this.state.name_1} {this.state.name_2}（{this.state.name_furi_1} {this.state.name_furi_2}）様
											</Col>
										</Row>
										<hr className="mb-4"/>
										<Row>
											<Col md={3}>
												メールアドレス
											</Col>
											<Col md={9}>
												{this.state.email}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												パスワード
											</Col>
											<Col md={9}>
												＊＊＊＊＊＊＊＊＊＊
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												住所
											</Col>
											<Col md={9}>
												〒{this.state.zip_1}-{this.state.zip_2}<br/>
												{this.props.pref}{this.state.addr_1}{this.state.addr_2}{this.state.addr_3}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												電話番号
											</Col>
											<Col md={9}>
												{this.state.tel_1}-{this.state.tel_2}-{this.state.tel_3}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												生年月日
											</Col>
											<Col md={9}>
												{this.state.birth_y}年{this.state.birth_m}月{this.state.birth_d}日
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												性別
											</Col>
											<Col md={9}>
												{this.state.sex}
											</Col>
										</Row>
										<hr className="mb-4"/>

										<Row>
											<Col md={3}>
												メルマガ購読
											</Col>
											<Col md={9}>
												{this.state.mg_kbn_1 ? 'はい' : 'いいえ'}
											</Col>
										</Row>
										<hr className="mb-4"/>

										{/*<Row>
												<Col md={4}>
												<Button className="btn-blue" size="lg" block onClick={()=>{ this.props.history.push('/mypage/profile/'); }}>
													登録内容を変更する
												</Button>
												</Col>
							<Col md={4}>
							<Button size="lg" type="button" className="btn-blue" block onClick={()=>{ this.props.history.push('/mypage/password/'); }} >
								パスワードを変更する
							</Button>
											</Col>
												<Col md={4}>
							<Button size="lg" type="button" className="btn-blue" block onClick={()=>{ this.props.history.push('/mypage/emailverify/'); }} >
								Ｅメール変更の検証コードを入力
							</Button>
							</Col>

										</Row>*/}
										<Row className="btn-mypage">
										<Col md={6}>
										<Button type="button" className="btn-blue btn-block" size="lg" block onClick={()=>{ this.props.history.push('/mypage/profile/'); }}>
											会員情報を変更する
										</Button>
										</Col>
										<Col md={6}>
										<Button type="button" className="btn-blue btn-block" size="lg" block onClick={()=>{ this.props.history.push('/mypage/address/'); }}>
											お届け先を変更する
										</Button>
										</Col>
										</Row>
										<Row className="btn-mypage">
										<Col md={6}>
										<Button size="lg" type="button" className="btn-blue" block onClick={()=>{ this.props.history.push('/mypage/password/'); }} >
											パスワードを変更する
										</Button>
										</Col>
										<Col md={6}>
										<Button size="lg" type="button" className="btn-blue" block onClick={()=>{ this.props.history.push('/mypage/emailverify/'); }} >
											Ｅメール変更の検証コードを入力
										</Button>
										</Col>
										</Row>
									</Col>
								</Row>
							</Container>
												</Form>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withAuthenticator(withRouter(Default));
