import React from 'react';
import { API } from '../@onocomm-cart';
import { withRouter } from 'react-router-dom';
import SiteFlag from './@Include/SiteFlag';


class GetItemInfo extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			isLockForm     : false,
			isShowModal    : false,
			ErrMsg         : '',
			code           : '',
			setkey      : '',
			itemresult      : '',
		}
		this._getData         = this._getData.bind(this);
	}

	async _getData() {

try{
			//取得
			const code=this.props.code;
			const setkey=this.props.setkey;
			var itemresult='';
			//返答
			let ref;

				ref = await API.Item.Get(SiteFlag.en, code);
				if(ref.error === null){
					if(!!ref.item[setkey]){
    				itemresult  = ref.item[setkey];
						if(setkey==='price') {itemresult=itemresult.toLocaleString()}
  				}


					this.setState({
						isLoading      : false,
						code           : code ,
						setkey      : setkey,
						itemresult      : itemresult,
					});
				}
			//console.log(items);

		}

		catch(error) {

		}

	}
	componentDidMount () {
		this._getData();
	}

  render() {
    return this.state.itemresult;
  }
}

export default withRouter(GetItemInfo);
