import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NotFound from '../@Include/NotFound';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isNotFound     : false,
			isValidated    : false,
			ErrMsg         : '',
			cartData       : [],
			recentData     : [],
			total_price    : 0,
			total_piece    : 0,
			amountFlag     : null,
		}
		this.handleReload     = this.handleReload.bind(this);
		this.cartUpdate       = this.cartUpdate.bind(this);
		this.cartRemove       = this.cartRemove.bind(this);
		this._getData         = this._getData.bind(this);
		this.handleErrorClose = this.handleErrorClose.bind(this);
		this.lockFormClose    = this.lockFormClose.bind(this);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading   : true,
		});
		this._getData();
	}
	async _getData() {

		try{
			//console.log(await API.Auth.currentUserInfo());
			const refCat    = await API.Cart.Get();
			const refRecent = await API.RecentlyViewed.Get();

			this.setState({
				isLoading   : false,
				isLockForm  : false,
				cartData    : refCat.items,
				recentData  : refRecent.items,
				total_price : refCat.total_price ? refCat.total_price : 0,
				total_piece : refCat.total_piece ? refCat.total_piece : 0,
			});
		}
		catch(error) {
			console.log(error);
		}
	}
	async cartUpdate (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj = event.currentTarget;

			//stateから直接取得する更新項目
			const amountFlag = this.state.amountFlag;

			//Formから直接取得する更新項目
			const code  = formObj.code.value;
			const piece = Number(formObj.piece.value);

			let number = 0;

			if(amountFlag){
				number = piece + 1;
			}
			else{
				number = piece - 1;
			}

			//カート変更
			const ref = await API.Cart.Update(code, number);

			if(ref.error === null){
				//表示更新
				this._getData();
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}
	}
	async cartRemove (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;

			//Formから直接取得する更新項目
			const code          = formObj.code.value;

			//カート追加
			const ref = await API.Cart.Remove(code);

			if(ref.error === null){
				//表示更新
				this._getData();
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg,
				isLockForm : false,
			 });
			return(0);
		}

	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else if(this.state.isNotFound){
			return (
				<NotFound />
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered >
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered >
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>

					<Header/>
					<main>
						<div className="head-logo sec">
							<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
						</div>
						<Container>
							<div className="pan">
								<div>
									<a href="/stationary_index/">TOP</a>
								</div>
								<div>
									<a href="/cart/">ショッピングカート</a>
								</div>
							</div>
						</Container>
						<Container>
						{/*<div className="my-5">
						<picture>
						<source media="(max-width: 767px)" srcSet="/img/202003/bnr_free2003_sp.jpg" />
							<Image src="/img/202003/bnr_free2003.jpg" alt="送料無料キャンペーン" fluid />
						</picture>
						</div>*/}
							<h1>
								SHOPPING CART
								<p>
									ショッピングカート
								</p>
							</h1>

							<h2>
								カートに入れた商品
							</h2>

							<table className="table table-reflow">
								<thead>
									<tr>
										<th>商品情報</th>
										<th>価格</th>
										<th>数量</th>
										<th>小計</th>
									</tr>
								</thead>
							<tbody>
								{
									this.state.cartData.map( item =>{

										 const code       = item.code;
										 const name       = item.name;
										 const piece      = item.piece ? item.piece.toLocaleString() : 0;
										 const piece_type = item.piece_type;
										 const price      = item.price ? item.price.toLocaleString() : 0;
										 const sub_price  = item.sub_price ? item.sub_price.toLocaleString() : 0;
										 const error      = item.error;

										return(
											<tr key={code}>
												<td className="itemname">
													<p className="itemimg">
														<Image className="item-img" src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} />
													</p>
													<p>
														{name}
													</p>
													<div className="red">{error}</div>
												</td>
												<td>
													￥{price}
												</td>
												<td>
												<Form noValidate validated={this.state.isValidated} onSubmit={e => this.cartUpdate(e)}>
												<input type="hidden" name="code" value={code} />
												<input type="hidden" name="piece" value={piece} />
													{piece}{piece_type}　
													<Button variant="link" className="qty" type="submit" onClick={()=>{ this.setState({ amountFlag: false }); }} disabled={this.state.isLockForm} >
														－
													</Button>
													<Button variant="link" className="qty" type="submit" onClick={()=>{ this.setState({ amountFlag: true }); }} disabled={this.state.isLockForm} >
														＋
													</Button>
												</Form>
												</td>
												<td>
												<Form noValidate validated={this.state.isValidated} onSubmit={e => this.cartRemove(e)}>
												<input type="hidden" name="code" value={code} />
													￥{sub_price}　
													<Button variant="link" className="qty" type="submit"  disabled={this.state.isLockForm}>
														削除
													</Button>
												</Form>
												</td>
											</tr>
										);
									})
								}
							</tbody>
							<tfoot className="table-info">
								<tr>
									<th colSpan="4" className="allprice">
										合計：<span className="display-4">{this.state.total_piece.toLocaleString()}</span> 個　
										<span className="display-4">￥{this.state.total_price.toLocaleString()}</span>
									</th>
								</tr>
							</tfoot>
							</table>
						</Container>
						<Container>
							<Row>
								<Col md={{ span: 10, offset: 1 }}>
									<Row>
										<Col md={6} mb={4}>
											<Button variant="primary" className="btn-blue" type="button" block size="lg" onClick={()=>{ this.props.history.push('/order/'); }}>
												ご購入手続きへ
											</Button>
										</Col>
										<Col md={6} mb={4}>
											<Button variant="primary" className="btn-blue" type="button" block size="lg" onClick={()=>{ this.props.history.goBack(); }} disabled={this.state.isLockForm} >
												ショッピングを続ける
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>

						<Container>
							<h3>
								<span>チェックしたアイテム</span>
							</h3>
							<div className="checkitem-wrap">
								{
									this.state.recentData.map( item =>{

										 const code       = item.code;
										 const name       = item.name;

										return(
											<div key={code} className="item-box">
												<a href={'/item/' + code + '/'}>
													<Image className="item-img" src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} />
												</a>
											        <h5 className="list-title">
											        	<a href={'/item/' + code + '/'}>
											        		{name}
											        	</a>
											        </h5>
											</div>
										);
									})
								}
							</div>
						</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

export default withRouter(Default);
