import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersSpeaker extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-3"><Image src="/img/speaker/header-speaker.jpg" alt="オンラインストア限定" fluid /></div>
	 </Container>
<Container>
	 <div id="" className="my-5 pt-5">
	 <div className="my-5">
	 <Image src="/img/speaker/speaker_spec.jpg" alt="SPEC" fluid />
	 </div>	
	 <div className="my-5">
		 <Image className="mt-5" src="/img/speaker/ttl_item.png" alt="一覧" fluid />
	 <p className="text-right pt-5">各種 ￥<span className="font_ll">5,478</span> (税込)</p>
	</div>
	<div className="mb_list mb-5 pb-5 text-center">
	<Row>
		<Col xs={12} sm={4} ><a href="/item/GS2212A/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/GS2212A_main.jpg" alt="" fluid /></a>
		<p className="mt-3"><a href="/item/GS2212A/">ごろごろにゃんすけ</a></p></Col>
		<Col xs={12} sm={4} ><a href="/item/GS2212B/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/GS2212B_main.jpg" alt="" fluid /></a>
		<p className="mt-3"><a href="/item/GS2212B/">しばんばん</a></p></Col>
		<Col xs={12} sm={4} ><a href="/item/GS2212C/" className="opa06"><Image className="bdrimg" src="https://images.mindwave-store.com/public/GS2212C_main.jpg" alt="" fluid /></a>
		<p className="mt-3"><a href="/item/GS2212C/">ぴよこ豆</a></p></Col>
 </Row>
 </div>
 </div>
	 </Container>
<Container className="mb-4 ">

	 <div className="oshirase">
	 <Row>
	 <Col xs={12}>
	 <h4><i className="fas fa-exclamation-triangle"></i> 《ご注意》ご購入前に必ずお読みください</h4>
	 <p className="mb-5">●本品はBluetooth対応の全ての機器との接続動作を保証するものではありません。<br />
●本品の使用上発生した動作障害およびメモリ内容の消失やその他トラブルに関して、弊社では一切の責任を負いかねます。<br />
●本品に起因する不慮の事故及び故障が発生した場合の損害賠償責任は、弊社に故意または重大な過失があった場合を除き、本品の購入代金と同額を上限と致します。<br />
●医療機器や人命に直接または間接的に関わるシステム、高い安全性や信頼性が求められる環境下、飛行機内など通信システムを妨害する恐れのある場所での使用はおやめください。<br />
●他のBluetooth機器からの接続要求に応答するために常に電力を消費しますので、ご使用にならない場合は本品の電源をお切りください。<br />
●本品に防水機能はありません。風呂場等水のかかる場所では使用しないでください。屋外で使用する場合は、雨等に濡れない様に注意してください。
</p>
	 {/*<ul className="mb-5">
	<li>※本来の用途以外には使用しないでください。</li>
	<li>※ご使用の際には取扱説明書をよくお読みの上、正しくお使いください。</li>
	<li>※本製品のご使用中に伴う、接続機器の故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
		</ul>*/}
	</Col></Row>
	 </div>	
</Container>
<Container className="my-5">
 <div className="text-right mt-5 pt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersSpeaker);
