import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class Footer extends React.Component {
	render() {
		return (
			<footer className="footer">
				<Container>
					<Row>
						<ul className="footer-guide flexbox-container">
							<li><a href="/guide/">ショッピングガイド</a></li>
							<li><a href="/guide/payment/">お支払いについて</a></li>
							<li><a href="/guide/send/">配送・送料について</a></li>
							<li><a href="/guide/faq/">よくあるご質問</a></li>
						</ul>
					</Row>
				</Container>
				<Container fluid className="footer-line clearfix">
					<Container>
						<Row>
							<Col sm={12} md={12} lg={10}>
								<Row>
									<ul className="footer-menu">
										<li><a href="/company/">会社概要</a></li>
										<li><a href="/company/terms/">ご利用規約</a></li>
										<li><a href="/company/law/">特定商取引に基づく表示</a></li>
										<li><a href="/company/policy/">個人情報の取り扱いについて</a></li>
										<li><a href="/contact/"><i className="far fa-envelope fa-lg"></i> お問い合わせ</a></li>
									</ul>
								</Row>
							</Col>
							<Col sm={12} md={12} lg={2}>
								<ul className="footer-sns">
									{/*<li><a href="https://www.facebook.com/mindwaveinc"><i className="fab fa-facebook-square fa-lg"></i><span>Facebook</span></a></li>*/}
									<li><a href="https://x.com/mindwaveinc">{/* <i className="fab fa-twitter-square fa-lg"></i>*/}<Image src="/img/square-x-twitter-brands-solid.svg" width="20px" className="snsicon"/><span>x</span></a></li> 
									<li><a href="https://www.instagram.com/mindwaveinc/">{/*<i className="fab fa-instagram fa-lg"></i>*/}<Image src="/img/square-instagram-brands-solid.svg" width="20px" className="snsicon"/><span>Instagram</span></a></li>
								</ul>
							</Col>
						</Row>
					</Container>
				</Container>

				<Container className="footer-mark">
					<Image src="/svg/logo_footer.svg" width="130" />
					<p>Copyright © MIND WAVE All rights reserved.</p>
					<p>MIND WAVE ONLINE STOREは（株）マインドウェイブとのライセンス契約により運営しております。  </p>
				</Container>
			</footer>
		);
	}
}

export default withRouter(Footer);
