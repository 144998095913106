import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';

import GetItemInfo from '../GetItemInfo';
import './character.css';

class OthersPiyokomame extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
			<main id="piyokomame-lp" className={this.state.cognitoid ? 'others chara-lp members' : 'others chara-lp'}>
			<Container fluid={true} className="bg_or lp_catelist">
			<div className="pt-5 pb-2">
			<dl><dt><picture>
		<source media="(max-width: 840px)" srcSet="/img/piyoko/ttl_list_sp.png" />
			 <Image src="/img/piyoko/ttl_list_pc.png" alt="カテゴリーリスト" />
		 </picture></dt><dd>
			<ul><li><a href="/category/キャラクターから探す-ぴよこ豆-シール/">シール</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-付箋/">付箋</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-メモ/">メモ</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-ノート/">ノート</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-モバイル用品/">モバイル用品</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-ランチ/">ランチ</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-テープ/">テープ</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-アルバム/">アルバム</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-文具小物/">文具小物</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-ペンケース・ポーチ/">ペンケース</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-ぬいぐるみ/">ぬいぐるみ</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-雑貨/">雑貨</a></li>			
			<li><a href="/category/キャラクターから探す-ぴよこ豆-布製品/">布製品</a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆-限定商品/">限定商品</a></li>
			</ul></dd></dl>
			</div>
			</Container>
<Container>

	 <Row>
		 	  <div className="top-img">
	   <h2>
		 		<Image src="/img/piyoko/lp_piyoko_head.jpg" alt="特集ページ" fluid />
	 	</h2>
	 </div>
		 </Row>
		<Row><dl className="illustrator"><dt><Image src="/img/piyoko/tomon.png" alt="イラストレーターの紹介" fluid /></dt>
		 <dd>福岡県出身。<br />代表作は「ぴよこ豆」「うさぎのムーちゃん」<br />脱力感のあるユーモラスなキャラクターが得意。
		 <span className="sns-link"><a href="https://twitter.com/tomo_n_mw"> <i className="fab fa-twitter"></i></a>
		 <a href="https://www.instagram.com/tomo_n_mw/"> <i className="fab fa-instagram"></i></a></span></dd></dl></Row>
</Container>
<Container>
<div className="pu-item">
	 <h3 className="nyansuke_ttl pu-item_ttl">
	 <Image src="/img/piyoko/ttl_puitem_ye.png" alt="注目のアイテムをご紹介！" />
	 </h3>
	 <h4 className="nyansuke_sttl pu-item_sttl">
 	 <Image src="/img/nyansuke/subttl_01.png" alt="オンラインストア限定アイテム" />
 	 </h4>
 		<Row>
 			<Col xs={12} sm={4} ><a href="/others/glasscase/" className="opa06"><Image src="/img/piyoko/recommend_piyoko04.jpg" alt="注目のアイテム" fluid /></a></Col>
 			<Col xs={12} sm={4} ><a href="/others/mobilebattery/" className="opa06"><Image src="/img/piyoko/recommend_piyoko05.jpg" alt="注目のアイテム" fluid /></a></Col>
 			<Col xs={12} sm={4} ><a href="/item/iP_033ring/" className="opa06"><Image src="/img/piyoko/recommend_piyoko06.jpg" alt="注目のアイテム" fluid /></a></Col>
 	 </Row>
 	 <ul className="text-right puitem-list">
 	 <li><a href="/others/glasscase/">◆受注生産◆ スマホケース［メーカー発送］ ￥2,860<span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
 	 <li><a href="/others/mobilebattery/">◆受注生産◆ モバイルバッテリー(5000mAh)［メーカー発送］ ￥4,378<span>（税込）</span>  <i className="fas fa-arrow-right"></i></a></li>
 	 <li><a href="/item/iP_033ring/">ダイカットスマホリング［メーカー発送］ ￥1,650<span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li></ul>

	 <h4 className="nyansuke_sttl pu-item_sttl">
	 <Image src="/img/piyoko/subttl_01.png" alt="いつでもぴよこ豆と一緒" />
	 </h4>
		<Row>
			<Col xs={12} sm={4} ><a href="/item/61785/" className="opa06"><Image src="/img/piyoko/recommend_piyoko10.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/61679/" className="opa06"><Image src="/img/piyoko/recommend_piyoko12.jpg" alt="注目のアイテム" fluid /></a></Col>
			<Col xs={12} sm={4} ><a href="/item/61685/" className="opa06"><Image src="/img/piyoko/recommend_piyoko07.jpg" alt="注目のアイテム" fluid /></a></Col>
	 </Row>
	 <ul className="text-right puitem-list">
	 <li><a href="/item/61785/"><GetItemInfo code="61785" setkey="name" /> ￥<GetItemInfo code="61785" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/61679/"><GetItemInfo code="61679" setkey="name" /> ￥<GetItemInfo code="61679" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li>
	 <li><a href="/item/61685/"><GetItemInfo code="61685" setkey="name" /> ￥<GetItemInfo code="61685" setkey="price" /><span>（税込）</span> <i className="fas fa-arrow-right"></i></a></li></ul>


</div>
	 </Container>

	 <Container className="my-5">
	 <h3 className="nyansuke_ttl pu-item_ttl">
	 <Image src="/img/piyoko/ttl_pucate_ye.png" alt="注目のカテゴリーをご紹介！" />
	 </h3>
{/* 	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/piyoko/cate_nuigurumi_ye_sp.png" />
		<Image className="my-5" src="/img/piyoko/cate_nuigurumi_ye.png" alt="ぬいぐるみ" fluid />
	</picture>
	<div className="pucate-item">
	<Row>
		<Col xs={12} sm={4} ><a href="/item/94792/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94792_main.jpg" alt="" fluid /></a>
		<p><a href="/item/94792/"><GetItemInfo code="94792" setkey="name" /></a></p></Col>
		<Col xs={12} sm={4} ><a href="/item/57061/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57061_main.jpg" alt="" fluid /></a>
		<p><a href="/item/57061/"><GetItemInfo code="57061" setkey="name" /></a></p></Col>
		<Col xs={12} sm={4} ><a href="/item/61430/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61430_main.jpg" alt="" fluid /></a>
		<p><a href="/item/61430/"><GetItemInfo code="61430" setkey="name" /></a></p></Col>
 </Row>
 </div>
 <div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-ぬいぐるみ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>

<Container fluid={true} className="bg_ye py-5 my-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/piyoko/cate_stanp_ye_sp.png" />
 <Image className="my-5" src="/img/piyoko/cate_stanp_ye.png" alt="スタンプ" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/95090/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95090_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95090/"><GetItemInfo code="95090" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/94945/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94945_main.jpg" alt="" fluid /></a>
 <p><a href="/item/94945/"><GetItemInfo code="94945" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/95091/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/95091_main.jpg" alt="" fluid /></a>
 <p><a href="/item/95091/"><GetItemInfo code="95091" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/94713/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/94713_main.jpg" alt="" fluid /></a>
 <p><a href="/item/94713/"><GetItemInfo code="94713" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-スタンプ/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
	 </Container>*/}
</Container>
<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/piyoko/cate_gentei_ye_sp.png" />
 <Image className="my-5" src="/img/piyoko/cate_gentei_ye.png" alt="限定商品" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/61430/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61430_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61430/"><GetItemInfo code="61430" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/61917/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61917_main.jpg" alt="" fluid /></a>
<p><a href="/item/61917/"><GetItemInfo code="61917" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/61712/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61712_main.jpg" alt="" fluid /></a>
<p><a href="/item/61712/"><GetItemInfo code="61712" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/61894/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61894_main.jpg" alt="" fluid /></a>
 <p><a href="/item/61894/"><GetItemInfo code="61894" setkey="name" /></a></p></Col>

</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-限定商品/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
<Container fluid={true} className="bg_ye py-5 mt-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/piyoko/cate_file_ye_sp.png" />
 <Image className="my-5" src="/img/piyoko/cate_file_ye.png" alt="クリアファイル" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={4} ><a href="/item/57422/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57422_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57422/"><GetItemInfo code="57422" setkey="name" /></a></p></Col>
 <Col xs={6} sm={4} ><a href="/item/57025/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57025_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57025/"><GetItemInfo code="57025" setkey="name" /></a></p></Col>
 <Col xs={6} sm={4} ><a href="/item/57423/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57423_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57423/"><GetItemInfo code="57423" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-ファイル/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>


<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/piyoko/cate_seal_ye_sp.png" />
 <Image className="my-5" src="/img/piyoko/cate_seal_ye.png" alt="シール" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/81751/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/81751_main.jpg" alt="" fluid /></a>
 <p><a href="/item/81751/"><GetItemInfo code="81751" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/80372/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/80372_main.jpg" alt="" fluid /></a>
 <p><a href="/item/80372/"><GetItemInfo code="80372" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/81171/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/81171_main.jpg" alt="" fluid /></a>
<p><a href="/item/81171/"><GetItemInfo code="81171" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/61674/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/61674_main.jpg" alt="" fluid /></a>
<p><a href="/item/61674/"><GetItemInfo code="61674" setkey="name" /></a></p></Col>
 
 
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-シール/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>

<Container fluid={true} className="bg_ye py-5 mt-5">
<Container><picture>
<source media="(max-width: 767px)" srcSet="/img/piyoko/cate_bungu_ye_sp.png" />
 <Image className="my-5" src="/img/piyoko/cate_bungu_ye.png" alt="文具小物" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/57706/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57706_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57706/"><GetItemInfo code="57706" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/58033/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/58033_main.jpg" alt="" fluid /></a>
 <p><a href="/item/58033/"><GetItemInfo code="58033" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57605/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57605_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57605/"><GetItemInfo code="57605" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57423/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57423_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57423/"><GetItemInfo code="57423" setkey="name" /></a></p></Col>
</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-文具小物/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>
</Container>

<Container className="my-5">
<picture>
<source media="(max-width: 767px)" srcSet="/img/piyoko/cate_husen_ye_sp.png" />
 <Image className="my-5" src="/img/piyoko/cate_husen_ye.png" alt="ふせん" fluid />
</picture>
<div className="pucate-item">
<Row>
<Col xs={6} sm={3} ><a href="/item/57662/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57662_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57662/"><GetItemInfo code="57662" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/57755/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57755_main.jpg" alt="" fluid /></a>
<p><a href="/item/57755/"><GetItemInfo code="57755" setkey="name" /></a></p></Col>
<Col xs={6} sm={3} ><a href="/item/57783/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57783_main.jpg" alt="" fluid /></a>
<p><a href="/item/57783/"><GetItemInfo code="57783" setkey="name" /></a></p></Col>
 <Col xs={6} sm={3} ><a href="/item/57465/" className="opa06"><Image className="" src="https://images.mindwave-store.com/public/57465_main.jpg" alt="" fluid /></a>
 <p><a href="/item/57465/"><GetItemInfo code="57465" setkey="name" /></a></p></Col>

</Row>
</div>
<div className="text-right"><a href="/category/キャラクターから探す-ぴよこ豆-付箋/"><button type="button" className="btn btn-outline-dark px-5 btn-lg btn-smp">その他の商品もCHECK <i className="fas fa-arrow-right"></i></button></a></div>
</Container>

<Container className="mb-3"><Row>
<Col xs={12} sm={{ span: 6, offset: 3 }} ><a href="/category/キャラクターから探す-ぴよこ豆/" className="btn_or w-100 btn btn-smp btn-lg py-3 my-5">全商品一覧はこちら <i className="fa fa-arrow-right"></i></a></Col>
</Row></Container>
<Container className="">
<a href="../30th/"><picture>
<source media="(max-width: 767px)" srcSet="/img/top/bnr_30th_sp.jpg" />
<Image className="mb-3" src="/img/top/bnr_30th.jpg" alt="30周年特設ページ" fluid />
</picture></a>
<Row>
 <Col xs={12} sm={6} ><a href="../nyansuke/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-nyansuke_sp.jpg" alt="ごろごろにゃんすけ" fluid /></a></Col>
 <Col xs={12} sm={6} ><a href="../shibanban/" className="opa06"><Image className="mb-3" src="/img/top/bnr_lp-banban_sp.jpg" alt="しばんばん" fluid /></a></Col>
 </Row>
 <div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る <i className="fas fa-arrow-right"></i></a></div>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersPiyokomame);
